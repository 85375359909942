define('smart-tracking-webapp/routes/protected/accounting/accounts/owners/truck/fuel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function (params, history) {
            let truckId = history.params['protected.accounting.accounts.owners.truck'].id;
            return this.get('store').query('fuel', { truck: truckId });
        }
    });
});