define('smart-tracking-webapp/components/broker-form', ['exports', 'smart-tracking-webapp/validations/broker'], function (exports, _broker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        brokerValidation: _broker.default,

        broker: null,
        classNames: ['form'],

        actions: {

            saveBroker(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let broker = this.get('broker');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        broker.set(change.key, change.value);
                    });

                    this.sendAction('saveBroker', broker, randomId);
                }
            },
            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let broker = this.get('broker');
                    broker.unloadRecord();
                }
                return changeset.rollback();
            }
        }
    });
});