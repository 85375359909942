define('smart-tracking-webapp/routes/protected/safety/settings/groups', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {
            return Ember.RSVP.hash({
                ownerGroups: this.get('store').query('owner-group', { pagination: false }),
                users: this.get('store').query('user', { pagination: false })
            });
        },
        actions: {
            refreshModel() {
                this.refresh();
            }
        }
    });
});