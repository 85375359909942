define('smart-tracking-webapp/helpers/truck-status-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truckStatusColor = truckStatusColor;
  function truckStatusColor(params /*, hash*/) {
    return params[0] === 'OPEN' ? 'red' : 'green';
  }

  exports.default = Ember.Helper.helper(truckStatusColor);
});