define('smart-tracking-webapp/components/truck-form', ['exports', 'smart-tracking-webapp/validations/truck'], function (exports, _truck) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        truck: null,
        classNames: ['form'],
        truckValidation: _truck.default,

        actions: {
            saveTruck(changeset) {
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let truck = this.get('truck');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        truck.set(change.key, change.value);
                    });
                    this.sendAction('saveTruck', truck, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let truck = this.get('truck');
                    truck.unloadRecord();
                }

                return changeset.rollback();
            }
        }
    });
});