define('smart-tracking-webapp/routes/protected/admin/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let document = routeCrud.getRecord(params, 'company-document', 'document_id');

            return Ember.RSVP.hash({
                document: document.record,
                randomId: document.randomId
            });
        }

    });
});