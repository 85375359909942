define('smart-tracking-webapp/controllers/get-load-status', ['exports', 'smart-tracking-webapp/validations/load-check', 'smart-tracking-webapp/config/environment'], function (exports, _loadCheck, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        loadCheckValidation: _loadCheck.default,

        init() {
            this._super(...arguments);
            this.set('loadCheck', { mc: '', referenceNumber: '' });
            this.set('initScreen', true);
        },

        stopsTable: Ember.computed('response.stops', 'response.stops.[]', function () {
            let loadStops = this.get('response').stops || [];

            return {
                header: [{
                    title: 'Time',
                    propertyName: 'dateTime'
                }, {
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Facility name',
                    propertyName: 'facilityName'
                }, {
                    title: 'Facility street',
                    propertyName: 'facilityStreet'
                }, {
                    title: 'Facility city',
                    propertyName: 'facilityCity'
                }, {
                    title: 'Facility state',
                    propertyName: 'facilityState'
                }, {
                    title: 'Facility zip',
                    propertyName: 'facilityZip'
                }],
                data: loadStops
            };
        }),

        eventsTable: Ember.computed('response.events', 'response.events.[]', function () {
            let loadEvents = this.get('response').events || [];

            return {
                header: [{
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Note',
                    propertyName: 'note'
                }],
                data: loadEvents
            };
        }),

        documentsTable: Ember.computed('response.documents', 'response.documents.[]', function () {
            let loadDocuments = this.get('response').documents || [];

            return {
                header: [{
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Description',
                    propertyName: 'desc'
                }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }],
                data: loadDocuments
            };
        }),

        actions: {
            checkLoadStatus(changeset) {
                changeset.validate();
                let isValid = changeset.get('isValid');
                const basicAjax = this.get('basicAjax');

                let self = this;
                if (isValid) {
                    let mc = changeset.get('mc');
                    let referenceNumber = changeset.get('referenceNumber');

                    let url = _environment.default.APP.API_HOST + `/get-load-status?mc=${mc}&referenceNumber=${referenceNumber}`;
                    basicAjax.request('GET', url).then(function (response) {
                        let params = {
                            z: 3,
                            lat: '40.166666',
                            lon: '-100.166666',
                            qp: 'll'
                        };

                        if (response.map) {
                            params.z = 7;
                            params.lat = response.map.lat;
                            params.lon = response.map.lon;
                            params.qp = 'q';
                        }
                        let googleUrl = "https://maps.google.com/maps";
                        let mapSource = `${googleUrl}?${params.qp}=${params.lat}, ${params.lon}&z=${params.z}&output=embed`;

                        self.set('mapSource', mapSource);
                        self.set('response', response);
                        self.set('errorMessage', false);
                        self.set('initScreen', false);
                    }, function () {
                        self.set('errorMessage', true);
                    });
                }
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-load-status-document/" + id;

                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            },

            backToLoadStatus() {
                this.set('initScreen', true);
            }
        }
    });
});