define('smart-tracking-webapp/controllers/protected/dispatch/brokers/broker/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                history.back();
            },

            saveDocument(document, randomId) {
                let broker = this.get('model').broker;
                document.set('broker', broker);

                let self = this;
                let brokerId = broker.get('id');

                if (randomId) {
                    document.set('id', null);
                }

                document.save().then(function () {
                    self.get('store').findRecord('broker', brokerId, { include: 'documents' });
                });

                this.send('closeModal');
            }
        }
    });
});