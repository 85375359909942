define('smart-tracking-webapp/validations/add-load', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        referenceNumber: (0, _validators.validatePresence)(true),
        broker: (0, _validators.validatePresence)(true),
        brokerContact: (0, _validators.validatePresence)(true),
        equipment: (0, _validators.validatePresence)(true),
        driver: (0, _validators.validatePresence)(true),
        truck: (0, _validators.validatePresence)(true)
    };
});