define('smart-tracking-webapp/validations/recovery-password', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 }), (0, _validators.validateFormat)({ regex: /[A-Z\s]+/, message: 'Password must have one capital letter' }), (0, _validators.validateFormat)({ regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, message: 'Password must have one number' })],
        passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({ on: 'password', message: 'Passwords do not match' })]
    };
});