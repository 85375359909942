define('smart-tracking-webapp/controllers/protected/dispatch/brokers/broker', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),

        brokerContactsTable: Ember.computed('model.broker', 'model.broker.contacts', 'model.broker.contacts.[]', function () {
            let brokerContacts = this.get('model').brokerContacts;
            return {
                header: [{
                    title: 'Full name',
                    propertyName: 'fullName',
                    routeName: "protected.dispatch.brokers.broker.contact"
                }, {
                    title: 'Email',
                    propertyName: 'email'
                }, {
                    title: 'Phone',
                    propertyName: 'phoneNumber'
                }, {
                    title: 'Extension',
                    propertyName: 'extensionNumber'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: brokerContacts
            };
        }),

        brokerDocumentsTable: Ember.computed('model.broker', 'model.broker.documents', 'model.broker.documents.[]', function () {
            let brokerDocuments = this.get('model').broker.get('documents');
            return {
                header: [{
                    title: 'Description',
                    propertyName: 'description'
                }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: brokerDocuments
            };
        }),

        actions: {

            closeModal() {
                this.set('deleteDocumentModal', false);
                this.set('deleteBrokerContact', false);
                this.set('accidentModal', false);
                this.set('deleteDocumentModal', false);
            },

            confirmEditBroker(broker) {
                broker.save();
            },

            //broker contacts
            addBrokerContact() {
                this.transitionToRoute('protected.dispatch.brokers.broker.contact', 'new');
            },

            editBrokerContact(id) {
                this.transitionToRoute('protected.dispatch.brokers.broker.contact', id);
            },

            deleteBrokerContact(id) {
                let brokerContact = this.get('store').peekRecord('broker-contact', id);
                this.set('currentBrokerContact', brokerContact);
                this.set('deleteBrokerContact', true);
            },

            confirmDeleteBrokerContact() {
                let brockerContact = this.get('currentBrokerContact');
                brockerContact.deleteRecord();
                brockerContact.save();
                this.send('closeModal');
            },

            backToBrokers() {
                this.transitionToRoute('protected.dispatch.brokers');
            },

            addBrokerDocument() {
                this.transitionToRoute('protected.dispatch.brokers.broker.document', 'new');
            },

            deleteBrokerDocument(id) {
                let document = this.get('store').peekRecord('broker-document', id);
                this.set('currentDocument', document);
                this.set('deleteDocumentModal', true);
            },

            confirmDeleteDocument() {
                let document = this.get('currentDocument');
                document.deleteRecord();
                document.save();
                this.send('closeModal');
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-broker-documents/" + id;
                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            }
        }

    });
});