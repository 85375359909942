define('smart-tracking-webapp/models/company-eld-provider', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        apiKey: _emberData.default.attr('string'),
        eldProvider: _emberData.default.belongsTo('eld-provider', { inverse: null }),
        company: _emberData.default.belongsTo('company', { inverse: null })
    });
});