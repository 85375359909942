define('smart-tracking-webapp/routes/protected/safety/settings/elds', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {
            return Ember.RSVP.hash({
                eldProviders: this.get('store').query('eld-provider', { pagination: false }),
                companyEldProviders: this.get('store').query('company-eld-provider', {})
            });
        },
        actions: {
            refreshModel() {
                this.refresh();
            }
        }
    });
});