define('smart-tracking-webapp/routes/protected/safety/index', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        model: function () {
            const basicAjax = this.get('basicAjax');
            let urlDocuments = _environment.default.APP.API_HOST + '/get-expire-documents?pagination=false';
            let urlEntity = _environment.default.APP.API_HOST + '/get-count-per-entity?pagination=false';

            return Ember.RSVP.hash({
                documents: basicAjax.request('GET', urlDocuments).then(function (response) {
                    return response;
                }),
                entities: basicAjax.request('GET', urlEntity).then(function (response) {
                    return response;
                })
            });
        }
    });
});