define('smart-tracking-webapp/components/vehicle-maintenance-form', ['exports', 'smart-tracking-webapp/validations/vehicle-maintenance', 'smart-tracking-webapp/config/environment'], function (exports, _vehicleMaintenance, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        maintenance: null,
        basicAjax: Ember.inject.service('basicAjax'),

        maintenanceValidation: _vehicleMaintenance.default,

        actions: {
            saveMaintenance(changeset) {
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let maintenance = this.get('maintenance');
                let randomId = this.get('randomId');
                let self = this;
                let filePath = this.get('filePath');

                if (isValid) {

                    if (self.get('isTruck')) {
                        maintenance.set('truck', self.get('currentTruck'));
                    } else {
                        maintenance.set('equipment', self.get('currentEquipment'));
                    }

                    changes.forEach(function (change) {
                        maintenance.set(change.key, change.value);
                    });

                    if (filePath) {
                        maintenance.set('path', filePath);
                    }
                    this.sendAction('saveMaintenance', maintenance, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let maintenance = this.get('maintenance');
                    maintenance.unloadRecord();
                }

                return changeset.rollback();
            },

            downloadDocument() {
                let basicAjax = this.get('basicAjax');
                let maintenanceId = this.get('maintenance').get('id');
                let url = _environment.default.APP.API_HOST + "/get-maintenance-documents/" + maintenanceId;

                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            }
        }
    });
});