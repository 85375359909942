define('smart-tracking-webapp/routes/protected/dispatch/brokers/broker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function (params) {
            return Ember.RSVP.hash({
                broker: this.get('store').findRecord('broker', params.id, { include: 'documents' }),
                brokerContacts: this.get('store').query('broker-contact', { broker: params.id }),
                brokerDocuments: this.get('store').query('broker-document', { broker: params.id })
            });
        }
    });
});