define('smart-tracking-webapp/components/driver-timeline', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['driver-timeline'],

        //different colors on timeline
        didInsertElement() {
            let classes = ["one", "two", "three", "four"];

            Ember.$(".driver-timeline-event").each(function () {
                Ember.$(this).addClass(classes[~~(Math.random() * classes.length)]);
            });
        }
    });
});