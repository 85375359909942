define('smart-tracking-webapp/models/driver-document', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        issueDate: _emberData.default.attr('string'),
        expireDate: _emberData.default.attr('string'),
        type: _emberData.default.belongsTo('driver-document-type'),
        path: _emberData.default.attr('string'),
        driver: _emberData.default.belongsTo('driver', { inverse: null }),
        formattedIssueDate: Ember.computed('issueDate', function () {
            return this.get('issueDate') ? (0, _moment.default)(this.get('issueDate')).format('MM/DD/YYYY') : '/';
        }),
        formattedExpireDate: Ember.computed('expireDate', function () {
            return this.get('expireDate') ? (0, _moment.default)(this.get('expireDate')).format('MM/DD/YYYY') : '/';
        })
    });
});