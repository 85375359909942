define('smart-tracking-webapp/controllers/protected/dispatch/facilities/facility', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),

        actions: {
            closeModal(randomId) {
                //unload record if adding is canceled
                if (randomId) {
                    let facility = this.get('store').peekRecord('facility', randomId);
                    facility.unloadRecord();
                }
                this.transitionToRoute('protected.dispatch.facilities');
            },

            saveFacility(facility, randomId) {
                let self = this;
                if (randomId) {
                    facility.set('id', null);
                }

                facility.save().then(function () {
                    self.send('refreshModel');
                    self.transitionToRoute('protected.dispatch.facilities');
                }).catch(function () {
                    facility.unloadRecord();
                });

                this.send('closeModal');
            }
        }
    });
});