define('smart-tracking-webapp/controllers/protected/copy-brokers', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),
        actions: {
            copyBrokers() {
                const url = _environment.default.APP.API_HOST + '/copy-company-brokers';
                const basicAjax = this.get('basicAjax');
                const notifications = this.get('notifications');

                let selectedForCompany = this.get('selectedForCompany');
                let selectedFromCompany = this.get('selectedFromCompany');

                if (selectedForCompany && selectedFromCompany) {
                    basicAjax.request('POST', url, {
                        forCompanyId: selectedForCompany.get('id'),
                        fromCompanyId: selectedFromCompany.get('id')
                    }).then(function (response) {
                        notifications.success('Successfully copied brokers');
                        return response;
                    });
                } else {
                    notifications.error('Please fill both fields', 'Error');
                }
            }
        }
    });
});