define('smart-tracking-webapp/helpers/load-status-color', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.loadStatusColor = loadStatusColor;


    function setColor(params) {
        let color;
        switch (params) {
            case 'Completed':
                color = '#b6b6b6';
                break;
            case 'Planned':
                color = '#e8d127';
                break;
            case 'In transit':
                color = '#26a556';
                break;
            case 'Canceled':
                color = '#bb3333';
                break;
            default:
                color = '#b6b6b6';
                break;
        }
        return color;
    }

    function loadStatusColor(params /*, hash*/) {
        return setColor(params[0]);
    }

    exports.default = Ember.Helper.helper(loadStatusColor);
});