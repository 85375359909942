define('smart-tracking-webapp/controllers/protected/accounting/statistics/fuel', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        fuel: Ember.computed('model.fuel', function () {

            let fuel = this.get('model').fuel;
            let dollarspermileGroup = [];
            let dollarspermileTruck = [];
            let milespergallonGroup = [];
            let milespergallonTruck = [];
            let owners = [];
            let trucks = [];

            fuel.perGroup.forEach(record => {
                dollarspermileGroup.push(parseFloat(record.dollarspermile));
                milespergallonGroup.push(parseFloat(record.milespergallon));
                owners.push(record.owner);
            });

            fuel.perTruck.forEach(record => {
                dollarspermileTruck.push(parseFloat(record.dollarspermile));
                milespergallonTruck.push(parseFloat(record.milespergallon));
                trucks.push(record.truckId);
            });

            return {
                optionsGroup: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Fuel per group'
                    },
                    xAxis: {
                        categories: owners
                    },
                    yAxis: {
                        min: 0
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    }
                },

                groupData: [{
                    name: 'Dollars per mile',
                    data: dollarspermileGroup

                }, {
                    name: 'Miles per gallon',
                    data: milespergallonGroup

                }],

                optionsDriver: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Fuel per truck'
                    },
                    xAxis: {
                        categories: trucks
                    },
                    yAxis: {
                        min: 0
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    }
                },

                driverData: [{
                    name: 'Dollars per mile',
                    data: dollarspermileTruck

                }, {
                    name: 'Miles per gallon',
                    data: milespergallonTruck

                }]
            };
        }),
        actions: {
            filter(params) {

                this.set('selectedDate', {
                    startTime: params.start,
                    endTime: params.start
                });

                let formattedParams = {
                    startTime: (0, _moment.default)(params.start).format('YYYY-MM-DD'),
                    endTime: (0, _moment.default)(params.end).format('YYYY-MM-DD')
                };

                const basicAjax = this.get('basicAjax');
                const fuelUrl = _environment.default.APP.API_HOST + '/fuel-statistic';

                let self = this;
                basicAjax.request('GET', fuelUrl, formattedParams, true).then(function (response) {
                    self.set('model.fuel', response);
                });
            }
        }
    });
});