define('smart-tracking-webapp/controllers/protected/accounting/statistics/company-profit', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);
            let years = ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028'];
            let months = _moment.default.months();
            this.set('years', years);
            this.set('months', months);
            this.set('selectedYear', (0, _moment.default)().format('YYYY'));
        },

        total: Ember.computed('model.total', function () {
            let total = this.get('model').total || { income: 0, expenses: 0 };
            let net = total.income - total.expenses;
            total = [['Income', total.income], ['Expenses', total.expenses], ['Total', net]];

            return {
                options: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Dollars'
                    },
                    plotOptions: {
                        pie: {
                            innerSize: 100,
                            depth: 45
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    }
                },

                data: [{
                    name: '$',
                    data: total
                }]
            };
        }),

        ownedTrucks: Ember.computed('model.ownedTrucks', function () {
            let ownedTrucks = this.get('model').ownedTrucks || [];
            const months = this.get('months');

            let incomeArray = months.map(month => {
                if (ownedTrucks && ownedTrucks.income && Object.entries(ownedTrucks.income).length) {
                    const year = Object.entries(ownedTrucks.income)[0][0].slice(-4); // 2019
                    if (year) {
                        return ownedTrucks.income[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            let milesArray = months.map(month => {
                if (ownedTrucks && ownedTrucks.miles && Object.entries(ownedTrucks.miles).length) {
                    const year = Object.entries(ownedTrucks.miles)[0][0].slice(-4); // 2019
                    if (year) {
                        return ownedTrucks.miles[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            return {
                options: {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: 'Company owned trucks - Mile/Gross per Months'
                    },
                    xAxis: {
                        categories: months
                    },
                    yAxis: {
                        title: {
                            text: '$ (dollars)'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    }
                },

                data: [{
                    name: 'Incomes ($)',
                    data: incomeArray
                }, {
                    name: 'Miles',
                    data: milesArray
                }]
            };
        }),

        allTrucks: Ember.computed('model.allTrucks', function () {
            let allTrucks = this.get('model').allTrucks || [];
            const months = this.get('months');

            let incomeArray = months.map(month => {
                if (allTrucks && allTrucks.income && Object.entries(allTrucks.income).length) {
                    const year = Object.entries(allTrucks.income)[0][0].slice(-4); // 2019
                    if (year) {
                        return allTrucks.income[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            let milesArray = months.map(month => {
                if (allTrucks && allTrucks.miles && Object.entries(allTrucks.miles).length) {
                    const year = Object.entries(allTrucks.miles)[0][0].slice(-4); // 2019
                    if (year) {
                        return allTrucks.miles[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            return {
                options: {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: 'All trucks - Mile/Gross per Months'
                    },
                    xAxis: {
                        categories: months
                    },
                    yAxis: {
                        title: {
                            text: '$ (dollars)'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    }
                },

                data: [{
                    name: 'Incomes ($)',
                    data: incomeArray
                }, {
                    name: 'Miles',
                    data: milesArray
                }]
            };
        }),

        profit: Ember.computed('model.profit', function () {
            let profit = this.get('model').profit || [];
            const months = this.get('months');

            let incomeArray = months.map(month => {
                if (profit && profit.income && Object.entries(profit.income).length) {
                    const year = Object.entries(profit.income)[0][0].slice(-4); // 2019
                    if (year) {
                        return profit.income[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            let expensesArray = months.map(month => {
                if (profit && profit.expenses && Object.entries(profit.expenses).length) {
                    const year = Object.entries(profit.expenses)[0][0].slice(-4); // 2019
                    if (year) {
                        return profit.expenses[`${month}-${year}`] || 0;
                    }
                }
                return 0;
            });

            let profitArray = incomeArray.map((value, index) => {
                return value - expensesArray[index];
            });

            return {
                options: {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: 'Income - Expenses - Profit'
                    },
                    xAxis: {
                        categories: months
                    },
                    yAxis: {
                        title: {
                            text: '$ (dollars)'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    }
                },

                data: [{
                    name: 'Incomes ($)',
                    data: incomeArray
                }, {
                    name: 'Expenses ($)',
                    data: expensesArray
                }, {
                    name: 'Profit ($)',
                    data: profitArray
                }]
            };
        }),

        actions: {
            filterYear(year) {
                this.set('selectedYear', year);

                let self = this;
                let params = {
                    start: year + '-01-01',
                    end: year + '-12-31'
                };

                const basicAjax = this.get('basicAjax');
                const ownedTrucksUrl = _environment.default.APP.API_HOST + '/get-company-owned-trucks-period-chart';
                const allTrucksUrl = _environment.default.APP.API_HOST + '/get-company-all-trucks-period-chart';
                const profitUrl = _environment.default.APP.API_HOST + '/get-company-profit-per-period';

                basicAjax.request('GET', ownedTrucksUrl, params, true).then(function (response) {
                    self.set('model.ownedTrucks', response);
                });

                basicAjax.request('GET', allTrucksUrl, params, true).then(function (response) {
                    self.set('model.allTrucks', response);
                });

                basicAjax.request('GET', profitUrl, params, true).then(function (response) {
                    self.set('model.profit', response);
                });
            },

            filter(params) {

                this.set('selectedDate', {
                    startTime: params.start,
                    endTime: params.start
                });

                let formattedParams = {
                    start: (0, _moment.default)(params.start).format('YYYY-MM-DD'),
                    end: (0, _moment.default)(params.end).format('YYYY-MM-DD')
                };

                const basicAjax = this.get('basicAjax');
                const totalUrl = _environment.default.APP.API_HOST + '/get-company-profit';

                let self = this;

                basicAjax.request('GET', totalUrl, formattedParams, true).then(function (response) {
                    self.set('model.total', response);
                });
            }
        }
    });
});