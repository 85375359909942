define('smart-tracking-webapp/components/filter-bar', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['filter-bar'],
        init() {
            this._super(...arguments);
            //starting year for date picker
            let startYear = this.startYear || 1950;
            let currentYear = Number((0, _moment.default)().format('YYYY')) + 10; //3 more years added in case of planning
            let totalYears = currentYear - startYear;

            this.set('years', Array(...Array(totalYears)).map((_, i) => `${i + startYear}`));
            this.set('months', _moment.default.months());
            this.set('selectedStartDate', this.get('selectedStartDate') ? this.get('selectedStartDate') : null);
            this.set('selectedEndDate', this.get('selectedEndDate') ? this.get('selectedEndDate') : null);
        },

        actions: {
            selectStartDate(date) {
                this.set('selectedStartDate', date);
                this.send('filter');
            },

            selectEndDate(date) {
                this.set('selectedEndDate', date);
                this.send('filter');
            },

            changeCenterStart(unit, calendar, val) {
                let newCenter = calendar.center.clone()[unit](val);
                calendar.actions.changeCenter(newCenter);
            },

            changeCenterEnd(unit, calendar, val) {
                let newCenter = calendar.center.clone()[unit](val);
                calendar.actions.changeCenter(newCenter);
            },

            filter() {

                let startDate = this.get('selectedStartDate');
                let endDate = this.get('selectedEndDate');

                if (startDate && endDate) {
                    this.get('filter')({
                        start: (0, _moment.default)(startDate).format('MM/DD/YYYY'),
                        end: (0, _moment.default)(endDate).format('MM/DD/YYYY')
                    });
                }
            }
        }
    });
});