define('smart-tracking-webapp/components/table-invoice', ['exports', 'smart-tracking-webapp/templates/components/table-edit-row'], function (exports, _tableEditRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableEditRow.default,
        actions: {
            sendAction(actionName, recordId, event) {
                this.sendAction(actionName, recordId);
                event.stopPropagation();
            }
        }
    });
});