define('smart-tracking-webapp/components/drug-test-form', ['exports', 'smart-tracking-webapp/validations/drug-test', 'smart-tracking-webapp/config/environment'], function (exports, _drugTest, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        basicAjax: Ember.inject.service('basicAjax'),
        store: Ember.inject.service(),
        classNames: ['form'],

        init() {
            this._super(...arguments);
            this.set('allStatuses', ['Positive', 'Negative']);

            let testStatus = this.get('drugTest.status');
            let self = this;
            if (testStatus) {
                self.set('selectedStatus', testStatus);
            } else {
                self.set('selectedStatus', null);
            }
        },

        drugTestValidation: _drugTest.default,

        document: null,

        actions: {
            saveDrugTest(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let drugTest = this.get('drugTest');
                let randomId = this.get('randomId');
                let filePath = this.get('filePath');
                let selectedStatus = this.get('selectedStatus');
                let self = this;

                if (isValid) {

                    changes.forEach(function (change) {
                        drugTest.set(change.key, change.value);
                    });

                    if (filePath) {
                        drugTest.set('path', filePath);
                    }

                    let passed = true;
                    if (selectedStatus === 'Positive') {
                        passed = false;
                    }

                    drugTest.set('passed', passed);
                    self.set('noDocument', false);
                    this.sendAction('saveDrugTest', drugTest, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let drugTest = this.get('drugTest');
                    drugTest.unloadRecord();
                }

                return changeset.rollback();
            },

            downloadDocument() {
                let basicAjax = this.get('basicAjax');
                let drugTestId = this.get('drugTest').get('id');
                let url = _environment.default.APP.API_HOST + "/get-drug-test-documents/" + drugTestId;

                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            }
        }
    });
});