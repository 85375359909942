define('smart-tracking-webapp/controllers/protected/dispatch/index', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);
            let truckStatuses = [{
                value: 'ALL',
                label: 'All'
            }, {
                value: 'COVERED',
                label: 'Covered'
            }, {
                value: 'OPEN',
                label: 'Open'
            }];
            this.set('truckStatuses', truckStatuses);
            this.set('selectedStatus', {
                value: 'ALL',
                label: 'All'
            });
        },

        totalTrucks: Ember.computed('model.trucks', 'model.trucks.[]', function () {
            let trucks = this.get('model').trucks;
            let open = 0;
            let covered = 0;

            trucks.forEach(truck => {
                if (truck.truckStatus === 'COVERED') {
                    covered++;
                }
                if (truck.truckStatus === 'OPEN') {
                    open++;
                }
            });

            return {
                open: open,
                covered: covered
            };
        }),

        allDispatchers: Ember.computed('model.allDispatchers', 'model', function () {
            let allDispatchers = this.get('model.allDispatchers');
            allDispatchers = allDispatchers.filter(function (dispatcher) {
                if (dispatcher.get('roles').indexOf('ROLE_DISPATCH') !== -1) {
                    return true;
                }
            });
            return allDispatchers;
        }),

        selectedDispatchers: Ember.computed('model.currentUser', 'model.allDispatchers', function () {
            let currentUser = this.get('model.currentUser');
            let currentUserRoles = currentUser.get('roles');
            return currentUserRoles.includes('ROLE_DISPATCH') ? [currentUser] : this.get('model.allDispatchers');
        }),

        selectedDispatchersIds: Ember.computed('selectedDispatchers', function () {
            let selectedDispatchers = this.get('selectedDispatchers');
            let dispatchIds = [];
            selectedDispatchers.map(record => {
                dispatchIds.push(record.get('id'));
            });

            return dispatchIds.toString();
        }),

        addingEnabled: Ember.computed('model.currentUser', function () {
            let currentUserRoles = this.get('model').currentUser.get('roles');
            let enabled = false;

            if (currentUserRoles.includes('ROLE_ADMIN') || currentUserRoles.includes('ROLE_DISPATCH')) {
                enabled = true;
            }

            return enabled;
        }),

        actions: {
            didSelectDispatcher(truckId, dispatcher) {
                let self = this;

                //Update dispatcher for selected truck
                this.get('store').findRecord('truck', truckId).then(function (truck) {
                    truck.set('dispatcher', dispatcher);
                    truck.save().then(function () {
                        self.send('updateTable');
                    });
                });
            },

            saveNote(noteValue, truckId) {
                let self = this;
                //Update note for selected truck
                this.get('store').findRecord('truck', truckId).then(function (truck) {
                    truck.set('note', noteValue);
                    truck.save().then(function () {
                        self.send('updateTable');
                        self.set('notEditable', true);
                    });
                });
            },

            updateTable() {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + '/truck-board';
                let self = this;
                let params = this.get('model.currentFilter');
                //get selected dispatchers from multiselect
                params.dispatch = this.get('selectedDispatchersIds');
                params.status = this.get('selectedStatus').value;

                this.set('isLoading', true);
                basicAjax.request('GET', url, params, true).then(function (response) {
                    self.set('model.trucks', response);
                    self.set('isLoading', false);
                });
            },

            filterBoard(params) {
                //set new values to datepicker
                this.set('model.currentFilter.start', params.start);
                this.set('model.currentFilter.end', params.end);

                //get selected dispatchers from multiselect
                let selectedDispatchersIds = this.get('selectedDispatchersIds');
                params.dispatch = this.get('selectedDispatchersIds');
                params.truckStatus = this.get('selectedStatus').value;

                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/truck-board';
                let self = this;
                this.set('isLoading', true);

                basicAjax.request('GET', url, params, true).then(function (response) {
                    self.set('model.trucks', response);
                    self.set('isLoading', false);
                });
            },

            showTruck(id) {
                this.set('infoModal', true);
                this.set('isTruck', true);
                let self = this;
                this.get('store').findRecord('truck', id, { include: 'owner' }).then(truck => {
                    self.set('currentTruck', truck);
                    self.set('isTruck', true);
                });
            },

            showDriver(id) {
                let self = this;
                this.set('infoModal', true);
                if (id) {
                    self.get('store').findRecord('driver', id).then(driver => {
                        self.set('currentDriver', driver);
                        self.set('isDriver', true);
                    });
                }
            },

            showEquipment(id) {
                this.set('infoModal', true);
                this.set('isEquipment', true);
                let self = this;
                this.get('store').findRecord('equipment', id, { include: 'owner' }).then(equipment => {
                    self.set('currentEquipment', equipment);
                    self.set('isEquipment', true);
                });
            },

            closeModal() {
                this.set('infoModal', false);
                this.set('isDriver', false);
                this.set('isTruck', false);
                this.set('isEquipment', false);
            },

            //adding load
            addLoad() {
                this.set('loadModal', true);
                let load = this.get('store').createRecord('load');
                this.set('currentLoad', load);
            },
            confirmAddLoad(load) {
                let self = this;
                load.set('status', 'Planned');
                load.save().then(function (savedLoad) {
                    self.send('updateTable');
                    self.set('loadModal', false);
                    self.transitionToRoute('protected.dispatch.loads.load', savedLoad.get('id'));
                });
            },
            cancelAddingLoad() {
                let currentLoad = this.get('currentLoad');
                currentLoad.unloadRecord();
                this.set('loadModal', false);
            },

            didSelectDispatchers(dispatchers) {

                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/truck-board';
                let self = this;
                this.set('isLoading', true);

                //set selected dispatchers to multiselect and create array of ids
                this.set('selectedDispatchers', dispatchers);

                //set params with values from datepicker and formatted multiselect array
                let params = {
                    start: this.get('model.currentFilter.start'),
                    end: this.get('model.currentFilter.end'),
                    dispatch: this.get('selectedDispatchersIds'),
                    truckStatus: this.get('selectedStatus').value
                };

                basicAjax.request('GET', url, params, true).then(function (response) {
                    self.set('model.trucks', response);
                    self.set('isLoading', false);
                });
            },

            didSelectStatus(status) {

                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/truck-board';

                let self = this;
                this.set('isLoading', true);

                //set selected status
                this.set('selectedStatus', status);

                let params = {
                    start: this.get('model.currentFilter.start'),
                    end: this.get('model.currentFilter.end'),
                    dispatch: this.get('selectedDispatchersIds'),
                    truckStatus: status.value
                };

                basicAjax.request('GET', url, params, true).then(function (response) {
                    self.set('model.trucks', response);
                    self.set('isLoading', false);
                });
            },

            clearDispatchers() {

                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/truck-board';
                let self = this;
                this.set('isLoading', true);

                //clear all dispatchers
                this.set('selectedDispatchers', []);

                //set params with values from datepicker and formatted multiselect array
                let params = {
                    start: this.get('model.currentFilter.start'),
                    end: this.get('model.currentFilter.end'),
                    dispatch: this.get('selectedDispatchersIds'),
                    truckStatus: this.get('selectedStatus').value
                };

                basicAjax.request('GET', url, params, true).then(function (response) {
                    self.set('model.trucks', response);
                    self.set('isLoading', false);
                });
            }
        }
    });
});