define('smart-tracking-webapp/controllers/protected/dispatch/facilities', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        facilitiesTable: Ember.computed('model.facilities.[]', 'model.facilities', function () {

            let facilities = this.get('model').facilities;
            return {
                header: [{
                    title: 'Facility name',
                    propertyName: 'name',
                    routeName: "protected.dispatch.facilities.facility"
                }, {
                    title: 'Zip number',
                    propertyName: 'zip'
                }, {
                    title: 'State',
                    propertyName: 'state'
                }, {
                    title: 'City',
                    propertyName: 'city'
                }, {
                    title: 'Street',
                    propertyName: 'street'
                }, {
                    title: 'Average rate',
                    propertyName: 'formattedRate'
                }, {
                    title: 'Average time spent on the facility',
                    propertyName: 'timeSpent'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: facilities
            };
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            addFacility() {
                this.transitionToRoute('protected.dispatch.facilities.facility', 'new');
            },

            deleteFacility(id) {
                let facility = this.get('store').peekRecord('facility', id);
                this.set('currentFacility', facility);
                this.set('openModal', true);
            },
            confirmDeleteFacility() {
                let facility = this.get('currentFacility');
                facility.deleteRecord();
                facility.save();
                this.send('closeModal');
            }
        }
    });
});