define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/driver/refunds', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params, history) {
            let driverId = history.params['protected.accounting.accounts.drivers.driver'].id;
            return Ember.RSVP.hash({
                advances: this.get('store').query('advance', { driver: driverId, refunds: true, include: 'statement' }),
                driver: this.get('store').peekRecord('driver', driverId)
            });
        }

    });
});