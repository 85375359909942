define('smart-tracking-webapp/controllers/protected/admin/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                this.transitionToRoute('protected.admin');
            },

            saveDocument(document, randomId) {
                if (randomId) {
                    document.set('id', null);
                }

                document.save().catch(function () {
                    document.unloadRecord();
                });

                this.send('closeModal');
            }
        }
    });
});