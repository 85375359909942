define('smart-tracking-webapp/validations/decimals', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = validateDecimal;
    function validateDecimal() {
        return (key, newValue) => {
            if (newValue && newValue.includes('.')) {
                let afterDot = newValue.substr(newValue.indexOf('.') + 1);
                let length = afterDot.length;
                if (length === 0) {
                    return "Remove dot or put put number with 2 decimals";
                }
                return length < 3 ? true : '2 decimals only';
            }
            return true;
        };
    }
});