define('smart-tracking-webapp/models/stop', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        type: _emberData.default.attr('string'), //pick-up or delivery
        startTime: _emberData.default.attr('string'), //date + time
        endTime: _emberData.default.attr('string'), //date + time
        note: _emberData.default.attr('string'),
        load: _emberData.default.belongsTo('load', { inverse: null }),
        facility: _emberData.default.belongsTo('facility', { inverse: null }),
        authorFullName: _emberData.default.attr('string'),
        formattedTime: Ember.computed('startTime', function () {
            return this.get('startTime') ? (0, _moment.default)(this.get('startTime')).format('MM/DD/YYYY HH:mm') : '/';
        })
    });
});