define('smart-tracking-webapp/routes/protected/accounting', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),
        session: Ember.inject.service('session'),
        activate() {
            let defaultItems = this.get('navigationService').get('defaultVisibleItems');
            let userId = this.get('session.data.authenticated.user_id');
            let user = this.get('store').peekRecord('user', userId);
            let userRoles = user.get('roles');
            let navigationItems = [{
                icon: 'folder-open',
                position: 2,
                label: 'Accounting',
                link: 'protected.accounting.index'
            }, {
                icon: 'folder-open',
                position: 3,
                label: 'Loads',
                link: 'protected.accounting.loads'
            }, {
                icon: 'folder-open',
                position: 4,
                label: 'Accounts payable',
                link: 'protected.accounting.accounts'
            }, {
                icon: 'folder-open',
                position: 5,
                label: 'Fuel',
                link: 'protected.accounting.fuel'
            }, {
                icon: 'folder-open',
                position: 6,
                label: 'Brokers',
                link: 'protected.dispatch.brokers'
            }];

            if (userRoles.includes('ROLE_ADMIN')) {
                navigationItems.push({
                    icon: 'folder-open',
                    position: 7,
                    label: ' Company Expenses',
                    link: 'protected.accounting.company'
                });
                navigationItems.push({
                    icon: 'folder-open',
                    position: 8,
                    label: 'Statistics',
                    link: 'protected.accounting.statistics'
                });
            }

            let completeNavigationItems = navigationItems.concat(defaultItems);

            //sorting by position
            completeNavigationItems.sort(function (a, b) {
                return a.position - b.position;
            });
            this.get('navigationService').set('visibleItems', completeNavigationItems);
        },

        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});