define('smart-tracking-webapp/components/load-document-form', ['exports', 'smart-tracking-webapp/validations/load-document'], function (exports, _loadDocument) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        classNames: ['form'],

        loadDocumentValidation: _loadDocument.default,

        document: null,

        filteredTypes: Ember.computed(function () {
            let documents = this.get('load.documents');
            let allDocumentTypes = this.get('documentTypes');
            return documents.get('content').length === 0 ? ['Rate Confirmation'] : allDocumentTypes;
        }),
        actions: {
            saveDocument(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let document = this.get('document');
                let randomId = this.get('randomId');
                let filePath = this.get('filePath');
                let self = this;

                if (!filePath) {
                    isValid = false;
                    self.set('noDocument', true);
                }

                if (isValid) {

                    changes.forEach(function (change) {
                        document.set(change.key, change.value);
                    });

                    document.set('path', filePath);
                    self.set('noDocument', false);
                    this.sendAction('saveDocument', document, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let document = this.get('document');
                    document.unloadRecord();
                }

                return changeset.rollback();
            }
        }
    });
});