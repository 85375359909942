define('smart-tracking-webapp/routes/protected/safety/settings/groups/group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let group = routeCrud.getRecord(params, 'owner-group');

            return Ember.RSVP.hash({
                group: group.record,
                users: this.get('store').query('user', { pagination: false }),
                randomId: group.randomId
            });
        }
    });
});