define('smart-tracking-webapp/models/broker', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        mc: _emberData.default.attr('string'),
        name: _emberData.default.attr('string'),
        street: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        zip: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        contacts: _emberData.default.hasMany('broker-contact'),
        documents: _emberData.default.hasMany('broker-document'),
        afterHoursContactName: _emberData.default.attr('string'),
        afterHoursContactPhone: _emberData.default.attr('string'),
        afterHoursContactMail: _emberData.default.attr('string'),
        blackListed: _emberData.default.attr('boolean'),
        disabled: Ember.computed('blackListed', function () {
            return this.get('blackListed');
        })
    });
});