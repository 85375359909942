define('smart-tracking-webapp/routes/protected/accounting/statistics/general', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        model: function () {

            let params = {
                startTime: (0, _moment.default)().startOf('month').format('YYYY-MM-DD'),
                endTime: (0, _moment.default)().endOf('month').format('YYYY-MM-DD')
            };

            const basicAjax = this.get('basicAjax');
            const totalGrossUrl = _environment.default.APP.API_HOST + '/gross-statistic';
            const topCustomersUrl = _environment.default.APP.API_HOST + '/customer-statistic';
            const mileGrossRateUrl = _environment.default.APP.API_HOST + '/truck-gross-statistic';
            const statementsUrl = _environment.default.APP.API_HOST + '/statements-statistic';

            return Ember.RSVP.hash({
                selectedDate: {
                    start: params.startTime,
                    end: params.endTime
                },
                totalGross: basicAjax.request('GET', totalGrossUrl, params, true).then(function (response) {
                    return response;
                }),
                topCustomers: basicAjax.request('GET', topCustomersUrl, params, true).then(function (response) {
                    return response.data;
                }),
                mileGrossRate: basicAjax.request('GET', mileGrossRateUrl, params, true).then(function (response) {
                    return response;
                }),
                statements: basicAjax.request('GET', statementsUrl, params, true).then(function (response) {
                    return response;
                })
            });
        }
    });
});