define('smart-tracking-webapp/routes/protected/dispatch/loads/load', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function (params) {

            let userId = this.get('session.data.authenticated.user_id');

            return Ember.RSVP.hash({
                load: this.get('store').findRecord('load', params.id, { include: 'stops,events,driver,truck,charges,author' }),
                documents: this.get('store').query('load-document', { load: params.id }),
                stops: this.get('store').query('stop', {
                    load: params.id,
                    include: 'facility'
                }),
                drivers: this.get('store').query('driver', { 'status.name': "active driver", pagination: false }),
                equipment: this.get('store').query('equipment', { active: true, pagination: false }),
                trucks: this.get('store').query('truck', { active: true, pagination: false }),
                brokers: this.get('store').query('broker', { include: 'broker-contact', pagination: false }),
                loadStatuses: Ember.$.getJSON("/json/load-statuses.json").then(function (statuses) {
                    return statuses;
                }),
                currentUser: this.get('store').findRecord('user', userId)
            });
        }
    });
});