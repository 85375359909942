define('smart-tracking-webapp/controllers/protected/safety/trucks/truck', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        truckDocumentsTable: Ember.computed('model.truck.documents', 'model.truck.documents.[]', function () {
            let documents = this.get('model').truck.get('documents');
            return {
                header: [{ title: 'Type', propertyName: 'type.name' }, { title: 'Description', propertyName: 'description' }, { title: 'Issue date', propertyName: 'formattedIssueDate' }, { title: 'Expire date', propertyName: 'formattedExpireDate' }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: documents
            };
        }),

        totalMaintenance: Ember.computed('model.maintenances', 'model.maintenances.[]', function () {
            let maintenance = this.get('model').maintenances || [];
            let total = 0;

            maintenance.forEach(record => {
                total = total + +record.get('cost');
            });

            return total.toFixed(2);
        }),

        maintenanceTable: Ember.computed('model.maintenances', 'model.maintenances.[]', function () {
            let maintenance = this.get('model').maintenances || [];

            return {
                header: [{ title: 'Type',
                    propertyName: 'type',
                    routeName: "protected.safety.trucks.truck.maintenance"
                }, {
                    title: 'Description',
                    propertyName: 'description'
                }, { title: 'Cost ($)', propertyName: 'dollarCost' }, { title: 'Repair date', propertyName: 'formattedRepairDate' }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: maintenance
            };
        }),

        accidentsTable: Ember.computed('model.truck', 'model.truck.accidents', 'model.truck.accidents.[]', function () {
            let accidents = this.get('model').truck.get('accidents') || [];
            return {
                header: [{
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Driver',
                    propertyName: 'driver.fullName'
                }, {
                    title: 'Truck number',
                    propertyName: 'truck.truckId'
                }, {
                    title: 'Equipment number',
                    propertyName: 'equipment.equipmentId'
                }, {
                    title: 'Report number',
                    propertyName: 'reportNumber'
                }, {
                    title: 'Location',
                    propertyName: 'location'
                }, {
                    title: 'View',
                    component: "table-view-row",
                    disableSorting: true
                }],
                data: accidents
            };
        }),

        actions: {

            closeModal() {
                this.set('deleteDocumentModal', false);
                this.set('deleteMaintenanceModal', false);
                this.set('accidentModal', false);
            },

            confirmEditTruck(truck) {
                truck.save();
            },

            //documents
            addTruckDocument() {
                this.transitionToRoute('protected.safety.trucks.truck.document', 'new');
            },

            deleteTruckDocument(id) {
                let document = this.get('store').peekRecord('vehicle-document', id);
                this.set('currentDocument', document);
                this.set('deleteDocumentModal', true);
            },

            confirmDeleteDocument() {
                let document = this.get('currentDocument');
                document.deleteRecord();
                document.save();
                this.send('closeModal');
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-truck-documents/" + id;

                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            },

            //maintenance
            addMaintenance() {
                this.transitionToRoute('protected.safety.trucks.truck.maintenance', 'new');
            },

            deleteMaintenance(id) {
                let maintenance = this.get('store').peekRecord('vehicle-maintenance', id);
                this.set('currentMaintenance', maintenance);
                this.set('deleteMaintenanceModal', true);
            },

            confirmDeleteMaintenance() {
                let maintenance = this.get('currentMaintenance');
                maintenance.deleteRecord();
                maintenance.save();
                this.send('closeModal');
            },

            //accidents
            viewAccident(id) {
                let self = this;
                this.get('store').findRecord('accident', id, { include: 'truck, equipment' }).then(function (accident) {
                    self.set('accidentModal', true);
                    self.set('currentAccident', accident);
                });
            },

            backToTrucks() {
                this.transitionToRoute('protected.safety.trucks');
            }
        }

    });
});