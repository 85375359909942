define('smart-tracking-webapp/controllers/protected/dispatch/loads/index', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        openModal: false,
        dayModal: false,
        loadModal: false,
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);

            let startOfWeek = (0, _moment.default)().startOf('week');
            let endOfWeek = (0, _moment.default)().endOf('week');

            let queryingPeriod = {
                start: startOfWeek,
                end: endOfWeek
            };

            let formattedDays = this.createWeekDays(startOfWeek, endOfWeek);
            this.set('weekDays', formattedDays);
            this.set('queryingPeriod', queryingPeriod);
            this.set('allTrucks', true);
            this.set('selectedTrucks', []);
        },

        createWeekDays: function (startOfWeek, endOfWeek) {

            let days = [];
            let day = startOfWeek;
            while (day <= endOfWeek) {
                days.push(day.toDate());
                day = day.clone().add(1, 'd');
            }

            return days.map(day => {
                return {
                    name: (0, _moment.default)(day).format('dddd'),
                    date: (0, _moment.default)(day).format('MM/DD/YYYY')
                };
            });
        },

        setStatusColor: function (status) {
            switch (status) {
                case 'Planned':
                    return 'status-planned';
                case 'In transit':
                    return 'status-transit';
                case 'Completed':
                    return 'status-completed';
                case 'Canceled':
                    return 'status-canceled';
                default:
                    return '';
            }
        },

        loadDayList: Ember.computed('model.loads', 'model.loads.[]', 'weekDays', function () {

            let loads = this.get('model').loads;

            //is disable button
            let formattedLoads = loads.map(load => {
                let isDisabled = false;
                if (load.loadStatus !== 'Planned') {
                    isDisabled = true;
                }
                if (!load.loadStatus) {
                    isDisabled = false;
                }
                if (load.hasOwnProperty('isDisabled')) {
                    Ember.set(load, 'isDisabled', isDisabled);
                } else {
                    load['isDisabled'] = isDisabled;
                }

                return load;
            });

            formattedLoads.forEach(loadDay => {
                let allPickupStops = [];
                let allDeliveryStops = [];
                loadDay.days.forEach(day => {

                    //exists stop in week
                    if (day.stops && day.stops.length > 0) {

                        //color all week days with stops
                        if (day.hasOwnProperty('color')) {
                            Ember.set(day, 'color', this.setStatusColor(loadDay.loadStatus));
                        } else {
                            day['color'] = this.setStatusColor(loadDay.loadStatus);
                        }

                        day.stops.forEach(stop => {
                            if (stop.type === "Pick-up") {
                                allPickupStops.push(stop.startTime);
                            } else {
                                allDeliveryStops.push(stop.startTime);
                            }
                        });
                    }
                });

                if (allPickupStops.length === 0) {
                    loadDay['noPickups'] = true;
                }

                if (allDeliveryStops.length === 0) {
                    loadDay['noDeliveries'] = true;
                }

                let sortedPickups = allPickupStops.sort(function (a, b) {
                    return (0, _moment.default)(a, 'MM/DD/YYYY HH:mm:SS').toDate() - (0, _moment.default)(b, 'MM/DD/YYYY HH:mm:SS').toDate();
                });

                //sorted from first to last
                let sortedDelivery = allDeliveryStops.sort(function (a, b) {
                    return (0, _moment.default)(a, 'MM/DD/YYYY HH:mm:SS').toDate() - (0, _moment.default)(b, 'MM/DD/YYYY HH:mm:SS').toDate();
                });

                loadDay['firstPickup'] = sortedPickups[0];
                loadDay['lastDelivery'] = sortedDelivery[sortedDelivery.length - 1];
            });

            //coloring all other days
            formattedLoads.forEach(loadDay => {

                loadDay.days.forEach(day => {
                    if (loadDay.noDeliveries) {
                        if ((0, _moment.default)(day.date).isAfter(loadDay.firstPickup)) {

                            if (day.hasOwnProperty('color')) {
                                Ember.set(day, 'color', this.setStatusColor(loadDay.loadStatus));
                            } else {
                                day['color'] = this.setStatusColor(loadDay.loadStatus);
                            }
                        }
                    } else if (loadDay.noPickups) {
                        if ((0, _moment.default)(day.date).isBefore(loadDay.lastDelivery)) {
                            if (day.hasOwnProperty('color')) {
                                Ember.set(day, 'color', this.setStatusColor(loadDay.loadStatus));
                            } else {
                                day['color'] = this.setStatusColor(loadDay.loadStatus);
                            }
                        }
                    } else {
                        if ((0, _moment.default)(day.date).isAfter(loadDay.firstPickup) && (0, _moment.default)(day.date).isBefore(loadDay.lastDelivery)) {
                            if (day.hasOwnProperty('color')) {
                                Ember.set(day, 'color', this.setStatusColor(loadDay.loadStatus));
                            } else {
                                day['color'] = this.setStatusColor(loadDay.loadStatus);
                            }
                        }
                    }
                });

                if (loadDay.hasMoreStops) {
                    loadDay.days.forEach(day => {
                        if ((0, _moment.default)(day.date).isAfter(loadDay.lastDelivery)) {
                            if (day.hasOwnProperty('color')) {
                                Ember.set(day, 'color', this.setStatusColor(loadDay.loadStatus));
                            } else {
                                day['color'] = this.setStatusColor(loadDay.loadStatus);
                            }
                        }
                    });
                }
            });

            return formattedLoads;
        }),

        selectedTrucksIds: Ember.computed('selectedTrucks', function () {
            let selectedTrucks = this.get('selectedTrucks');
            let truckIds = [];
            selectedTrucks.map(record => {
                truckIds.push(record.get('id'));
            });

            return truckIds.toString();
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
                this.set('newEventModal', false);
                this.set('dayModal', false);
                this.set('loadModal', false);
                this.set('driverInfoModal', false);
            },
            //day click actions
            showDay(day, loadId) {
                let events = day.events;
                let sortedEvents = [];

                if (events) {
                    sortedEvents = events.sortBy('eventId').reverse();
                }
                this.set('dayModal', true);
                this.set('selectedDay', day);
                this.set('selectedLoadId', loadId);
                this.set('selectedStops', day.stops);
                this.set('selectedEvents', sortedEvents);
            },
            closeDayModal() {
                this.send('closeModal');
            },

            //events
            addEvent(day, loadId) {
                this.set('newEventModal', true);
                this.set('selectedLoadId', loadId);
                let event = this.get('store').createRecord('event');
                let randomId = Math.floor(Math.random() * 10000) + 1000;
                event.set('id', randomId);
                event.set('date', day.date);
                this.set('currentEvent', event);
            },

            closeEventModal() {
                this.send('closeModal');
            },

            saveEvent(event) {

                let selectedLoadId = this.get('selectedLoadId');
                let self = this;
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + '/loads-board';
                this.set('isLoadingData', true);

                this.get('store').findRecord('load', selectedLoadId).then(function (selectedLoad) {
                    event.set('load', selectedLoad);
                    event.set('id', null);

                    //Updating table with event
                    let queryingPeriod = self.get('queryingPeriod');
                    let newQueryingPeriod = self.get('newQueryingPeriod') || {};

                    newQueryingPeriod.start = (0, _moment.default)(queryingPeriod.start).format('MM/DD/YYYY');
                    newQueryingPeriod.end = (0, _moment.default)(queryingPeriod.end).format('MM/DD/YYYY');
                    newQueryingPeriod.loadStatus = self.get('selectedLoadStatus') || 'ALL';
                    newQueryingPeriod.trucks = self.get('selectedTrucksIds') || 'all';

                    event.save().then(function () {
                        basicAjax.request('GET', url, newQueryingPeriod, true).then(function (response) {
                            self.set('model.loads', response);
                            self.set('newEventModal', false);
                            self.set('isLoadingData', false);
                        });
                    });
                });
            },

            //querying loads per period
            getPeriodLoads(period) {
                let queryingPeriod = this.get('queryingPeriod');
                let newQueryingPeriod;
                let self = this;

                if (period === "previous") {
                    newQueryingPeriod = {
                        start: (0, _moment.default)(queryingPeriod.start).subtract(7, 'days'),
                        end: (0, _moment.default)(queryingPeriod.end).subtract(7, 'days')
                    };
                } else if (period === "next") {
                    newQueryingPeriod = {
                        start: (0, _moment.default)(queryingPeriod.start).add(7, 'days'),
                        end: (0, _moment.default)(queryingPeriod.end).add(7, 'days')
                    };
                } else {
                    newQueryingPeriod = {
                        start: (0, _moment.default)().startOf('week'),
                        end: (0, _moment.default)().endOf('week')
                    };
                }

                //update weekdays
                let formattedDays = this.createWeekDays(newQueryingPeriod.start, newQueryingPeriod.end);
                this.set('weekDays', formattedDays);

                //set new querying period
                this.set('queryingPeriod', newQueryingPeriod);

                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + '/loads-board';

                let filter = {
                    start: (0, _moment.default)(newQueryingPeriod.start).format('MM/DD/YYYY'),
                    end: (0, _moment.default)(newQueryingPeriod.end).format('MM/DD/YYYY'),
                    loadStatus: this.get('selectedLoadStatus') || 'ALL',
                    trucks: this.get('selectedTrucksIds') || 'all'
                };

                this.set('isLoadingData', true);
                basicAjax.request('GET', url, filter, true).then(function (response) {
                    self.set('model.loads', response);
                    self.set('isLoadingData', false);
                });
            },

            //show driver info 
            showDriverInfo(driverId) {
                this.set('driverInfoModal', true);
                let self = this;
                this.get('store').findRecord('driver', driverId).then(function (driver) {
                    self.set('shownDriver', driver);
                });
            },

            addLoad() {
                this.set('loadModal', true);
                let load = this.get('store').createRecord('load');
                this.set('currentLoad', load);
            },

            confirmAddLoad(load) {
                let self = this;
                load.set('status', 'Planned');
                load.save().then(function () {
                    self.send('updateTable');
                });
                this.set('loadModal', false);
            },

            cancelAddingLoad() {
                let currentLoad = this.get('currentLoad');
                currentLoad.unloadRecord();
                this.set('loadModal', false);
            },

            didSelectTruck(trucks) {
                this.set('selectedTrucks', trucks);
                this.send('queryWithFilter', 'trucks', this.get('selectedTrucksIds'));
            },

            toggleAllTrucks() {
                let allTrucksValue = this.get('allTrucks');
                this.toggleProperty('allTrucks');

                let filterValue;
                if (allTrucksValue) {
                    filterValue = this.get('selectedTrucksIds');
                } else {
                    filterValue = 'all';
                }

                this.send('queryWithFilter', 'trucks', filterValue);
            },

            radioClicked(value) {
                this.set('selectedLoadStatus', value);
                this.send('queryWithFilter', 'loadStatus', value);
            },

            queryWithFilter(filterName, filterValue) {
                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/loads-board';
                let self = this;
                this.set('isLoading', true);

                let newQueryingPeriod = this.get('newQueryingPeriod') || {};

                let queryingPeriod = this.get('queryingPeriod');

                newQueryingPeriod.start = (0, _moment.default)(queryingPeriod.start).format('MM/DD/YYYY');
                newQueryingPeriod.end = (0, _moment.default)(queryingPeriod.end).format('MM/DD/YYYY');
                newQueryingPeriod.loadStatus = this.get('selectedLoadStatus') || 'ALL';
                newQueryingPeriod.trucks = this.get('selectedTrucksIds') || 'all';

                newQueryingPeriod[filterName] = filterValue;
                this.set('newQueryingPeriod', newQueryingPeriod);

                basicAjax.request('GET', url, newQueryingPeriod, true).then(function (response) {
                    self.set('model.loads', response);
                    self.set('isLoadingData', false);
                });
            }
        }
    });
});