define('smart-tracking-webapp/components/high-charts', ['exports', 'ember-highcharts/components/high-charts'], function (exports, _highCharts) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _highCharts.default.extend({
        /**
         * Event that fires on change of underlying data for chart and renders it with new data
         * @params {object} chartData
         */
        contentDidChange: Ember.observer('content', function () {
            let chartOptions = this.get('chartOptions');
            let chart = this.get('chart');

            if (chartOptions.chart.type === 'column' || chartOptions.chart.type === 'area') {
                let yAxis = '';

                if (Array.isArray(chartOptions.yAxis)) {
                    yAxis = chartOptions.yAxis[0];
                } else {
                    yAxis = chartOptions.yAxis;
                }
            }
            let xAxis = this.chartOptions.xAxis;
            //check does categories on xAxis exists
            let arrayOfCategories = [];
            if (xAxis) {
                arrayOfCategories = xAxis.categories;
            }
            this.get('chart').redraw();
            // Check if chart has been initialised
            if (this.get('chart')) {

                let chart = this.get('chart');
                chart.xAxis[0].setCategories(arrayOfCategories);
                chart.redraw();
            }
        })
    });
});