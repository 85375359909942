define('smart-tracking-webapp/controllers/protected/user-profile', ['exports', 'smart-tracking-webapp/validations/user-profile'], function (exports, _userProfile) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service('session'),
        notifications: Ember.inject.service('toast'),
        userProfileValidation: _userProfile.default,

        actions: {
            saveUser(changeset) {
                const notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let userId = this.get('session.data.authenticated.user_id');

                if (isValid) {
                    let currentUser = this.get('store').peekRecord('user', userId);
                    changes.forEach(function (change) {
                        currentUser.set(change.key, change.value);
                    });
                    currentUser.save().then(function () {
                        notifications.success('User successfully saved');
                    });
                }
            },

            rollback(changeset) {
                return changeset.rollback();
            },

            changeAvatar() {
                this.set('avatarModal', true);
            },

            closeAvatarModal() {
                this.set('avatarModal', false);
                this.set('filePath', false);
            },

            saveNewAvatar(filePath) {
                let userId = this.get('session.data.authenticated.user_id');
                let currentUser = this.get('store').peekRecord('user', userId);
                currentUser.set('avatarImage', filePath);
                currentUser.save();
                this.send('closeAvatarModal');
            },

            changePassword() {
                this.set('passwordModal', true);
            },

            closeModal() {
                this.set('passwordModal', false);
            }
        }
    });
});