define('smart-tracking-webapp/routes/protected/safety/drivers/driver/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {

            let driverId = transition.params['protected.safety.drivers.driver'].id;
            let routeCrud = this.get('routeCrud');
            let document = routeCrud.getRecord(params, 'driver-document', 'document_id');

            return Ember.RSVP.hash({
                driver: this.get('store').findRecord('driver', driverId),
                document: document.record,
                randomId: document.randomId,
                driverDocumentTypes: this.get('store').findAll('driver-document-type')
            });
        }

    });
});