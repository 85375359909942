define('smart-tracking-webapp/controllers/protected/safety/trucks/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        trucksTable: Ember.computed('model.trucks.[]', 'model.trucks', function () {

            let trucks = this.get('model').trucks;
            return {
                header: [{
                    title: 'Truck number',
                    propertyName: 'truckId',
                    routeName: "protected.safety.trucks.truck"
                }, { title: 'Licence', propertyName: 'licence' }, { title: 'Vin', propertyName: 'vin' }, { title: 'Owner', propertyName: 'owner.name' }, { title: 'Status', propertyName: 'status' }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: trucks
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete truck' : 'Add truck';
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            cancelAddingTruck() {
                let currentTruck = this.get('currentTruck');
                currentTruck.unloadRecord();
                this.send('closeModal');
            },

            deleteTruck(id) {
                let truck = this.get('store').peekRecord('truck', id);
                this.set('currentTruck', truck);
                this.set('openModal', true);
                this.set('isDelete', true);
            },
            confirmDeleteTruck() {
                let truck = this.get('currentTruck');
                truck.deleteRecord();
                truck.save();
                this.send('closeModal');
            },
            addTruck() {
                this.set('openModal', true);
                let truck = this.get('store').createRecord('truck', {});
                this.set('currentTruck', truck);
                this.set('isDelete', false);
            },
            confirmAddTruck(truck) {
                let self = this;
                truck.save().then(function () {
                    self.get('store').query('truck', {}).then(function (truck) {
                        self.set('model.trucks', truck);
                    });
                }).catch(function () {
                    truck.unloadRecord();
                });
                this.send('closeModal');
            }
        }
    });
});