define('smart-tracking-webapp/routes/protected/user-profile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function () {
            let userId = this.get('session.data.authenticated.user_id');
            return this.get('store').findRecord('user', userId, { include: 'company' });
        },

        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});