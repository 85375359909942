define('smart-tracking-webapp/routes/protected/safety/settings/general', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {
            return Ember.RSVP.hash({
                driverStatuses: this.get('store').findAll('driver-status')
            });
        }
    });
});