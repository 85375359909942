define('smart-tracking-webapp/controllers/protected/accounting/accounts/drivers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        driversTable: Ember.computed('model.drivers.[]', 'model.drivers', function () {

            let drivers = this.get('model').drivers;
            return {
                header: [{
                    title: 'Driver ID',
                    propertyName: 'driverId',
                    routeName: "protected.accounting.accounts.drivers.driver"
                }, {
                    title: 'Full name',
                    propertyName: 'fullName',
                    routeName: "protected.accounting.accounts.drivers.driver"
                }, { title: 'Address', propertyName: 'address' }, { title: 'Telephone', propertyName: 'telephone' }, { title: 'Email', propertyName: 'email' }, { title: 'Fuel card number', propertyName: 'fuelCard' }, { title: 'Bank routing number', propertyName: 'bankRoutingNumber' }, { title: 'Bank account number', propertyName: 'bankAccountNumber' }, { title: 'Status', propertyName: 'status.name' }],
                data: drivers
            };
        })
    });
});