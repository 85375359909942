define('smart-tracking-webapp/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        dba: _emberData.default.attr('string'),
        street: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        zip: _emberData.default.attr('number'),
        state: _emberData.default.attr('string'),
        mailingStreet: _emberData.default.attr('string'),
        mailingCity: _emberData.default.attr('string'),
        mailingZip: _emberData.default.attr('number'),
        mailingState: _emberData.default.attr('string'),
        faxNumber: _emberData.default.attr('string'),
        cellPhoneNumber: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        safetyNumber: _emberData.default.attr('string'),
        dispatchNumber: _emberData.default.attr('string'),
        trackingNumber: _emberData.default.attr('string'),
        accountingNumber: _emberData.default.attr('string'),
        usdot: _emberData.default.attr('string'),
        mc: _emberData.default.attr('string'),
        irs: _emberData.default.attr('string'),
        documents: _emberData.default.hasMany('company-document'),
        drivers: _emberData.default.hasMany('driver'),
        email: _emberData.default.attr('string'),
        users: _emberData.default.hasMany('user'),
        zipAPIkey: _emberData.default.attr('string'),
        loadPrefix: _emberData.default.attr('string'),
        loadStart: _emberData.default.attr('number'),
        logo: _emberData.default.attr('string'),
        allowedUsers: _emberData.default.attr('number')
    });
});