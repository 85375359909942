define('smart-tracking-webapp/components/validation-field', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        classNames: ['validation-field-component'],
        classNameBindings: ['hasSymbol:validation-field-with-symbol'],

        init() {
            this._super(...arguments);
            switch (this.fieldType) {
                case 'input':
                    this.set('isInput', true);
                    break;
                case 'textarea':
                    this.set('isTextArea', true);
                    break;
                case 'select':
                    this.set('isSelect', true);
                    break;
                case 'multiselect':
                    this.set('isMultiSelect', true);
                    break;
                case 'datepicker':
                    this.set('isDatePicker', true);
                    break;
                case 'timepicker':
                    this.set('isTimePicker', true);
                    break;
                case 'checkbox':
                    this.set('isCheckBox', true);
                    break;
                case 'switcher':
                    this.set('isSwitcher', true);
                    break;
                case 'addselect':
                    this.set('isAddSelect', true);
                    break;
                case 'searchselect':
                    this.set('isSearchSelect', true);
                    break;
                default:
                    this.set('isInput', true);
                    break;
            }

            //starting year for date picker
            let startYear = this.startYear || 1950;
            let currentYear = Number((0, _moment.default)().format('YYYY')) + 10; //3 more years added in case of planning
            let totalYears = currentYear - startYear;

            this.set('years', Array(...Array(totalYears)).map((_, i) => `${i + startYear}`));
            this.set('months', _moment.default.months());
        },

        didInsertElement: function () {
            this.set('componentId', this.elementId);
        },

        selectedValue: Ember.computed('value', function () {
            if (this.value) {
                return this.value;
            }
        }),

        selectedDate: Ember.computed('value', function () {
            if (this.value) {
                return this.value;
            }
        }),

        selectedCheck: Ember.computed('value', function () {
            if (this.value) {
                return this.value;
            }
        }),

        selectedDateTime: Ember.computed('value', function () {
            if (this.value) {
                return this.value;
            }
        }),

        charCounter: Ember.computed('value', function () {
            if (this.value) {
                return this.value.length;
            } else {
                return 0;
            }
        }),

        actions: {
            didSelectValue(value) {
                this.set('selectedValue', value); //for select
                this.set('value', value); //for changeset
                this.sendAction('didSelectValue', value);
            },

            didSelectDate(date) {
                this.set('value', (0, _moment.default)(date).format('MM/DD/YYYY')); //for changeset
                this.set('selectedDate', date); //for input field and calendar
            },

            didSelectDateInTimePicker(date) {
                let selectedTime = this.get('selectedTime') || '00:00';
                let selectedDate = (0, _moment.default)(date).format('MM/DD/YYYY');
                this.set('value', selectedDate + ' ' + selectedTime + ':00'); //for changeset
            },

            setTime(time) {
                let self = this;
                let selectedDate = (0, _moment.default)(self.get('selectedDateTime')).format('MM/DD/YYYY');
                this.set('selectedTime', time);
                this.set('value', selectedDate + ' ' + time + ':00');
            },

            changeCenter(unit, calendar, val) {
                let newCenter = calendar.center.clone()[unit](val);
                calendar.actions.changeCenter(newCenter);
            },

            toggleValue() {
                let self = this;
                this.set('selectedCheck', !self.get('selectedCheck'));
                this.set('value', self.get('selectedCheck'));
            },

            //create new object or record from select
            createObject(value) {

                let isOnlyObject = this.get('isOnlyObject');
                let modelProperty = this.get('modelProperty') || 'name';

                let newRecord = {};

                if (isOnlyObject) {
                    newRecord[modelProperty] = value;
                } else {
                    let modelName = this.get('modelName');
                    newRecord = this.get('store').createRecord(modelName);
                    newRecord.set(modelProperty, value);
                }
                this.set('selectedValue', newRecord); //for select
                this.set('value', newRecord); //for changeset
                this.sendAction('didSelectValue', newRecord, true);
            },

            searchEntity(value) {
                if (value.length > 2) {
                    setTimeout(() => {
                        this.sendAction('searchEntity', value);
                    }, 200);
                }
            }
        }

    });
});