define('smart-tracking-webapp/controllers/protected/users', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        usersTable: Ember.computed('model.users.[]', 'model.users', function () {

            let users = this.get('model').users;
            return {
                header: [{
                    title: 'Username',
                    propertyName: 'username',
                    routeName: "protected.users.user"
                }, { title: 'First name', propertyName: 'firstName' }, { title: 'Last name', propertyName: 'lastName' }, { title: 'Company', propertyName: 'company.name' }, { title: 'Role', propertyName: 'roles' }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: users
            };
        }),

        actions: {
            closeModal() {
                this.set('deleteModal', false);
            },
            deleteUser(id) {
                let user = this.get('store').peekRecord('user', id);
                this.set('currentUser', user);
                this.set('deleteModal', true);
            },
            confirmDeleteUser() {
                let user = this.get('currentUser');
                user.deleteRecord();
                user.save();
                this.send('closeModal');
            },
            addUser() {
                this.transitionToRoute('protected.users.user', 'new');
            }
        }
    });
});