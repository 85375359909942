define('smart-tracking-webapp/components/models-table', ['exports', 'ember-models-table/components/models-table'], function (exports, _modelsTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelsTable.default.extend({
        init() {
            this._super(...arguments);
            this.set('themeInstance.messages', {
                "searchLabel": "",
                "searchPlaceholder": "Search",
                "groupByLabel": "Group by",
                "columns-title": "Column",
                "columns-showAll": "Show all columns",
                "columns-hideAll": "Hide all columns",
                "columns-restoreDefaults": "Restore defaults",
                "tableSummary": "Show %@ - %@ of %@",
                "allColumnsAreHidden": "All columns are hidden",
                "noDataToShow": "No data to show",
                "editRowButtonLabel": "Edit",
                "saveRowButtonLabel": "Save",
                "cancelRowButtonLabel": "Cancel"
            });
        },

        pageSizeValues: [5, 10, 20, 50]
    });
});