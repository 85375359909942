define('smart-tracking-webapp/models/driver-document-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        expireNotification: _emberData.default.attr('number'),
        repeatNotification: _emberData.default.attr('number'),
        isRequired: _emberData.default.attr('boolean'),
        isPostEmployment: _emberData.default.attr('boolean'),
        isRequiredIssueDate: _emberData.default.attr('boolean'),
        isRequiredExpiredDate: _emberData.default.attr('boolean')
    });
});