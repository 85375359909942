define('smart-tracking-webapp/routes/protected/tracking', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),
        activate() {
            let defaultItems = this.get('navigationService').get('defaultVisibleItems');
            let navigationItems = [{
                icon: 'user',
                position: 2,
                label: 'Tracking',
                link: 'protected.tracking'
            }];
            let completeNavigationItems = navigationItems.concat(defaultItems);

            //sorting by position
            completeNavigationItems.sort(function (a, b) {
                return a.position - b.position;
            });
            this.get('navigationService').set('visibleItems', completeNavigationItems);
        },
        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});