define('smart-tracking-webapp/models/company-document', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        issueDate: _emberData.default.attr('string'),
        expireDate: _emberData.default.attr('string'),
        path: _emberData.default.attr('string'),
        company: _emberData.default.belongsTo('company')
    });
});