define('smart-tracking-webapp/controllers/protected/dispatch/brokers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        openModal: false,
        brokersTable: Ember.computed('model.brokers.[]', 'model.brokers', function () {

            let brokers = this.get('model').brokers;
            return {
                header: [{
                    title: 'Name',
                    propertyName: 'name',
                    routeName: 'protected.dispatch.brokers.broker'
                }, {
                    title: 'MC',
                    propertyName: 'mc'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: brokers
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete broker' : 'Add broker';
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },
            //delete actions
            deleteBroker(id) {
                let broker = this.get('store').peekRecord('broker', id);
                this.set('currentBroker', broker);
                this.set('openModal', true);
                this.set('isDelete', true);
            },
            confirmDeleteBroker() {
                let broker = this.get('currentBroker');
                broker.deleteRecord();
                broker.save();
                this.send('closeModal');
            },
            //add actions
            addBroker() {
                this.set('openModal', true);
                let broker = this.get('store').createRecord('broker');
                this.set('currentBroker', broker);
                this.set('isAdd', true);
                this.set('isDelete', false);
            },
            confirmAddBroker(broker) {
                let self = this;
                this.set('isLoadingData', true);
                broker.save().then(function () {
                    self.set('model.brokers', self.get('store').query('broker', {}));
                    self.set('isLoadingData', false);
                });
                this.send('closeModal');
            },
            cancelAddingBroker() {
                let currentBroker = this.get('currentBroker');
                currentBroker.unloadRecord();
                this.send('closeModal');
            }
        }
    });
});