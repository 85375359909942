define('smart-tracking-webapp/controllers/protected/accounting/accounts/owners/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        trucksTable: Ember.computed('model.trucks.[]', 'model.trucks', function () {

            let trucks = this.get('model').trucks || [];
            return {
                header: [{
                    title: 'Truck ID',
                    propertyName: 'truckId',
                    routeName: "protected.accounting.accounts.owners.truck"
                }, {
                    title: 'Licence',
                    propertyName: 'licence'
                }, {
                    title: 'VIN',
                    propertyName: 'vin'
                }],
                data: trucks
            };
        }),

        actions: {
            didSelectOwner(selected) {
                this.set('selectedOwner', selected);
                this.get('store').query('truck', { owner: selected.get('id') }).then(trucks => {
                    this.set('model.trucks', trucks);
                });
            }
        }
    });
});