define('smart-tracking-webapp/validations/provider', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        apiKey: (0, _validators.validatePresence)(true),
        eldProvider: (0, _validators.validatePresence)(true)
    };
});