define('smart-tracking-webapp/routes/protected/safety/drivers/driver', ['exports', 'smart-tracking-webapp/config/environment', 'moment'], function (exports, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        model: function (params) {
            let basicAjax = this.get('basicAjax');
            let url = _environment.default.APP.API_HOST + "/log-drivers?driver=" + params.id;
            return Ember.RSVP.hash({
                documentTypes: this.get('store').findAll('driver-document-type'),
                drivers: this.get('store').query('driver', { pagination: false }),
                driver: this.get('store').findRecord('driver', params.id, { include: 'documents,drugTests,accidents,status' }),
                driverStatuses: this.get('store').findAll('driver-status'),
                driverEvents: basicAjax.request('GET', url).then(function (response) {

                    response[params.id].forEach(item => {
                        let formattedTime = (0, _moment.default)(item.time, 'DD/MM/YYYY HH:mm:SS').utcOffset('-06:00').format('MM/DD/YYYY');
                        item.time = formattedTime;
                    });

                    return response[params.id];
                }),
                owners: this.get('store').query('owner-group', { pagination: false }),
                eldProviders: this.get('store').query('eld-provider', {})
            });
        }
    });
});