define('smart-tracking-webapp/controllers/protected/dispatch/loads/load', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),
        notifications: Ember.inject.service('toast'),
        basicAjax: Ember.inject.service('basicAjax'),
        stopModal: false,
        deleteEventModal: false,
        deleteChargeModal: false,

        addingEnabled: Ember.computed('model.currentUser', function () {
            let currentUserRoles = this.get('model').currentUser.get('roles');
            let enabled = false;

            if (currentUserRoles.includes('ROLE_ADMIN') || currentUserRoles.includes('ROLE_DISPATCH')) {
                enabled = true;
            }

            return enabled;
        }),

        extraCharges: Ember.computed('model.load', 'modal.load.charges', 'model.charges.[]', function () {
            let loadCharges = this.get('model.load.charges') || [];

            let totalExtraCharges = 0;
            loadCharges.forEach(charge => {
                totalExtraCharges += charge.get('cost') || 0;
            });

            return totalExtraCharges;
        }),

        chargesTable: Ember.computed('model.load', 'modal.load.charges', 'model.charges.[]', function () {
            let loadCharges = this.get('model').load.get('charges') || [];

            return {
                header: [{
                    title: 'Type',
                    propertyName: 'category'
                }, {
                    title: 'Cost ($)',
                    propertyName: 'cost'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: loadCharges

            };
        }),

        stopsTable: Ember.computed('model.stops', 'model.stops', 'model.stops.[]', 'model.load.stops.[]', 'model.load.stops', function () {
            let loadStops = this.get('model').stops || [];

            return {
                header: [{
                    title: 'Time',
                    propertyName: 'formattedTime'
                }, {
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Facility',
                    propertyName: 'facility.name'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: loadStops
            };
        }),

        sortedEvents: Ember.computed('model.load', 'model.load.events', 'model.load.events.[]', function () {
            let loadEvents = this.get('model').load.get('events') || [];
            return loadEvents.sortBy('date').sortBy('id').reverse();
        }),

        eventsTable: Ember.computed('sortedEvents', function () {
            let sortedEvents = this.get('sortedEvents');

            return {
                header: [{
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Note',
                    propertyName: 'note'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: sortedEvents
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete stop' : 'Add stop';
        }),

        emptyDocuments: Ember.computed('model.load', 'model.load.documents', 'model.load.documents.[]', function () {
            let documents = this.get('model').load.get('documents');
            return documents.get('content').length === 0;
        }),

        loadDocuments: Ember.computed('model.load', 'model.load.documents', 'model.load.documents.[]', function () {
            let documents = this.get('model').load.get('documents') || [];

            return {
                header: [{ title: 'Type', propertyName: 'type' }, { title: 'Description', propertyName: 'description' }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: documents
            };
        }),

        actions: {

            closeModal() {
                this.set('stopModal', false);
                this.set('deleteEventModal', false);
                this.set('deleteChargeModal', false);
                this.set('isDelete', false);
                this.set('deleteDocumentModal', false);
            },

            confirmEditLoad(load) {
                const notifications = this.get('notifications');
                load.save().then(function () {
                    notifications.success('Load successfully saved');
                });
            },

            //load extra charges
            addLoadCharge() {
                this.transitionToRoute('protected.dispatch.loads.load.charge', 'new');
            },

            editLoadCharge(id) {
                this.transitionToRoute('protected.dispatch.loads.load.charge', id);
            },

            deleteLoadCharge(id) {
                let charge = this.get('store').peekRecord('charge', id);
                this.set('currentCharge', charge);
                this.set('deleteChargeModal', true);
            },

            confirmDeleteLoadCharge() {
                let charge = this.get('currentCharge');
                charge.deleteRecord();
                charge.save();
                this.send('closeModal');
            },

            //load stops
            addLoadStop() {
                this.set('stopModal', true);
                let stop = this.get('store').createRecord('stop');
                let randomId = Math.floor(Math.random() * 10000) + 1000;
                stop.set('id', randomId);
                this.set('newStop', stop);
            },

            saveNewStop(stop) {

                let self = this;
                let notifications = this.get('notifications');
                let load = this.get('model').load;
                let stops = this.get('model').stops.content;

                stop.set('load', load);
                stop.set('id', null);
                stop.save().then(function () {
                    self.get('store').query('stop', {
                        load: load.get('id'),
                        include: 'facility'
                    }).then(function (stops) {
                        self.set('model.stops', stops);
                    });
                }).catch(function () {
                    stop.unloadRecord();
                });
                this.send('closeModal');
            },

            editLoadStop(id) {
                this.transitionToRoute('protected.dispatch.loads.load.stop', id);
            },

            deleteLoadStop(id) {
                let stop = this.get('store').peekRecord('stop', id);
                this.set('currentStop', stop);
                this.set('stopModal', true);
                this.set('isDelete', true);
            },

            confirmDeleteLoadStop() {
                let stop = this.get('currentStop');
                stop.deleteRecord();
                stop.save();
                this.send('closeModal');
            },

            //load events
            addLoadEvent() {
                this.transitionToRoute('protected.dispatch.loads.load.event', 'new');
            },

            editLoadEvent(id) {
                this.transitionToRoute('protected.dispatch.loads.load.event', id);
            },

            deleteLoadEvent(id) {
                let event = this.get('store').peekRecord('event', id);
                this.set('currentEvent', event);
                this.set('deleteEventModal', true);
            },

            confirmDeleteLoadEvent() {
                let event = this.get('currentEvent');
                event.deleteRecord();
                event.save();
                this.send('closeModal');
            },

            backToLoads() {
                history.back();
            },

            //documents
            addLoadDocument() {
                this.transitionToRoute('protected.dispatch.loads.load.document', 'new');
            },

            deleteLoadDocument(id) {
                let document = this.get('store').peekRecord('load-document', id);
                this.set('currentDocument', document);
                this.set('deleteDocumentModal', true);
            },

            confirmDeleteDocument() {
                let document = this.get('currentDocument');
                document.deleteRecord();
                document.save();
                this.send('closeModal');
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-load-documents/" + id;
                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            }
        }

    });
});