define('smart-tracking-webapp/controllers/protected/safety/accidents/accident', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                this.transitionToRoute('protected.safety.accidents');
            },

            saveAccident(accident, randomId) {

                let self = this;
                if (randomId) {
                    accident.set('id', null);
                }

                accident.save().then(function () {
                    self.send('refreshModel');
                    self.transitionToRoute('protected.safety.accidents');
                });
            }
        }
    });
});