define('smart-tracking-webapp/models/advance', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        driver: _emberData.default.belongsTo('driver', { inverse: null }),
        truck: _emberData.default.belongsTo('truck', { inverse: null }),
        amount: _emberData.default.attr('number'),
        description: _emberData.default.attr('string'),
        refunds: _emberData.default.attr('boolean'),
        date: _emberData.default.attr(),
        formattedDate: Ember.computed('created', function () {
            return this.get('date') && (0, _moment.default)(this.get('date')).format('MM/DD/YYYY');
        }),
        statement: _emberData.default.belongsTo('statement', { inverse: null }),
        status: _emberData.default.attr(),
        category: _emberData.default.attr(''), // only front
        type: _emberData.default.attr(''), //only front
        typeValue: _emberData.default.attr(''), //only front
        numOfTimes: _emberData.default.attr('number') //only front
    });
});