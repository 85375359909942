define('smart-tracking-webapp/components/user-avatar', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['user-avatar-component'],

        host: _environment.default.APP.HOST,

        imagePath: Ember.computed('imgUrl', function () {
            if (this.get('companyLogo')) {
                return this.get('imgUrl') ? this.get('imgUrl') : 'img/default-logo.png';
            } else {
                return this.get('imgUrl') ? this.get('imgUrl') : '/img/default-avatar.png';
            }
        }),

        actions: {
            changeAvatar() {
                this.sendAction('changeAvatar');
            }
        }
    });
});