define('smart-tracking-webapp/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPISerializer.extend({
        keyForAttribute: function (attr) {
            return attr;
        },
        keyForRelationship(key) {
            return key;
        }
        //
        // normalizeResponse(store, primaryModelClass, payload, id, requestType) {
        //
        //
        //     if(requestType === 'findAll'){
        //         // let route = payload.links.self + '/';
        //         payload.data = (payload.data).map(record => {
        //
        //             return  {
        //                 attributes: record.attributes,
        //                 // id: (record.id).replace(route, ""),
        //                 id: record.attributes._id,
        //                 type: record.type
        //             }
        //         });
        //
        //     }else {
        //         payload.data.id = id;
        //     }
        //
        //     return this._super(...arguments);
        // }
    });
});