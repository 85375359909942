define('smart-tracking-webapp/components/truck-board-row', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['truck-board-row m-table__row'],
        notEditable: true,
        openMoreInfo: false,
        basicAjax: Ember.inject.service('basicAjax'),
        alreadyOpened: false,

        actions: {
            openMoreInfo(id) {
                this.toggleProperty('openMoreInfo');
                const basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/truck-board/" + id;

                if (!this.get('alreadyOpened')) {
                    let self = this;
                    this.set('isLoading', true);
                    basicAjax.request('GET', url).then(function (response) {
                        self.set('moreInfo', response);
                        self.set('isLoading', false);
                    });
                }
                this.set('alreadyOpened', true);
            },

            showTruck(id) {
                this.sendAction('showTruck', id);
            },
            showDriver(id) {
                this.sendAction('showDriver', id);
            },
            showEquipment(id) {
                this.sendAction('showEquipment', id);
            },
            didSelectDispatcher(dispatcher) {
                let truckId = this.get('truck.id');
                this.sendAction('didSelectDispatcher', truckId, dispatcher);
                this.set('truck.dispatcher', dispatcher);
            },
            editNote() {
                this.set('notEditable', false);
            },
            saveNote(noteValue, truckId) {
                this.sendAction('saveNote', noteValue, truckId);
                this.set('notEditable', true);
            }
        }
    });
});