define('smart-tracking-webapp/models/fuel', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        truck: _emberData.default.belongsTo('truck', { inverse: null }),
        amount: _emberData.default.attr('number'),
        created: _emberData.default.attr('date'),
        city: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        code: _emberData.default.attr('string'),
        quantity: _emberData.default.attr('number'),
        formattedDate: Ember.computed('created', function () {
            return (0, _moment.default)(this.get('created')).format('MM/DD/YYYY HH:mm');
        }),
        statement: _emberData.default.belongsTo('statement', { inverse: null })
    });
});