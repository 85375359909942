define('smart-tracking-webapp/components/ytd-summary', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['ytd-summary-component'],
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);
            this.set('openModal', false);

            //starting year for date picker
            let currentYear = Number(moment().format('YYYY'));
            let startYear = Number(moment().format('YYYY')) - 5;
            let totalYears = currentYear - startYear;

            this.set('years', Array(...Array(totalYears + 1)).map((_, i) => `${i + startYear}`));
            this.set('selectedYear', currentYear);
        },

        actions: {
            openModal() {
                this.set('openModal', true);
            },
            closeModal() {
                this.set('openModal', false);
            },
            generateSummary(id) {
                let selectedYear = this.get('selectedYear');
                let self = this;
                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + "/get-ytd-summary/" + id;

                basicAjax.request('GET', url, { year: this.get('selectedYear'), type: this.get('isDriver') ? 'driver' : 'owner' }, true).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();

                    self.set('openModal', false);
                });
            }
        }
    });
});