define('smart-tracking-webapp/routes/protected/dispatch/facilities/facility', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let facility = routeCrud.getRecord(params, 'facility');
            //let facilityId = transition.params['protected.dispatch.facilities.facility'].id;

            return Ember.RSVP.hash({
                facility: facility.record,
                randomId: facility.randomId
            });
        }
    });
});