define('smart-tracking-webapp/components/change-password-form-admin', ['exports', 'smart-tracking-webapp/validations/password-admin', 'smart-tracking-webapp/config/environment'], function (exports, _passwordAdmin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),
        password: {
            newPassword: null,
            passwordConfirmation: null
        },
        classNames: ['form'],
        passwordValidation: _passwordAdmin.default,

        actions: {
            updatePassword(changeset) {
                const notifications = this.get('notifications');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let self = this;
                let currentUserId = this.get('currentUser').get('id');

                if (isValid) {

                    let basicAjax = this.get('basicAjax');
                    let url = _environment.default.APP.API_HOST + `/users/${currentUserId}/change-password`;
                    let password = changeset.get('newPassword');
                    let confirmPassword = changeset.get('passwordConfirmation');
                    let jsonData = { password: password, confirmPassword: confirmPassword };

                    basicAjax.request('PATCH', url, jsonData).then(function () {
                        notifications.success('Password successfully changed');
                        self.sendAction('closeModal');
                    });
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');
                return changeset.rollback();
            }
        }
    });
});