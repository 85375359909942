define('smart-tracking-webapp/controllers/protected/safety/settings/vehicle-document-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        table: Ember.computed('model.vehicleDocumentTypes', 'model.vehicleDocumentTypes.[]', function () {

            let vehicleDocumentTypes = this.get('model').vehicleDocumentTypes;
            return {
                header: [{
                    title: 'Name',
                    propertyName: 'name'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: vehicleDocumentTypes,

                properties: [{
                    name: 'name',
                    label: 'Document name'
                },
                // {
                //     name: 'expireNotification',
                //     label: 'Reminder before/after (days)'
                // },
                // {
                //     name: 'repeatNotification',
                //     label: 'Repeat notification on (days)'
                // },
                {
                    name: 'isRequiredIssueDate',
                    label: 'Is required issue date',
                    fieldType: 'checkbox'
                }, {
                    name: 'isRequiredExpiredDate',
                    label: 'Is required expire date',
                    fieldType: 'checkbox'
                }]

            };
        })
    });
});