define('smart-tracking-webapp/components/main-box', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['main-box-wrapper'],
        classNameBindings: ['isHalf:main-box-wrapper--half', 'isDisabled:main-box-wrapper--disabled']
    });
});