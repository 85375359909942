define('smart-tracking-webapp/components/navigation-menu', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['side-bar-menu'],

        navigationService: Ember.inject.service('navigationService')
    });
});