define('smart-tracking-webapp/controllers/protected/safety/drivers/driver', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),

        isInactiveDriver: Ember.computed('model.driver', 'model.driver.status', function () {
            return this.get('model').driver.get('status').get('name') !== 'active driver';
        }),

        driverDocuments: Ember.computed('model.driver', 'model.driver.documents', 'model.driver.documents.[]', function () {
            let documents = this.get('model').driver.get('documents') || [];

            return {
                header: [{ title: 'Type', propertyName: 'type.name' }, { title: 'Description', propertyName: 'description' }, {
                    title: 'Pre/post employment',
                    propertyName: 'type.isPostEmployment',
                    component: "table-pre-post-document"
                }, { title: 'Issue date', propertyName: 'formattedIssueDate' }, { title: 'Expire date', propertyName: 'formattedExpireDate' }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: documents
            };
        }),

        drugTestsTable: Ember.computed('model.driver', 'model.driver.drugTests', 'model.driver.drugTests.[]', function () {
            let drugTests = this.get('model').driver.get('drugTests') || [];
            return {
                header: [{
                    title: 'Type',
                    propertyName: 'type',
                    routeName: "protected.safety.drivers.driver.drug-test"
                }, {
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Status',
                    propertyName: 'status',
                    component: "table-drug-test"
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: drugTests
            };
        }),

        accidentsTable: Ember.computed('model.driver', 'model.driver.accidents', 'model.driver.accidents.[]', function () {
            let accidents = this.get('model').driver.get('accidents') || [];
            return {
                header: [{
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Driver',
                    propertyName: 'driver.fullName'
                }, {
                    title: 'Truck number',
                    propertyName: 'truck.truckId'
                }, {
                    title: 'Equipment number',
                    propertyName: 'equipment.equipmentId'
                }, {
                    title: 'Report number',
                    propertyName: 'reportNumber'
                }, {
                    title: 'Location',
                    propertyName: 'location'
                }, {
                    title: 'View',
                    component: "table-view-row",
                    disableSorting: true
                }],
                data: accidents
            };
        }),

        actions: {

            closeModal() {
                this.set('deleteDocumentModal', false);
                this.set('deleteDrugTest', false);
                this.set('accidentModal', false);
            },

            confirmEditDriver(driver) {
                driver.save();
            },

            //documents
            addDriverDocument() {
                this.transitionToRoute('protected.safety.drivers.driver.document', 'new');
            },

            deleteDriverDocument(id) {
                let document = this.get('store').peekRecord('driver-document', id);
                this.set('currentDocument', document);
                this.set('deleteDocumentModal', true);
            },

            confirmDeleteDocument() {
                let document = this.get('currentDocument');
                document.deleteRecord();
                document.save();
                this.send('closeModal');
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-driver-documents/" + id;
                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            },

            //drug tests
            addDrugTest() {
                this.transitionToRoute('protected.safety.drivers.driver.drug-test', 'new');
            },

            editDrugTest(id) {
                this.transitionToRoute('protected.safety.drivers.driver.drug-test', id);
            },

            deleteDrugTest(id) {
                let drugTest = this.get('store').peekRecord('drug-test', id);
                this.set('currentDrugTest', drugTest);
                this.set('deleteDrugTest', true);
            },

            confirmDeleteDrugTest() {
                let drugTest = this.get('currentDrugTest');
                drugTest.deleteRecord();
                drugTest.save();
                this.send('closeModal');
            },

            //actions on driver status
            hireDriver() {
                let notifications = this.get('notifications');
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/hire";
                let self = this;
                let driverId = { driverId: this.get('model.driver.id') };

                basicAjax.request('POST', url, driverId).then(function (response) {
                    if (response.length > 0) {
                        notifications.error('Missing documents', 'Error');
                        self.set('missingHireDocuments', response);
                    } else {
                        self.set('missingHireDocuments', false);
                        notifications.success('Driver is hired', 'Success');
                    }
                }, function () {
                    notifications.error('Server error', 'Error');
                });
            },

            sackDriver(driver) {
                let notifications = this.get('notifications');
                let driverStatuses = this.get('model').driverStatuses;
                let self = this;
                //set status to inactive
                let inactiveStatus = {};
                driverStatuses.forEach(status => {
                    if (status.get('name') === 'inactive driver') {
                        inactiveStatus = status;
                    }
                });
                driver.set('status', inactiveStatus);
                driver.save().then(function (driver) {
                    notifications.success('Driver is sacked', 'Success');
                    self.set('model.driver', driver);
                });
            },

            //accidents
            viewAccident(id) {
                let self = this;
                this.get('store').findRecord('accident', id, { include: 'truck, equipment' }).then(function (accident) {
                    self.set('accidentModal', true);
                    self.set('currentAccident', accident);
                });
            },

            backToDrivers() {
                this.transitionToRoute('protected.safety.drivers');
            }

        }

    });
});