define('smart-tracking-webapp/components/table-download-document', ['exports', 'smart-tracking-webapp/templates/components/table-download-document'], function (exports, _tableDownloadDocument) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableDownloadDocument.default,
        actions: {
            sendAction(actionName, recordId, event) {
                this.sendAction(actionName, recordId);
                event.stopPropagation();
            }
        }
    });
});