define('smart-tracking-webapp/controllers/protected/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        blockedSections: Ember.computed('model.currentUser', function () {

            let currentUser = this.get('model').currentUser;
            let userRoles = currentUser.get('roles');

            let blockedSections = {
                safety: true,
                dispatch: true,
                tracking: true,
                accounting: true
            };

            //depending on roles available sections
            //'ROLE_SAFETY', 'ROLE_DISPATCH', 'ROLE_TRACKING', 'ROLE_ACCOUNTING'
            if (userRoles.includes('ROLE_SAFETY')) {
                blockedSections.safety = false;
            }
            if (userRoles.includes('ROLE_DISPATCH')) {
                blockedSections.dispatch = false;
            }
            if (userRoles.includes('ROLE_TRACKING')) {
                blockedSections.tracking = false;
            }
            if (userRoles.includes('ROLE_ACCOUNTING')) {
                blockedSections.accounting = false;
            }

            if (userRoles.includes('ROLE_ADMIN')) {
                blockedSections = {
                    safety: false,
                    dispatch: false,
                    tracking: false,
                    accounting: false
                };
            }

            return blockedSections;
        })
    });
});