define('smart-tracking-webapp/components/add-load-form', ['exports', 'smart-tracking-webapp/validations/add-load'], function (exports, _addLoad) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        addLoadValidation: _addLoad.default,

        init() {
            this._super(...arguments);
            this.set('load.statusCheck', true); //inital value for statusCheck
        },

        actions: {

            saveLoad(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let load = this.get('load');
                let randomId = this.get('randomId');
                let selectedBrokerContact = this.get('selectedBrokerContact');

                if (isValid) {
                    changes.forEach(function (change) {
                        load.set(change.key, change.value);
                    });
                    load.set('brokerContact', selectedBrokerContact);
                    load.set('tripNumber', ''); //can't be null
                    this.sendAction('saveLoad', load, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let load = this.get('load');
                    load.unloadRecord();
                }
                return changeset.rollback();
            },

            //broker contact
            didSelectBroker(broker) {
                this.set('selectedBrokerContact', null);
                let self = this;
                let allBrokerContacts = [];
                if (broker) {
                    let selectedBrokerId = broker.get('id');
                    self.get('store').findRecord('broker', selectedBrokerId, { include: 'broker-contact' }).then(function (broker) {
                        allBrokerContacts = broker.get('contacts');
                        self.set('allBrokerContacts', allBrokerContacts);
                    });
                }
            },

            didSelectBrokerContact(contact) {
                this.set('selectedBrokerContact', contact);
            }
        }
    });
});