define('smart-tracking-webapp/components/driver-form', ['exports', 'smart-tracking-webapp/validations/driver'], function (exports, _driver) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        driver: null,
        classNames: ['form'],

        driverValidation: _driver.default,

        actions: {
            saveDriver(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let currentDriver = this.get('driver');
                let randomId = this.get('randomId');
                let driverIds = [];

                //checking empty fields
                if (isValid) {

                    //creating array of all drivers ids
                    this.get('allDrivers').forEach(driver => {
                        if (driver.get('driverId') !== currentDriver.get('driverId')) {
                            driverIds.push(driver.get('driverId'));
                        }
                    });

                    let duplicatedId = false;
                    if (driverIds.length !== 0) {
                        //checking is duplicated id
                        if (driverIds.includes(changeset.get('driverId'))) {
                            this.set('duplicateDriverIdError', 'Driver id already exists');
                            duplicatedId = true;
                        } else {
                            this.set('duplicateDriverIdError', false);
                            duplicatedId = false;
                        }
                    } else {
                        //array is empty so duplication is not possible
                        duplicatedId = false;
                    }

                    if (!duplicatedId) {
                        changes.forEach(function (change) {
                            currentDriver.set(change.key, change.value);
                        });
                        this.sendAction('saveDriver', currentDriver, randomId);
                    }
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let driver = this.get('driver');
                    driver.unloadRecord();
                }

                return changeset.rollback();
            }
        }
    });
});