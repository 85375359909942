define('smart-tracking-webapp/routes/protected/safety', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),

        activate() {

            let defaultItems = this.get('navigationService').get('defaultVisibleItems');
            let navigationItems = [{
                icon: 'steering-wheel',
                position: 3,
                label: 'Drivers',
                link: 'protected.safety.drivers'
            }, {
                icon: 'truck',
                position: 4,
                label: 'Trucks',
                link: 'protected.safety.trucks'
            }, {
                icon: 'list-bullet',
                position: 5,
                label: 'Equipment',
                link: 'protected.safety.equipment'
            }, {
                icon: 'address-card-o',
                position: 6,
                label: 'Accidents & inspections',
                link: 'protected.safety.accidents'
            }, {
                icon: 'truck',
                position: 7,
                label: 'All loads',
                link: 'protected.dispatch.loads-archive'
            }, {
                icon: 'cog',
                position: 8,
                label: 'Safety settings',
                link: 'protected.safety.settings.general'
            }];

            let completeNavigationItems = defaultItems.concat(navigationItems);

            //sorting by position
            completeNavigationItems.sort(function (a, b) {
                return a.position - b.position;
            });

            this.get('navigationService').set('visibleItems', completeNavigationItems);
        },
        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});