define('smart-tracking-webapp/controllers/protected/dispatch/brokers/broker/contact', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                history.back();
            },

            saveBrokerContact(brokerContact, randomId) {
                let broker = this.get('model').broker;
                brokerContact.set('broker', broker);

                if (randomId) {
                    brokerContact.set('id', null);
                }

                brokerContact.save().catch(function () {
                    brokerContact.unloadRecord();
                });

                this.send('closeModal');
            }
        }
    });
});