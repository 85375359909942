define('smart-tracking-webapp/components/side-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['side-bar-component'],

        didInsertElement() {

            Ember.$('.side-bar-menu').scroll(function () {
                let scroll = Ember.$('.side-bar-menu').scrollTop();

                if (scroll >= 5) {
                    Ember.$(".side-bar-component").addClass("js-user-avatarScroll");
                } else {
                    Ember.$(".side-bar-component").removeClass("js-user-avatarScroll");
                }
            }); //missing );
        },

        actions: {
            logout() {
                this.sendAction('logout');
            }
        }
    });
});