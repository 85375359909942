define('smart-tracking-webapp/components/company-form', ['exports', 'smart-tracking-webapp/validations/company'], function (exports, _company) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        company: null,
        isSameMailingAddress: Ember.computed('company', function () {
            let company = this.get('company');
            return company.get('street') === company.get('mailingStreet') && company.get('city') === company.get('city') && company.get('zip') === company.get('zip') && company.get('state') === company.get('state');
        }),
        classNames: ['form'],
        companyValidation: _company.default,

        actions: {

            toggleSameMailingAddress(changeset) {
                this.toggleProperty('isSameMailingAddress');
                return changeset.rollback();
            },

            saveCompany(changeset) {
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let isSameMailingAddress = this.get('isSameMailingAddress');
                let company = this.get('company');
                let randomId = this.get('randomId');

                if (isValid) {

                    changes.forEach(function (change) {
                        company.set(change.key, change.value);
                    });

                    if (isSameMailingAddress) {
                        company.set('mailingStreet', company.get('street'));
                        company.set('mailingCity', company.get('city'));
                        company.set('mailingZip', company.get('zip'));
                        company.set('mailingState', company.get('state'));
                    }

                    this.sendAction('saveCompany', company, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let company = this.get('company');
                    company.unloadRecord();
                }

                return changeset.rollback();
            },

            //avatar images
            changeAvatar() {
                this.set('avatarModal', true);
            },

            closeAvatarModal() {
                this.set('avatarModal', false);
                this.set('filePath', false);
            },

            saveNewAvatar(filePath) {
                let company = this.get('company');
                company.set('logo', filePath);
                company.save();
                this.send('closeAvatarModal');
            }
        }
    });
});