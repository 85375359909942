define('smart-tracking-webapp/models/accident', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        driver: _emberData.default.belongsTo('driver'),
        equipment: _emberData.default.belongsTo('equipment'),
        truck: _emberData.default.belongsTo('truck'),
        location: _emberData.default.attr('string'),
        date: _emberData.default.attr('string'),
        reportNumber: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        path: _emberData.default.attr('')
    });
});