define('smart-tracking-webapp/models/vehicle-maintenance', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        cost: _emberData.default.attr('number'),
        truck: _emberData.default.belongsTo('truck'),
        equipment: _emberData.default.belongsTo('equipment'),
        repairDay: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        path: _emberData.default.attr('string'),
        documents: _emberData.default.hasMany('vehicle-maintenance-document', { inverse: null }),
        dollarCost: Ember.computed('cost', function () {
            return '$ ' + (this.get('cost') || 0);
        }),
        formattedRepairDate: Ember.computed('repairDay', function () {
            return this.get('repairDay') ? (0, _moment.default)(this.get('repairDay')).format('MM/DD/YYYY') : '/';
        })
    });
});