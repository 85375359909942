define('smart-tracking-webapp/controllers/protected/accounting/accounts/drivers/driver/loads', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        allLoads: Ember.computed('model', 'model.[]', function () {
            let loads = this.get('model') || [];
            return {
                header: [{
                    title: 'Reference number',
                    propertyName: 'referenceNumber'
                }, {
                    title: 'Trip number',
                    propertyName: 'tripNumber'
                }, {
                    title: 'Truck id',
                    propertyName: 'truck.truckId'
                }, {
                    title: 'Date (From)',
                    propertyName: 'firstStop.date'
                }, {
                    title: 'Date (To)',
                    propertyName: 'lastStop.date'
                }, {
                    title: 'First driver',
                    propertyName: 'driver.fullName'
                }, {
                    title: 'Second driver',
                    propertyName: 'secondDriver.fullName'
                }, {
                    title: 'Loaded miles',
                    propertyName: 'miles'
                }, {
                    title: 'Empty miles',
                    propertyName: 'emptyMiles'
                }, {
                    title: 'Total miles',
                    propertyName: 'totalMiles'
                }, {
                    title: 'Status',
                    propertyName: 'status'
                }],
                data: loads
            };
        })
    });
});