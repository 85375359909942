define('smart-tracking-webapp/models/company-expense', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        amount: _emberData.default.attr('number'),
        description: _emberData.default.attr('string'),
        date: _emberData.default.attr(),
        formattedDate: Ember.computed('date', function () {
            return this.get('date') && (0, _moment.default)(this.get('date')).format('MM/DD/YYYY');
        }),
        category: _emberData.default.attr(''), // only front
        type: _emberData.default.attr(''), //only front
        typeValue: _emberData.default.attr(''), //only front
        numOfTimes: _emberData.default.attr('number') //only front
    });
});