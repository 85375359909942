define('smart-tracking-webapp/components/file-uploader', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        notifications: Ember.inject.service('toast'),
        fileList: [],

        actions: {

            /**
             * uploadFile uploads image on server. Uses response link for sending it to controller. Error handling included
             * @method uploadFile
             * @param file
             */
            uploadFile(file) {
                let notifications = this.get('notifications');
                let session = this.get('session').get('session');
                let accessToken = "Bearer " + session.content.authenticated.access_token;
                let url = _environment.default.APP.API_HOST + '/upload';
                let self = this;
                let type = this.get('type');
                let isMultiple = this.get('isMultiple');

                //different route for fuel excel import
                if (type === 'fuel') {
                    url = _environment.default.APP.API_HOST + '/import-fuel';
                }

                let options = {
                    url: url,
                    headers: {
                        "authorization": accessToken
                    },
                    data: {
                        type: type
                    },
                    accepts: ['application/json', 'text/javascript', 'text/plain', 'application/vnd.ms-excel']
                };

                let fileName = file.blob.name;
                file.upload(options).then(function (response) {
                    if (isMultiple) {
                        self.send('addFile', response.body.path);
                    } else {
                        if (type === 'avatar') {
                            self.set('filePath', 'http://' + response.body.avatar);
                        } else if (type === 'logo') {
                            self.set('filePath', _environment.default.APP.HOST + response.body.logo);
                        } else {

                            if (type === 'fuel') {
                                notifications.success('Successfully uploaded file');
                                self.sendAction('importFinished');
                            } else {
                                self.set('filePath', response.body.path);
                            }
                            self.set('fileName', fileName);
                        }
                    }
                }, function () {
                    notifications.error('Server error', 'Error');
                });
            },

            addFile(filePath) {
                let listOfFiles = this.get('fileList');
                let clonedArray = listOfFiles.slice(0);
                clonedArray.push(filePath);
                this.set('fileList', clonedArray);
                this.sendAction('addDocument', clonedArray);
            }
        }

    });
});