define('smart-tracking-webapp/routes/protected/companies/company', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),
        session: Ember.inject.service(),

        model: function (params) {

            const userId = this.get('session.data.authenticated.user_id');
            let routeCrud = this.get('routeCrud');
            let company = routeCrud.getRecord(params, 'company');

            return Ember.RSVP.hash({
                company: company.record,
                states: this.get('store').findAll('state'),
                users: this.get('store').findAll('user'),
                randomId: company.randomId,
                currentUser: this.get('store').findRecord('user', userId)
            });
        }
    });
});