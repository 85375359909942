define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/driver/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params, history) {
            let driverId = history.params['protected.accounting.accounts.drivers.driver'].id;
            return Ember.RSVP.hash({
                statements: this.get('store').query('statement', { driver: driverId, include: 'advances,loads' }),
                loads: this.get('store').query('load', { pagination: false, forDriver: driverId, newStatement: true, status: 'Completed', 'statement[exists]': false }),
                advances: this.get('store').query('advance', { pagination: false, driver: driverId, refunds: false, 'statement_exist': 0 }),
                refunds: this.get('store').query('advance', { pagination: false, driver: driverId, refunds: true, 'statement_exist': 0 }),
                driver: this.get('store').peekRecord('driver', driverId)
            });
        }

    });
});