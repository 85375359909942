define('smart-tracking-webapp/components/accident-display-form', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);
            let basicAjax = this.get('basicAjax');
            let accidentId = this.get('accident').get('id');
            let url = _environment.default.APP.API_HOST + "/get-accidents-document/" + accidentId;
            let self = this;

            basicAjax.request('GET', url).then(function (response) {
                if (response.url && response.url.length > 0) {
                    self.set('accidentDocuments', response.url);
                } else {
                    self.set('accidentDocuments', []);
                }
            });
        },

        actions: {
            closeModal() {
                this.sendAction('closeModal');
            }
        }
    });
});