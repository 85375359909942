define('smart-tracking-webapp/components/statement-form', ['exports', 'smart-tracking-webapp/validations/statement', 'smart-tracking-webapp/config/environment'], function (exports, _statement, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    basicAjax: Ember.inject.service('basicAjax'),
    store: Ember.inject.service(),
    classNames: ['form'],
    statementValidation: _statement.default,

    init() {
      this._super(...arguments);

      //selected values from server
      let refunds = [];
      let advances = [];
      this.get('statement.advances').forEach(function (advance) {
        if (advance.get('refunds')) {
          refunds.push(advance);
        } else {
          advances.push(advance);
        }
      });

      this.set('selectedLoads', this.get('statement.loads'));
      this.set('selectedAdvances', advances);
      this.set('selectedRefunds', refunds);
      this.set('selectedFuels', this.get('statement.fuels'));

      //filtered advances and refunds and sums
      let filteredRefunds = [];
      let filteredAdvances = [];

      let totalAdvances = 0;
      let totalRefunds = 0;
      let totalLoads = 0;

      this.get('allAdvances').forEach(advance => {
        filteredAdvances.push(advance);
        totalAdvances += advance.get('amount');
      });

      this.get('allRefunds').forEach(advance => {
        filteredRefunds.push(advance);
        totalRefunds += advance.get('amount');
      });

      this.get('statement.loads').forEach(load => {
        totalLoads += load.get('initialMoney');
      });

      this.set('filteredAdvances', filteredAdvances);
      this.set('filteredRefunds', filteredRefunds);
      // this.set('totalAmount.totalAdvances', totalAdvances);
      // this.set('totalAmount.totalRefunds', totalRefunds);
      // this.set('totalAmount.totalLoads', totalLoads);
    },

    // totalAmount: computed('selectedAdvances', 'selectedRefunds', 'selectedLoads', function () {
    //     let selectedAdvances = this.get('selectedAdvances');
    //     let selectedRefunds = this.get('selectedRefunds');
    //     let selectedLoads = this.get('selectedLoads');
    //
    //     let totalAdvances = 0;
    //     let totalRefunds = 0;
    //     let totalLoads = 0;
    //
    //     selectedRefunds.forEach(refund => {
    //         totalRefunds += refund.get('amount');
    //     });
    //
    //     selectedAdvances.forEach(advance => {
    //         totalAdvances += advance.get('amount');
    //     });
    //
    //     selectedLoads.forEach(load => {
    //         totalLoads += load.get('initialMoney');
    //     });
    //
    //     return {
    //         totalAdvances: totalAdvances,
    //         totalRefunds: totalRefunds,
    //         totalLoads: totalLoads
    //     }
    // }),
    //
    // checkAmount: computed('totalAmount', function () {
    //     let totalAmount = this.get('totalAmount');
    //     // let amount =
    //     return totalAmount.totalLoads - totalAmount.totalAdvances - totalAmount.totalRefunds;
    // }),

    actions: {
      saveStatement(changeset) {
        let changes = changeset.get('changes');
        changeset.validate();
        let isValid = changeset.get('isValid');
        let statement = this.get('statement');
        let randomId = this.get('randomId');
        let self = this;

        if (!this.get('selectedLoads')) {
          isValid = false;
          self.set('noLoadsError', 'You have to select at least one load');
        } else {
          isValid = true;
          self.set('noLoadsError', false);
        }

        if (isValid) {
          changes.forEach(function (change) {
            statement.set(change.key, change.value);
          });

          if (self.get('addFuel')) {
            statement.set('fuels', self.get('selectedFuels'));
          }
          //Add selected loads and advances
          statement.set('loads', self.get('selectedLoads'));
          statement.set('advances', self.get('selectedAdvances').concat(self.get('selectedRefunds')));

          this.sendAction('saveStatement', statement, randomId);
        }
      },

      rollback(changeset) {
        this.sendAction('closeModal');
        //unload record in controller
        if (this.get('randomId')) {
          let statement = this.get('statement');
          statement.unloadRecord();
        }
        return changeset.rollback();
      },

      livePreview() {

        const basicAjax = this.get('basicAjax');
        let url = _environment.default.APP.API_HOST + "/live-statement-preview?";

        if (this.get('driverId')) {
          url = url + "driver=" + this.get('driverId');
        } else {
          url = url + "truck=" + this.get('truckId');
        }

        let selectedRefunds = this.get('selectedRefunds');
        let selectedAdvances = this.get('selectedAdvances');
        let selectedLoads = this.get('selectedLoads');
        let selectedFuels = this.get('selectedFuels');

        function getIds(array) {
          return array.map(item => {
            return item.get('id');
          });
        }

        let queryParams = {
          loads: getIds(selectedLoads).toString(),
          advances: getIds(selectedAdvances).toString(),
          refunds: getIds(selectedRefunds).toString()
        };

        if (this.get('truckId')) {
          queryParams.fuels = getIds(selectedFuels).toString();
        }

        basicAjax.request('GET', url, queryParams, true).then(function (response) {
          let newWindow = window.open();
          newWindow.document.write(response);
          newWindow.document.title = 'Statement - live preview';
          newWindow.stop();
        });
      }
    }
  });
});