define('smart-tracking-webapp/controllers/protected/accounting/accounts/drivers/driver/rates', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            confirmRates(driver) {
                driver.save();
            }
        }
    });
});