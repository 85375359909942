define('smart-tracking-webapp/routes/protected/admin/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let user = routeCrud.getRecord(params, 'user');
            let companyId = this.get('session.data.authenticated.company_id');

            return Ember.RSVP.hash({
                user: user.record,
                companies: [this.get('store').findRecord('company', companyId)],
                randomId: user.randomId
            });
        }

    });
});