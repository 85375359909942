define('smart-tracking-webapp/controllers/protected/safety/settings/general', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        table: Ember.computed('model.driverStatuses', 'model.driverStatuses.[]', function () {

            let driverStatuses = this.get('model').driverStatuses;
            return {
                header: [{
                    title: 'Name',
                    propertyName: 'name'
                }],
                data: driverStatuses,

                properties: [{
                    name: 'name',
                    label: 'Driver status',
                    isRequired: true
                }]
            };
        })
    });
});