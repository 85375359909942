define('smart-tracking-webapp/components/facility-form', ['exports', 'smart-tracking-webapp/validations/facility'], function (exports, _facility) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        facilityValidation: _facility.default,

        facility: null,
        classNames: ['form'],

        actions: {

            saveFacility(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let facility = this.get('facility');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        Ember.set(facility, change.key, change.value);
                    });
                    this.sendAction('saveFacility', facility, randomId);
                }
            },

            rollback(changeset) {
                let randomId = this.get('randomId');
                this.sendAction('closeModal', randomId);
                return changeset.rollback();
            }
        }
    });
});