define('smart-tracking-webapp/models/broker-contact', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        secondaryEmail: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        extensionNumber: _emberData.default.attr('string'),
        faxNumber: _emberData.default.attr('string'),
        broker: _emberData.default.belongsTo('broker'),
        fullName: Ember.computed('firstName', 'lastName', 'username', function () {
            return this.get('firstName') || this.get('lastName') ? (this.get('firstName') || "") + " " + (this.get('lastName') || "") : 'unnamed';
        })
    });
});