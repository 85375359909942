define('smart-tracking-webapp/routes/protected/accounting/accounts/owners/truck', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params) {

            let truckTabs = [{
                icon: 'cog',
                label: 'Statements',
                link: 'protected.accounting.accounts.owners.truck.index'
            }, {
                icon: 'cogs',
                label: 'Pay rates',
                link: 'protected.accounting.accounts.owners.truck.rates'
            }, {
                icon: 'address-card-o',
                label: 'Advances and deductions',
                link: 'protected.accounting.accounts.owners.truck.advances'
            }, {
                icon: 'address-card-o',
                label: 'Refunds and bonuses',
                link: 'protected.accounting.accounts.owners.truck.refunds'
            }, {
                icon: 'address-card-o',
                label: 'Loads',
                link: 'protected.accounting.accounts.owners.truck.loads'
            }, {
                icon: 'address-card-o',
                label: 'Fuel',
                link: 'protected.accounting.accounts.owners.truck.fuel'
            }];

            return Ember.RSVP.hash({
                truck: this.get('store').findRecord('truck', params.id),
                truckTabs: truckTabs
            });
        }

    });
});