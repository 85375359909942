define('smart-tracking-webapp/components/regular-radio-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'input',
        type: 'radio',
        attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],

        value: null,
        checked: null,

        htmlChecked: Ember.computed('value', 'checked', function () {
            return this.get('value') === this.get('checked');
        }),

        change: function () {
            this.set('checked', this.get('value'));
            if (this.element) {
                this.element.checked = this.get('htmlChecked');
                this.sendAction('radioClicked', this.get('value'));
            }
        }
    });
});