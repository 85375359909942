define('smart-tracking-webapp/components/table-switcher', ['exports', 'smart-tracking-webapp/templates/components/table-switcher'], function (exports, _tableSwitcher) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableSwitcher.default,
        actions: {
            sendAction(actionName, recordId) {
                this.sendAction(actionName, recordId);
            }
        }
    });
});