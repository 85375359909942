define('smart-tracking-webapp/controllers/protected/users/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                this.transitionToRoute('protected.users');
            },

            saveUser(user, randomId) {

                if (randomId) {
                    user.set('id', null);
                }
                user.save().catch(function () {
                    user.unloadRecord();
                });

                this.send('closeModal');
            }
        }

    });
});