define('smart-tracking-webapp/components/server-models-table', ['exports', 'ember-models-table/components/models-table-server-paginated'], function (exports, _modelsTableServerPaginated) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const {
        $: { extend }
    } = Ember;

    exports.default = _modelsTableServerPaginated.default.extend({
        pageSizeValues: [5, 10, 30],
        pageSize: 30,
        showGlobalFilter: false,
        useFilteringByColumn: true,
        gotoFowardEnabled: true,

        metaItemsCountProperty: 'totalItems',

        pagesCount: Ember.computed('arrangedContent.[]', 'pageSize', function () {
            const pagesCount = Ember.get(this, 'arrangedContentLength') / parseInt(Ember.get(this, 'pageSize'), 10);
            return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
        }),

        //callback filtered content after request bubbles to the action you pass.
        dataObserver: Ember.observer('filteredContent', 'filteredContent.[]', function () {
            if (this.get('callbackAction')) {
                this.sendAction('callbackAction', this.get('filteredContent'));
            }
        }),

        /**
         * This function actually loads the data from the server.
         * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
         *
         * @returns {Promise}
         * @method _loadData
         * @private
         */
        _loadData: function () {
            let data = Ember.get(this, 'data');
            let pageSize = Ember.get(this, 'pageSize');

            if (!Ember.get(data, 'query')) {
                (true && Ember.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data'));

                return;
            }
            let query = extend({}, Ember.get(data, 'query'));

            Ember.set(query, 'itemsPerPage', pageSize);
            Ember.set(data, 'query', query);

            this._super(...arguments);
        },

        actions: {
            /**
             * @override
             * @method actions.sort
             * @param {ModelsTableColumn} column
             * @returns {undefined}
             */
            sort(column) {
                const sortMap = {
                    none: 'asc',
                    asc: 'desc',
                    desc: 'none'
                };
                let sortedBy = Ember.get(column, 'sortedBy') || Ember.get(column, 'propertyName');
                if (Ember.isNone(sortedBy)) {
                    return;
                }

                //TODO Refactor
                let data = Ember.get(column, 'data');

                //truck active
                if (data && data.modelName && data.modelName === 'truck') {
                    if (sortedBy === 'status') {
                        sortedBy = 'active';
                    }
                }
                //equipment active
                if (data && data.modelName && data.modelName === 'equipment') {
                    if (sortedBy === 'status') {
                        sortedBy = 'active';
                    }
                }

                //fuel
                if (data && data.modelName && data.modelName === 'fuel') {
                    if (sortedBy === 'formattedDate') {
                        sortedBy = 'created';
                    }
                }

                //company-expenses
                if (data && data.modelName && data.modelName === 'company-expense') {
                    if (sortedBy === 'formattedDate') {
                        sortedBy = 'date';
                    }
                }

                //sorting adjustments
                sortedBy === 'driver.fullName' && (sortedBy = 'driver.firstName');
                sortedBy === 'secondDriver.fullName' && (sortedBy = 'secondDriver.firstName');
                sortedBy === 'formattedRate' && (sortedBy = 'rate');
                sortedBy === 'dollarInitialMoney' && (sortedBy = 'initialMoney');
                sortedBy === 'fullName' && (sortedBy = 'firstName');

                let currentSorting = Ember.get(column, 'sorting');
                let newSorting = sortMap[currentSorting.toLowerCase()];
                let sortingArgs = [column, sortedBy, newSorting];
                this._singleColumnSorting(...sortingArgs);
                this.userInteractionObserver();
            }
        }

    });
});