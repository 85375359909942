define('smart-tracking-webapp/routes/protected/accounting/statistics/company-expenses', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        model: function () {

            let params = {
                start: (0, _moment.default)().startOf('month').format('YYYY-MM-DD'),
                end: (0, _moment.default)().endOf('month').format('YYYY-MM-DD')
            };

            const basicAjax = this.get('basicAjax');
            const url = _environment.default.APP.API_HOST + '/get-company-expenses';

            return Ember.RSVP.hash({
                selectedDate: {
                    start: params.start,
                    end: params.end
                },
                expenses: basicAjax.request('GET', url, params, true).then(function (response) {
                    return response;
                })

            });
        }
    });
});