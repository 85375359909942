define('smart-tracking-webapp/routes/protected/users', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),
        activate() {
            let self = this;
            this.get('navigationService').set('visibleItems', self.get('navigationService').get('defaultVisibleItems'));
        },
        model: function () {

            return Ember.RSVP.hash({
                users: this.get('store').query('user', { include: 'company' })
            });
        }
    });
});