define('smart-tracking-webapp/routes/protected/safety/accidents/accident', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),
        basicAjax: Ember.inject.service('basicAjax'),

        model: function (params, transition) {

            let routeCrud = this.get('routeCrud');
            let accident = routeCrud.getRecord(params, 'accident');
            let accidentId = transition.params['protected.safety.accidents.accident'].id;
            let url = _environment.default.APP.API_HOST + "/get-accidents-document/" + accidentId;
            let basicAjax = this.get('basicAjax');

            let accidentDocuments = [];
            if (params.id !== 'new') {
                accidentDocuments = basicAjax.request('GET', url).then(function (response) {
                    return response.url;
                });
            }

            return Ember.RSVP.hash({
                accident: accident.record,
                drivers: this.get('store').query('driver', { pagination: false }),
                equipment: this.get('store').query('equipment', { pagination: false }),
                trucks: this.get('store').query('truck', { pagination: false }),
                accidentTypes: Ember.$.getJSON("/json/accident-types.json").then(function (types) {
                    return types;
                }),
                accidentDocuments: accidentDocuments,
                randomId: accident.randomId
            });
        }
    });
});