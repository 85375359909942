define('smart-tracking-webapp/controllers/protected/safety/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        init() {
            this._super(...arguments);
            let safetyTabs = [{
                icon: 'cog',
                label: 'Driver statuses',
                link: 'protected.safety.settings.general'
            }, {
                icon: 'cogs',
                label: 'Owner groups',
                link: 'protected.safety.settings.groups'
            }, {
                icon: 'address-card-o',
                label: 'Driver documents',
                link: 'protected.safety.settings.driver-document-types'
            }, {
                icon: 'address-card-o',
                label: 'Vehicle documents',
                link: 'protected.safety.settings.vehicle-document-types'
            }, {
                icon: 'address-card-o',
                label: 'ELD Integrations',
                link: 'protected.safety.settings.elds'
            }];
            this.set('safetyTabs', safetyTabs);
        }

    });
});