define('smart-tracking-webapp/controllers/protected/accounting/statistics/dispatch-performance', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        dispatchPerformance: Ember.computed('model.dispatchPerformance', function () {
            let dispatchPerformance = this.get('model').dispatchPerformance || [];

            return {
                header: [{
                    title: 'Author (Dispatcher)',
                    propertyName: 'author'
                }, {
                    title: 'Total number of booked loads',
                    propertyName: 'bookedLoads'
                }, {
                    title: 'Total Gross ($) ',
                    propertyName: 'totalGross'
                }, {
                    title: 'Total empty miles',
                    propertyName: 'totalEmptyMiles'
                }, {
                    title: 'Total loaded miles',
                    propertyName: 'totalLoadedMiles'
                }, {
                    title: 'Grand total miles',
                    propertyName: 'grandTotalMiles'
                }, {
                    title: 'Total rate per grand total miles ($/mile)',
                    propertyName: 'grandRate'
                }, {
                    title: 'Total rate per total loaded miles ($/mile)',
                    propertyName: 'loadedRate'
                }, {
                    title: 'Deadhead percentage (%)',
                    propertyName: 'deadHead'
                }],
                data: dispatchPerformance
            };
        }),

        actions: {
            filter(params) {

                this.set('selectedDate', {
                    startTime: params.start,
                    endTime: params.start
                });

                let formattedParams = {
                    start: (0, _moment.default)(params.start).format('YYYY-MM-DD'),
                    end: (0, _moment.default)(params.end).format('YYYY-MM-DD')
                };

                let self = this;

                this.get('store').query('dispatch-performance', { startTime: formattedParams.start, endTime: formattedParams.end }).then(function (response) {
                    self.set('model.dispatchPerformance', response);
                });
            }
        }

    });
});