define('smart-tracking-webapp/components/advance-form', ['exports', 'smart-tracking-webapp/validations/advance'], function (exports, _advance) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        classNames: ['form'],
        advanceValidation: _advance.default,

        init() {
            this._super(...arguments);

            this.set('categories', [{
                value: 'one-time',
                label: 'One time'
            }, {
                value: 'repetitive',
                label: 'Repetitive'
            }, {
                value: 'scheduled',
                label: 'Scheduled'
            }]);

            this.set('types', [{
                value: 'daily',
                label: 'Daily'
            }, {
                value: 'weekly',
                label: 'Weekly'
            }, {
                value: 'monthly',
                label: 'Monthly'
            }]);

            this.set('weekDays', [{
                value: 1,
                label: 'Sunday'
            }, {
                value: 2,
                label: 'Monday'
            }, {
                value: 3,
                label: 'Tuesday'
            }, {
                value: 4,
                label: 'Wednesday'
            }, {
                value: 5,
                label: 'Thursday'
            }, {
                value: 6,
                label: 'Friday'
            }, {
                value: 7,
                label: 'Saturday'
            }]);

            let days = 28;
            let daysArray = Array.apply(null, { length: days }).map(function (value, index) {
                return {
                    label: index + 1,
                    value: index + 1
                };
            });

            this.set('daysArray', daysArray);
        },

        actions: {

            saveAdvance(changeset) {
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let advance = this.get('advance');
                let self = this;

                if (isValid) {
                    changes.forEach(function (change) {
                        advance.set(change.key, change.value);
                    });

                    let isAdditionalValid = true;

                    let formattedAdvance = {
                        category: advance.get('category').value, //One time, Repetitive,
                        amount: advance.get('amount'),
                        description: advance.get('description')
                    };

                    if (self.get('oneTime')) {
                        Ember.set(formattedAdvance, 'type', null);
                        Ember.set(formattedAdvance, 'typeValues', null);
                        Ember.set(formattedAdvance, 'numOfTimes', null);
                    }

                    if (self.get('repetitive')) {
                        Ember.set(formattedAdvance, 'type', null);
                        Ember.set(formattedAdvance, 'typeValues', null);
                        if (advance.get('numOfTimes')) {
                            Ember.set(formattedAdvance, 'numOfTimes', advance.get('numOfTimes'));
                            isAdditionalValid = true;
                        } else {
                            isAdditionalValid = false;
                        }
                    }

                    //TODO Refactor

                    if (self.get('scheduled')) {
                        if (self.get('selectedType') && advance.get('numOfTimes')) {
                            Ember.set(formattedAdvance, 'type', self.get('selectedType').value);
                            Ember.set(formattedAdvance, 'numOfTimes', advance.get('numOfTimes'));
                            isAdditionalValid = true;
                            if (self.get('selectedType').value !== 'daily') {

                                if (advance.get('typeValue')) {
                                    Ember.set(formattedAdvance, 'typeValue', advance.get('typeValue').value);
                                    isAdditionalValid = true;
                                } else {
                                    isAdditionalValid = false;
                                }
                            } else {
                                Ember.set(formattedAdvance, 'typeValue', null);
                            }
                        } else {
                            isAdditionalValid = false;
                        }
                    }

                    if (isAdditionalValid) {
                        this.sendAction('saveAdvance', formattedAdvance);
                        this.set('requiredErrorMessage', false);
                    } else {
                        this.set('requiredErrorMessage', 'All additional fields are required');
                    }
                }
            },
            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record in controller
                if (this.get('randomId')) {
                    let advance = this.get('advance');
                    advance.unloadRecord();
                }
                return changeset.rollback();
            },

            didSelectCategory(category) {
                let self = this;
                if (category.value === 'one-time') {
                    self.set('oneTime', true);
                    self.set('repetitive', false);
                    self.set('scheduled', false);
                } else if (category.value === 'repetitive') {
                    self.set('oneTime', false);
                    self.set('repetitive', true);
                    self.set('scheduled', false);
                } else {
                    self.set('oneTime', false);
                    self.set('repetitive', false);
                    self.set('scheduled', true);
                }
            },

            didSelectType(type) {
                let self = this;
                this.set('selectedType', type);
                if (type.value === 'monthly') {
                    self.set('typeValues', true);
                    self.set('typeValueOptions', self.get('daysArray'));
                } else if (type.value === 'weekly') {
                    self.set('typeValueOptions', self.get('weekDays'));
                    self.set('typeValues', true);
                } else {
                    self.set('typeValues', false);
                }
            }
        }

    });
});