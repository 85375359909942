define('smart-tracking-webapp/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        username: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        directPhoneNumber: _emberData.default.attr('string'),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        company: _emberData.default.belongsTo('company'),
        avatarImage: _emberData.default.attr('string'),
        isActive: _emberData.default.attr('boolean'),
        statusName: Ember.computed('isActive', function () {
            return this.get('isActive') ? "Active" : "Inactive";
        }),
        roles: _emberData.default.attr(''),
        fullName: Ember.computed('firstName', 'lastName', 'username', function () {
            return this.get('firstName') || this.get('lastName') ? (this.get('firstName') || "") + " " + (this.get('lastName') || "") : 'unnamed';
        })
    });
});