define('smart-tracking-webapp/controllers/protected/safety/accidents', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        accidentsTable: Ember.computed('model.accidents.[]', 'model.accidents', function () {

            let accidents = this.get('model').accidents;
            return {
                header: [{
                    title: 'Type',
                    propertyName: 'type',
                    routeName: "protected.safety.accidents.accident"
                }, {
                    title: 'Date',
                    propertyName: 'date'
                }, {
                    title: 'Driver',
                    propertyName: 'driver.fullName'
                }, {
                    title: 'Truck number',
                    propertyName: 'truck.truckId'
                }, {
                    title: 'Equipment number',
                    propertyName: 'equipment.equipmentId'
                }, {
                    title: 'Report number',
                    propertyName: 'reportNumber',
                    routeName: "protected.safety.accidents.accident"
                }, {
                    title: 'Location',
                    propertyName: 'location'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: accidents
            };
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            addAccident() {
                this.transitionToRoute('protected.safety.accidents.accident', 'new');
            },

            deleteAccident(id) {
                let accident = this.get('store').peekRecord('accident', id);
                this.set('currentAccident', accident);
                this.set('openModal', true);
            },
            confirmDeleteAccident() {
                let accident = this.get('currentAccident');
                accident.deleteRecord();
                accident.save();
                this.send('closeModal');
            }
        }
    });
});