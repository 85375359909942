define('smart-tracking-webapp/routes/protected', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        session: Ember.inject.service('session'),
        navigationService: Ember.inject.service('navigationService'),

        activate() {
            let self = this;
            this.get('navigationService').set('visibleItems', self.get('navigationService').get('defaultVisibleItems'));
        },

        model: function () {
            let userId = this.get('session.data.authenticated.user_id');

            return Ember.RSVP.hash({
                currentUser: this.get('store').findRecord('user', userId, { include: 'company' })
            });
        },

        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});