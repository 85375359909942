define('smart-tracking-webapp/services/current-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        load() {
            let userId = this.get('session.data.authenticated.user_id');
            if (!Ember.isEmpty(userId)) {
                return this.get('store').findRecord('user', userId, { include: "company" }).then(user => {
                    return {
                        user: user,
                        company: user.get('company')
                    };
                });
            }
        }

    });
});