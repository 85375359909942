define('smart-tracking-webapp/controllers/protected/accounting/statistics/company-expenses', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        init() {
            this._super(...arguments);
            let months = _moment.default.months();
            this.set('months', months);
        },

        expenses: Ember.computed('model.expenses', function () {
            let expenses = this.get('model').expenses.expenses || [];
            let total = this.get('model').expenses.totalExpenses || 0;

            let dates = expenses.map(record => {
                return record.date;
            });

            let values = expenses.map(record => {
                return record.value;
            });

            return {
                options: {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: 'Company expenses'
                    },
                    xAxis: {
                        categories: dates
                    },
                    yAxis: {
                        title: {
                            text: '$ (dollars)'
                        }
                    }
                },

                data: [{
                    name: 'Expenses',
                    data: values
                }],

                total: total
            };
        }),

        actions: {
            filter(params) {

                this.set('selectedDate', {
                    startTime: params.start,
                    endTime: params.start
                });

                let formattedParams = {
                    start: (0, _moment.default)(params.start).format('YYYY-MM-DD'),
                    end: (0, _moment.default)(params.end).format('YYYY-MM-DD')
                };

                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/get-company-expenses';

                let self = this;

                basicAjax.request('GET', url, formattedParams, true).then(function (response) {
                    self.set('model.expenses', response);
                });
            }
        }

    });
});