define('smart-tracking-webapp/routes/protected/dispatch/loads/index', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        basicAjax: Ember.inject.service('basicAjax'),

        model: function () {
            let basicAjax = this.get('basicAjax');
            let url = _environment.default.APP.API_HOST + '/loads-board';
            let filter = {
                start: (0, _moment.default)().startOf('week').format('MM/DD/YYYY'),
                end: (0, _moment.default)().endOf('week').format('MM/DD/YYYY'),
                trucks: 'all',
                loadStatus: "ALL"
            };

            return Ember.RSVP.hash({
                loads: basicAjax.request('GET', url, filter, true).then(function (response) {
                    return response;
                }),
                drivers: this.get('store').query('driver', { 'status.name': "active driver", pagination: false }),
                equipment: this.get('store').query('equipment', { active: true, pagination: false }),
                trucks: this.get('store').query('truck', { pagination: false }),
                brokers: this.get('store').query('broker', { include: 'contacts', pagination: false }),
                loadStatuses: Ember.$.getJSON("/json/load-statuses.json").then(function (statuses) {
                    return statuses;
                })
            });
        }
    });
});