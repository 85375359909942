define('smart-tracking-webapp/validations/company', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true),
        email: [(0, _validators.validatePresence)({ presence: true }), (0, _validators.validateFormat)({ type: 'email' })],
        street: (0, _validators.validatePresence)(true),
        city: (0, _validators.validatePresence)(true),
        zip: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })],
        state: (0, _validators.validatePresence)(true),
        usdot: (0, _validators.validatePresence)(true),
        mc: (0, _validators.validatePresence)(true)
    };
});