define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/driver/loads', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function (params, history) {
            let driverId = history.params['protected.accounting.accounts.drivers.driver'].id;
            return this.get('store').query('load', { anyDriver: driverId, include: "events,stops,truck" });
        }
    });
});