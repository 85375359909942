define('smart-tracking-webapp/controllers/protected/safety/drivers/driver/drug-test', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        actions: {
            closeModal() {
                history.back();
            },

            saveDrugTest(drugTest, randomId) {
                let driver = this.get('model').driver;
                drugTest.set('driver', driver);

                let self = this;
                let driverId = driver.get('id');

                if (randomId) {
                    drugTest.set('id', null);
                    drugTest.save().then(function () {
                        self.get('store').findRecord('driver', driverId, { include: 'documents,drugTests,accidents' });
                    });
                } else {
                    drugTest.save().then(function () {
                        self.get('store').findRecord('driver', driverId, { include: 'documents,drugTests,accidents' });
                    });
                }
                this.send('closeModal');
            }
        }
    });
});