define('smart-tracking-webapp/components/load-form', ['exports', 'smart-tracking-webapp/validations/load-dispatch', 'smart-tracking-webapp/validations/load-tracking', 'smart-tracking-webapp/validations/add-load', 'smart-tracking-webapp/config/environment'], function (exports, _loadDispatch, _loadTracking, _addLoad, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['form'],
        notifications: Ember.inject.service('toast'),
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        basicAjax: Ember.inject.service('basicAjax'),
        dispatchLoadValidation: _loadDispatch.default,
        trackingLoadValidation: _loadTracking.default,
        addLoadValidation: _addLoad.default,

        broker: null,

        customValidation: Ember.computed('dispatchLoadValidation', 'trackingLoadValidation', 'addLoadValidation', 'role', function () {
            let currentUserRoles = this.roles;

            if (currentUserRoles.includes('ROLE_DISPATCH')) {
                return _loadDispatch.default;
            } else if (currentUserRoles.includes('ROLE_TRACKING')) {
                return _loadTracking.default;
            } else {
                return _addLoad.default;
            }
        }),

        totalCharges: Ember.computed('load', 'load.initialMoney', 'extraCharges', function () {
            let initialMoney = +(this.get('load.initialMoney') || 0);
            let extraCharges = +(this.extraCharges || 0);
            return (extraCharges + initialMoney).toFixed(2);
        }),

        totalMiles: Ember.computed('load', 'load.miles', 'load.emptyMiles', function () {
            let miles = this.get('load.miles') || 0;
            let emptyMiles = this.get('load.emptyMiles') || 0;
            return parseFloat(miles) + parseFloat(emptyMiles);
        }),

        mileRate: Ember.computed('load', 'load.initialMoney', 'miles', function () {
            let miles = parseFloat(this.get('load.miles') || 0);
            let initialMoney = parseFloat(this.get('load.initialMoney'));
            return miles && initialMoney ? (initialMoney / miles).toFixed(2) : '';
        }),

        totalRate: Ember.computed('load', 'load.initialMoney', 'totalMiles', function () {
            let totalMiles = parseFloat(this.get('totalMiles') || 0);
            let initialMoney = parseFloat(this.get('load.initialMoney'));
            return totalMiles && initialMoney ? (initialMoney / totalMiles).toFixed(2) : '';
        }),

        actions: {

            saveLoad(changeset, isLoadInfo) {
                const notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let load = this.get('load');
                let randomId = this.get('randomId');

                //computed values can't be added through changeset
                let miles = this.get('load.miles');
                let emptyMiles = this.get('load.emptyMiles');

                let initialMoney = this.get('load.initialMoney');
                let mileRate = this.get('mileRate');
                let totalRate = this.get('totalRate');

                let commodity = changeset.get('commodity');
                let weight = changeset.get('weight');

                let selectedBroker = this.get('selectedBroker') ? this.get('selectedBroker') : load.get('broker');

                //checking two stops
                let loadStops = this.get('loadStops');
                let counter = 0;
                loadStops.forEach(() => {
                    counter++;
                });

                if (isValid) {
                    changes.forEach(function (change) {
                        load.set(change.key, change.value);
                    });
                    if (isLoadInfo) {
                        if (!(miles && emptyMiles && commodity && weight && initialMoney && !(counter < 2))) {
                            notifications.error('Please add Load Stops and fill out Load specifications');
                            return false;
                        }
                        load.set('driverVisible', true);
                    }
                    load.set('rate', mileRate);
                    load.set('totalRate', totalRate);
                    load.set('broker', selectedBroker);
                    this.sendAction('saveLoad', load, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let load = this.get('load');
                    load.unloadRecord();
                }
                return changeset.rollback();
            },

            //broker contact
            didSelectBroker(broker) {
                this.set('selectedBroker', broker);
                let self = this;
                let allBrokerContacts = [];
                if (broker) {
                    let selectedBrokerId = broker.get('id');
                    self.get('store').findRecord('broker', selectedBrokerId, { include: 'broker-contact' }).then(function (broker) {
                        allBrokerContacts = broker.get('contacts');
                        self.set('allBrokerContacts', allBrokerContacts);
                    });
                }
            },

            sendLoadInfoEmail(id) {
                const notifications = this.get('notifications');
                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/send-load-info/' + id;

                basicAjax.request('POST', url).then(function () {
                    notifications.success('Load info successfully sent to driver', 'Success');
                    //update only for UI at click
                    let loadInfo = this.get('load.loadInfo');
                    loadInfo++;
                    this.set('load.loadInfo', loadInfo);
                });
            },

            getInfoEmailDetails(id) {
                const notifications = this.get('notifications');
                const basicAjax = this.get('basicAjax');
                const url = _environment.default.APP.API_HOST + '/load-info-logs?load=' + id;
                let self = this;
                basicAjax.request('GET', url).then(function (response) {
                    self.set('currentLog', response.data);
                    self.set('InfoEmailDetails', true);
                }, function () {
                    notifications.error('Server error', 'Error');
                });
            },

            closeModal() {
                this.set('InfoEmailDetails', false);
            }
        }
    });
});