define('smart-tracking-webapp/controllers/protected/safety/settings/groups', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        groupsTable: Ember.computed('model.ownerGroups', 'model.ownerGroups.[]', function () {

            let ownerGroups = this.get('model').ownerGroups;
            return {
                header: [{
                    title: 'Name',
                    propertyName: 'name'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: ownerGroups
            };
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            addGroup() {
                this.transitionToRoute('protected.safety.settings.groups.group', 'new');
            },

            editGroup(id) {
                this.transitionToRoute('protected.safety.settings.groups.group', id);
            },

            deleteGroup(id) {
                let group = this.get('store').peekRecord('owner-group', id);
                this.set('currentGroup', group);
                this.set('openModal', true);
            },
            confirmDeleteGroup() {
                let group = this.get('currentGroup');
                group.deleteRecord();
                group.save();
                this.send('closeModal');
            }
        }
    });
});