define('smart-tracking-webapp/router', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('login');
        this.route('forgot-password');
        this.route('sign-up');
        this.route('recovery');
        this.route('protected', { path: '' }, function () {
            this.route('safety', { path: 'safety/' }, function () {
                this.route('drivers', function () {
                    this.route('driver', { path: 'driver/:id' }, function () {
                        this.route('document', { path: 'document/:document_id' });
                        this.route('drug-test', { path: 'drug-test/:test_id' });
                    });
                });
                this.route('trucks', function () {
                    this.route('truck', { path: 'truck/:id' }, function () {
                        this.route('document', { path: 'document/:document_id' });
                        this.route('maintenance', { path: 'maintenance/:maintenance_id' });
                    });
                });
                this.route('equipment', function () {
                    this.route('equipment', { path: '/:id' }, function () {
                        this.route('document', { path: 'document/:document_id' });
                        this.route('maintenance', { path: 'maintenance/:maintenance_id' });
                    });
                });
                this.route('settings', function () {
                    this.route('general');
                    this.route('groups', function () {
                        this.route('group', { path: 'group/:id' });
                    });
                    this.route('elds', function () {
                        this.route('eld', { path: 'eld/:id' });
                    });
                    this.route('driver-document-types');
                    this.route('vehicle-document-types');
                });
                this.route('accidents', function () {
                    this.route('accident', { path: 'accident/:id' });
                });
            });
            this.route('dispatch', { path: 'dispatch/' }, function () {
                this.route('brokers', function () {
                    this.route('broker', { path: 'broker/:id' }, function () {
                        this.route('contact', { path: 'contact/:contact_id' });
                        this.route('document', { path: 'contact/:document_id' });
                    });
                });
                this.route('loads', function () {
                    this.route('load', { path: 'load/:id' }, function () {
                        this.route('stop', { path: 'stop/:stop_id' });
                        this.route('event', { path: 'event/:event_id' });
                        this.route('charge', { path: 'charge/:charge_id' });
                        this.route('document', { path: 'document/:document_id' });
                    });
                });
                this.route('facilities', function () {
                    this.route('facility', { path: 'facility/:id' }, function () {});
                });
                this.route('loads-archive');
            });
            this.route('not-found', { path: '/*path' });
            this.route('tracking');
            this.route('accounting', function () {
                this.route('loads');
                this.route('accounts', function () {
                    this.route('drivers', function () {
                        this.route('driver', { path: '/:id' }, function () {
                            this.route('rates');
                            this.route('advances');
                            this.route('refunds');
                            this.route('loads');
                        });
                    });
                    this.route('owners', function () {
                        this.route('truck', { path: '/:id' }, function () {
                            this.route('advances');
                            this.route('loads');
                            this.route('rates');
                            this.route('refunds');
                            this.route('fuel');
                        });
                    });
                });
                this.route('fuel');
                this.route('statistics', function () {
                    this.route('general');
                    this.route('fuel');
                    this.route('company-expenses');
                    this.route('company-profit');
                    this.route('dispatch-performance');
                });
                this.route('company');
            });
            this.route('user-profile');
            this.route('admin', function () {
                this.route('user', { path: '/:id' });
                this.route('document', { path: '/:document_id' });
            });
            this.route('companies', function () {
                this.route('company', { path: '/:id' });
            });
            this.route('users', function () {
                this.route('user', { path: '/:id' });
            });
            this.route('copy-brokers');
        });
        this.route('get-load-status');
    });

    exports.default = Router;
});