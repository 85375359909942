define('smart-tracking-webapp/components/login-form', ['exports', 'smart-tracking-webapp/validations/login'], function (exports, _login) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['login-form-component'],
        session: Ember.inject.service('session'),

        loginValidation: _login.default,
        user: {
            username: null,
            password: null
        },

        selectedCountry: null,

        actions: {

            authenticateWithToken(changeset) {
                changeset.validate();
                let isValid = changeset.get('isValid');
                if (isValid) {
                    let identification = changeset.get('username');
                    let password = changeset.get('password');
                    this.get('session').authenticate('authenticator:oauth2', identification, password).catch(error => {
                        this.set('errorMessage', error || error.message);
                    });
                }
            }
        }
    });
});