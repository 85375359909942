define('smart-tracking-webapp/validations/group', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true),
        fein: (0, _validators.validatePresence)(true),
        phone: (0, _validators.validatePresence)(true),
        representativeName: (0, _validators.validatePresence)(true),
        owner: (0, _validators.validatePresence)(true),
        zip: (0, _validators.validatePresence)(true),
        state: (0, _validators.validatePresence)(true),
        city: (0, _validators.validatePresence)(true),
        street: (0, _validators.validatePresence)(true),
        email: [(0, _validators.validatePresence)({ presence: true }), (0, _validators.validateFormat)({ type: 'email' })]
    };
});