define('smart-tracking-webapp/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'smart-tracking-webapp/config/environment', 'fetch'], function (exports, _oauth2PasswordGrant, _environment, _fetch) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _oauth2PasswordGrant.default.extend({
        serverTokenEndpoint: _environment.default.APP.API_HOST + '/login_check',

        makeRequest(url, data, headers = {}) {

            //custom headers and body
            headers['Content-Type'] = 'application/json';
            // headers['dataType'] = 'json';
            let body = JSON.stringify(data);

            const options = {
                body,
                headers,
                method: 'POST'
            };

            const clientIdHeader = this.get('_clientIdHeader');
            if (!Ember.isEmpty(clientIdHeader)) {
                Ember.merge(options.headers, clientIdHeader);
            }

            return new Ember.RSVP.Promise((resolve, reject) => {
                (0, _fetch.default)(url, options).then(response => {
                    response.text().then(text => {
                        try {
                            let json = JSON.parse(text);
                            if (!response.ok) {
                                response.responseJSON = json;
                                reject(response);
                            } else {
                                resolve(json);
                            }
                        } catch (SyntaxError) {
                            response.responseText = text;
                            reject(response);
                        }
                    });
                }).catch(reject);
            });
        },

        _refreshAccessToken(expiresIn, refreshToken) {
            const notifications = this.get('notifications');
            const userId = JSON.parse(localStorage['ember_simple_auth-session']).authenticated.user_id;
            const data = { 'grant_type': 'refresh_token', 'refresh_token': refreshToken };
            const serverTokenEndpoint = _environment.default.APP.API_HOST + '/token/refresh';

            return new Ember.RSVP.Promise((resolve, reject) => {
                this.makeRequest(serverTokenEndpoint, data).then(response => {
                    run(() => {
                        expiresIn = response['expires_in'] || expiresIn;
                        refreshToken = response['refresh_token'] || refreshToken;
                        const expiresAt = this._absolutizeExpirationTime(expiresIn);
                        const data = assign(response, { 'expires_in': expiresIn, 'expires_at': expiresAt, 'refresh_token': refreshToken, 'user_id': userId });
                        this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
                        this.trigger('sessionDataUpdated', data);
                        resolve(data);
                    });
                }, () => {
                    notifications.error('Refresh token error');
                    reject();
                });
            });
        }
    });
});