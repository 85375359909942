define('smart-tracking-webapp/routes/protected/accounting/loads', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            return Ember.RSVP.hash({
                loads: this.get('store').query('load', {})
            });
        }
    });
});