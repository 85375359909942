define('smart-tracking-webapp/models/facility', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        zip: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        street: _emberData.default.attr('string'),
        rate: _emberData.default.attr(''),
        timeSpent: _emberData.default.attr('string'),
        formattedRate: Ember.computed('rate', function () {
            return this.get('rate') ? this.get('rate').toFixed(2) : 0;
        })
    });
});