define('smart-tracking-webapp/controllers/sign-up', ['exports', 'smart-tracking-webapp/validations/signup', 'smart-tracking-webapp/config/environment'], function (exports, _signup, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),
        Signup: _signup.default,

        init() {
            this._super(...arguments);
            this.set('userWithCompany', {});
        },

        actions: {
            signUp(changeset) {
                const notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let self = this;
                if (isValid) {

                    let user = {};
                    let company = {};

                    changes.forEach(function (change) {
                        if (change.key === 'username' || change.key === 'password' || change.key === 'firstName' || change.key === 'lastName' || change.key === 'email') {
                            Ember.set(user, change.key, change.value);
                        } else {

                            if (change.key === 'companyName') {
                                Ember.set(company, 'name', change.value);
                            } else if (change.key === 'companyEmail') {
                                Ember.set(company, 'email', change.value);
                            } else {
                                Ember.set(company, change.key, change.value);
                            }
                        }
                    });

                    let jsonData = {
                        user: user,
                        company: company
                    };
                    let basicAjax = this.get('basicAjax');
                    let url = _environment.default.APP.API_HOST + "/register";

                    basicAjax.request('POST', url, jsonData).then(function (response) {
                        if (response) {
                            notifications.success('Your request has been successfully completed.We will contact you shortly.');
                            self.transitionToRoute('login');
                        } else {
                            notifications.error('Server error', 'Error');
                        }
                    });
                }
            }
        }
    });
});