define('smart-tracking-webapp/controllers/protected/dispatch/loads/load/charge', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                history.back();
            },

            saveCharge(charge, randomId) {
                let load = this.get('model').load;
                charge.set('load', load);

                let loadId = load.get('id');
                let self = this;

                if (randomId) {
                    charge.set('id', null);
                }

                charge.save().then(function () {
                    self.get('store').findRecord('load', loadId, { include: 'stops,events,driver,truck,charges' });
                }).catch(function () {
                    charge.unloadRecord();
                });
                this.send('closeModal');
            }
        }
    });
});