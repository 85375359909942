define('smart-tracking-webapp/controllers/protected/companies/company', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notifications: Ember.inject.service('toast'),

        isSuperAdmin: Ember.computed('model.currentUser', function () {

            let currentUser = this.get('model').currentUser;
            let userRoles = currentUser.get('roles');

            return userRoles.includes('ROLE_SUPERADMIN');
        }),

        actions: {
            closeModal() {
                this.transitionToRoute('protected.companies');
            },

            saveCompany(company, randomId) {

                if (randomId) {
                    company.set('id', null);
                }
                company.save().then(() => {
                    this.get('notifications').success('Your request has been successfully completed ', 'Success');
                }).catch(function () {
                    company.unloadRecord();
                });

                this.send('closeModal');
            }
        }

    });
});