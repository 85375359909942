define('smart-tracking-webapp/controllers/protected/safety/drivers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        driversTable: Ember.computed('model.drivers.[]', 'model.drivers', function () {

            let drivers = this.get('model').drivers;
            return {
                header: [{
                    title: 'Full name',
                    propertyName: 'fullName',
                    routeName: "protected.safety.drivers.driver"
                }, { title: 'Driver ID', propertyName: 'driverId' }, { title: 'Telephone', propertyName: 'telephone' }, { title: 'Email', propertyName: 'email' }, { title: 'CDL', propertyName: 'cdl' }, { title: 'CDL state', propertyName: 'cdlState' }, { title: 'Status', propertyName: 'status.name' }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: drivers
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete driver' : 'Add driver';
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },
            cancelAddingDriver() {
                let currentDriver = this.get('currentDriver');
                currentDriver.unloadRecord();
                this.send('closeModal');
            },
            deleteDriver(id) {
                let driver = this.get('store').peekRecord('driver', id);
                this.set('currentDriver', driver);
                this.set('openModal', true);
                this.set('isDelete', true);
            },
            confirmDeleteDriver() {
                let driver = this.get('currentDriver');
                driver.deleteRecord();
                driver.save();
                this.send('closeModal');
            },
            addDriver() {
                this.set('openModal', true);
                let driver = this.get('store').createRecord('driver');
                this.set('currentDriver', driver);
                this.set('isAdd', true);
                this.set('isDelete', false);
            },
            confirmAddDriver(driver) {
                let self = this;
                let driverStatuses = this.get('model').driverStatuses;
                //set init status to applicant
                let activeStatus = {};
                driverStatuses.forEach(status => {
                    if (status.get('name') === 'applicant') {
                        activeStatus = status;
                    }
                });
                driver.set('status', activeStatus);
                driver.save().then(function () {
                    self.get('store').query('driver', { include: 'status' }).then(function (drivers) {
                        self.set('model.drivers', drivers);
                    });
                }).catch(function () {
                    driver.unloadRecord();
                });
                this.send('closeModal');
            }
        }
    });
});