define('smart-tracking-webapp/models/load', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        author: _emberData.default.belongsTo('user', { inverse: null }),
        driver: _emberData.default.belongsTo('driver', { inverse: null }),
        secondDriver: _emberData.default.belongsTo('driver', { inverse: null }),
        equipment: _emberData.default.belongsTo('equipment', { inverse: null }),
        truck: _emberData.default.belongsTo('truck', { inverse: null }),
        broker: _emberData.default.belongsTo('broker', { inverse: null }),
        brokerContact: _emberData.default.belongsTo('broker-contact', { inverse: null }),
        tripNumber: _emberData.default.attr('string'),
        referenceNumber: _emberData.default.attr('string'),
        stops: _emberData.default.hasMany('stop', { inverse: null }),
        events: _emberData.default.hasMany('event', { inverse: null }),
        rate: _emberData.default.attr('string'),
        totalRate: _emberData.default.attr('number'),
        miles: _emberData.default.attr('number'),
        emptyMiles: _emberData.default.attr('number'),
        initialMoney: _emberData.default.attr('number'),
        dispatch: _emberData.default.belongsTo('user'),
        commodity: _emberData.default.attr('string'),
        weight: _emberData.default.attr('number'),
        loadInfo: _emberData.default.attr('number'),
        status: _emberData.default.attr('string'),
        charges: _emberData.default.hasMany('charge', { inverse: null }), //additional charges
        documents: _emberData.default.hasMany('load-document'),
        invoice: _emberData.default.attr('string'), //generate invoice
        invoiceReference: _emberData.default.attr('string'), //if paymentStatus is true
        driverVisible: _emberData.default.attr('boolean'),
        isDisabled: Ember.computed('status', function () {

            //TODO Refactor
            let isDisabled = false;

            if (this.get('status') !== 'Planned') {
                isDisabled = true;
            }

            if (!this.get('status')) {
                isDisabled = false;
            }
            return isDisabled;
        }),

        isDisabledInvoice: Ember.computed('status', function () {
            return this.get('status') !== 'Completed';
        }),
        isDisabledInvoiceReference: Ember.computed('status', 'invoice', function () {
            return this.get('status') !== 'Completed' || !this.get('invoice');
        }),
        totalMiles: Ember.computed('emptyMiles', 'miles', function () {
            return (this.get('emptyMiles') || 0) + (this.get('miles') || 0);
        }),
        firstStop: _emberData.default.attr(''),
        lastStop: _emberData.default.attr(''),
        formattedFirstStop: Ember.computed('firstStop', function () {
            let firstStop = this.get('firstStop');
            return Array.isArray(firstStop) ? '/' : firstStop.city + ', ' + firstStop.state + ' (' + firstStop.date + ')';
        }),
        formattedLastStop: Ember.computed('firstStop', function () {
            let lastStop = this.get('lastStop');
            return Array.isArray(lastStop) ? '/' : lastStop.city + ', ' + lastStop.state + ' (' + lastStop.date + ')';
        }),
        dollarInitialMoney: Ember.computed('initialMoney', function () {
            return '$ ' + (this.get('initialMoney') || 0);
        }),
        statusCheck: _emberData.default.attr('boolean')
    });
});