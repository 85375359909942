define('smart-tracking-webapp/components/broker-contact-form', ['exports', 'smart-tracking-webapp/validations/broker-contact'], function (exports, _brokerContact) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        classNames: ['form'],
        brokerContact: null,
        brokerContactValidation: _brokerContact.default,

        actions: {
            saveBrokerContact(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let brokerContact = this.get('brokerContact');
                let randomId = this.get('randomId');

                if (isValid) {

                    changes.forEach(function (change) {
                        brokerContact.set(change.key, change.value);
                    });

                    this.sendAction('saveBrokerContact', brokerContact, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let brokerContact = this.get('brokerContact');
                    brokerContact.unloadRecord();
                }

                return changeset.rollback();
            }
        }
    });
});