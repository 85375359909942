define('smart-tracking-webapp/validations/load-tracking', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        referenceNumber: (0, _validators.validatePresence)(true),
        broker: (0, _validators.validatePresence)(true),
        brokerContact: (0, _validators.validatePresence)(true),
        status: (0, _validators.validatePresence)(true),
        miles: (0, _validators.validatePresence)(true),
        emptyMiles: (0, _validators.validatePresence)(true),
        initialMoney: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })],
        commodity: (0, _validators.validatePresence)(true),
        weight: (0, _validators.validatePresence)(true)
    };
});