define('smart-tracking-webapp/models/statement', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        loads: _emberData.default.hasMany('load', { inverse: null }),
        driver: _emberData.default.belongsTo('driver', { inverse: null }),
        truck: _emberData.default.belongsTo('truck', { inverse: null }),
        created: _emberData.default.attr(),
        advances: _emberData.default.hasMany('advance', { inverse: null }),
        invoiceReference: _emberData.default.attr('string'), //if paymentStatus is true
        formattedDate: Ember.computed('created', function () {
            return (0, _moment.default)(this.get('created')).format('MM/DD/YYYY');
        }),
        fuels: _emberData.default.hasMany('fuel', { inverse: null }),
        statementId: _emberData.default.attr('string'),
        valueOfStatement: _emberData.default.attr(''),
        finalPrice: _emberData.default.attr(''),
        paymentStatus: Ember.computed('invoiceReference', function () {
            return this.get('invoiceReference') ? 'Paid' : 'Not paid';
        }),
        isDisabled: Ember.computed('invoiceReference', function () {
            return this.get('invoiceReference');
        }),
        loadsSaver: _emberData.default.attr('')
    });
});