define('smart-tracking-webapp/services/navigation-service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        //default menu items (change depending on role)
        defaultVisibleItems: Ember.computed('session', 'session.data.authenticated.user_id', function () {

            let userId = this.get('session.data.authenticated.user_id');
            let user = this.get('store').peekRecord('user', userId);
            let userRoles = user.get('roles');

            let homeRoutes = [{
                icon: 'home',
                position: 1,
                label: 'Home',
                link: 'protected.index'
            }];

            let roleAdminRoutes = [{
                icon: 'gauge',
                position: 11,
                label: 'Company admin',
                link: 'protected.admin'
            }];

            let superAdminRoutes = [{
                icon: 'users',
                position: 21,
                label: 'Users',
                link: 'protected.users'
            }, {
                icon: 'building',
                position: 22,
                label: 'Companies',
                link: 'protected.companies'
            }, {
                icon: 'folder-open',
                position: 23,
                label: 'Copy brokers',
                link: 'protected.copy-brokers'
            }];

            let defaultVisibleItems = homeRoutes;

            if (userRoles.includes('ROLE_ADMIN')) {
                defaultVisibleItems = homeRoutes.concat(roleAdminRoutes);
            }

            if (userRoles.includes('ROLE_SUPERADMIN')) {
                defaultVisibleItems = homeRoutes.concat(superAdminRoutes);
            }

            return defaultVisibleItems;
        }),
        visibleItems: null
    });
});