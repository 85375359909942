define('smart-tracking-webapp/controllers/protected/accounting/fuel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        fuelTable: Ember.computed('model', 'model.[]', function () {

            let fuel = this.get('model');
            return {
                header: [{ title: 'Truck', propertyName: 'truck.truckId' }, { title: 'Transaction date and time', propertyName: 'formattedDate' }, { title: 'City', propertyName: 'city' }, { title: 'State', propertyName: 'state' }, { title: 'code', propertyName: 'code' }, { title: 'Quantity', propertyName: 'quantity' }, { title: 'Amount ($)', propertyName: 'amount' }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: fuel
            };
        }),

        actions: {
            uploadFuel() {
                this.set('uploadModal', true);
            },

            importFinished() {
                //TODO Check updating table
                this.set('uploadModal', false);
            },

            closeUploadModal() {
                this.set('uploadModal', false);
            },

            deleteFuel(id) {
                let fuel = this.get('store').peekRecord('fuel', id);
                this.set('currentFuel', fuel);
                this.set('deleteModal', true);
            },

            closeDeleteModal() {
                this.set('deleteModal', false);
            },

            confirmDeleteFuel() {
                let fuel = this.get('currentFuel');
                fuel.deleteRecord();
                fuel.save();
                this.set('deleteModal', false);
            }
        }
    });
});