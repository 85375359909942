define('smart-tracking-webapp/routes/protected/accounting/statistics/company-profit', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        model: function () {

            let params = {
                start: (0, _moment.default)().startOf('month').format('YYYY-MM-DD'),
                end: (0, _moment.default)().endOf('month').format('YYYY-MM-DD')
            };

            let yearParams = {
                start: (0, _moment.default)().startOf('year').format('YYYY-MM-DD'),
                end: (0, _moment.default)().endOf('year').format('YYYY-MM-DD')
            };

            const basicAjax = this.get('basicAjax');
            const totalUrl = _environment.default.APP.API_HOST + '/get-company-profit';
            const profitUrl = _environment.default.APP.API_HOST + '/get-company-profit-per-period';
            const ownedTrucksUrl = _environment.default.APP.API_HOST + '/get-company-owned-trucks-period-chart';
            const allTrucksUrl = _environment.default.APP.API_HOST + '/get-company-all-trucks-period-chart';

            return Ember.RSVP.hash({
                selectedDate: {
                    start: params.start,
                    end: params.end
                },
                total: basicAjax.request('GET', totalUrl, params, true).then(function (response) {
                    return response;
                }),
                profit: basicAjax.request('GET', profitUrl, yearParams, true).then(function (response) {
                    return response;
                }),
                ownedTrucks: basicAjax.request('GET', ownedTrucksUrl, yearParams, true).then(function (response) {
                    return response;
                }),
                allTrucks: basicAjax.request('GET', allTrucksUrl, yearParams, true).then(function (response) {
                    return response;
                })
            });
        }
    });
});