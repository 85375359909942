define('smart-tracking-webapp/models/owner-group', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        fein: _emberData.default.attr('string'),
        street: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        zip: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        bankRoutingNumber: _emberData.default.attr('string'),
        bankAccountNumber: _emberData.default.attr('string'),
        representativeName: _emberData.default.attr('string'),
        companyGroup: _emberData.default.attr('boolean'),
        owner: _emberData.default.belongsTo('user'),
        fullAddress: Ember.computed('street', 'city', 'state', function () {
            return this.get('street') + ', ' + this.get('city') + ', ' + this.get('state');
        }),
        trucks: _emberData.default.hasMany('truck')
    });
});