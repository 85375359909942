define('smart-tracking-webapp/controllers/protected/accounting/statistics/general', ['exports', 'moment', 'smart-tracking-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),

        totalGross: Ember.computed('model.totalGross', function () {
            let totalGross = this.get('model').totalGross || [];
            let total = 0;

            totalGross.forEach(record => {
                if (record[0] === 'Total') {
                    total = record[1];
                }
            });

            let updatedTotalGross = totalGross.filter(record => {
                return record[0] !== 'Total';
            });

            return {
                options: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Gross statistics'
                    },
                    plotOptions: {
                        pie: {
                            innerSize: 100,
                            depth: 45
                        }
                    }
                },

                data: [{
                    name: '$',
                    data: updatedTotalGross
                }],

                total: total
            };
        }),

        topCustomers: Ember.computed('model.topCustomers', function () {
            let topCustomers = this.get('model').topCustomers;
            let formattedCustomers = [];

            topCustomers.forEach(record => {
                formattedCustomers.push([record.name, parseFloat(record.money), 4]);
            });

            return {
                options: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Top customers'
                    },
                    plotOptions: {
                        pie: {
                            innerSize: 100,
                            depth: 45
                        }
                    }
                },

                data: [{
                    name: '$',
                    data: formattedCustomers
                }]
            };
        }),

        mileGrossRate: Ember.computed('model.mileGrossRate', function () {

            let mileGrossRate = this.get('model').mileGrossRate.data || [];

            let categories = [];
            let gross = [];
            let miles = [];
            let rate = [];

            mileGrossRate.forEach(record => {
                categories.push(record.truck);
                gross.push(parseFloat(record.money));
                miles.push(parseFloat(record.miles));
                rate.push(parseFloat(record.rate));
            });

            return {
                options: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Miles, Gross and Rate Per Mile Statistics Per Truck'
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0
                        // title: {
                        //     text: 'Rainfall (mm)'
                        // }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    }
                },

                data: [{
                    name: 'Gross ($)',
                    data: gross

                }, {
                    name: 'Miles',
                    data: miles

                }, {
                    name: 'Rate per mile',
                    data: rate

                }]
            };
        }),

        overallCompany: Ember.computed('model.mileGrossRate', function () {

            let mileGrossRate = this.get('model').mileGrossRate;
            let miles = mileGrossRate ? parseFloat(mileGrossRate.total[0].miles) : 0;
            let money = mileGrossRate ? parseFloat(mileGrossRate.total[0].money) : 0;
            let rate = mileGrossRate ? parseFloat(mileGrossRate.total[0].rate) : 0;

            return {
                options: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Overall Company Miles, Gross and Rate Per Mile'
                    },
                    xAxis: {
                        categories: ['Total'],
                        crosshair: true
                    },
                    yAxis: {
                        min: 0
                        // title: {
                        //     text: 'Rainfall (mm)'
                        // }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    }
                },

                data: [{
                    name: 'Total ($)',
                    data: [money]

                }, {
                    name: 'Miles',
                    data: [miles]

                }, {
                    name: 'Company rate per mile',
                    data: [rate]

                }]
            };
        }),

        statements: Ember.computed('model.statements', function () {

            let statements = this.get('model').statements;
            let categories = [];
            let due = [];
            let paid = [];
            let total = [];

            if (statements) {

                let drivers = statements.drivers;
                let owners = statements.owners;
                drivers.forEach(driver => {
                    categories.push(driver.name);
                });

                owners.forEach(truck => {
                    categories.push(truck.name);
                });

                drivers.forEach(record => {
                    due.push(record.due);
                    paid.push(record.paid);
                    total.push(record.total);
                });

                owners.forEach(record => {
                    due.push(record.due);
                    paid.push(record.paid);
                    total.push(record.total);
                });
            }

            return {
                options: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Statements for Company drivers and Owners Trucks'
                    },
                    xAxis: {
                        categories: categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0
                        // title: {
                        //     text: 'Rainfall (mm)'
                        // }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    }
                },

                data: [{
                    name: 'Total ($)',
                    data: total

                }, {
                    name: 'Paid ($)',
                    data: paid

                }, {
                    name: 'Due ($)',
                    data: due

                }]
            };
        }),

        totalStatements: Ember.computed('model.statements', function () {

            let statements = this.get('model').statements;
            let paid = statements ? statements.paid : 0;
            let total = statements ? statements.total : 0;
            let unpaid = statements ? statements.unpaid : 0;

            return {
                options: {
                    chart: {
                        type: 'column'
                    },

                    title: {
                        text: 'Total, Paid and Unpaid Statements'
                    },

                    xAxis: {
                        categories: ['Statements']
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Values'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    }
                },

                data: [{
                    name: 'Total',
                    data: [total]
                }, {
                    name: 'Paid',
                    data: [paid]
                }, {
                    name: 'Unpaid',
                    data: [unpaid]
                }]
            };
        }),

        actions: {
            filter(params) {

                this.set('selectedDate', {
                    startTime: params.start,
                    endTime: params.start
                });

                let formattedParams = {
                    startTime: (0, _moment.default)(params.start).format('YYYY-MM-DD'),
                    endTime: (0, _moment.default)(params.end).format('YYYY-MM-DD')
                };

                const basicAjax = this.get('basicAjax');
                const totalGrossUrl = _environment.default.APP.API_HOST + '/gross-statistic';
                const topCustomersUrl = _environment.default.APP.API_HOST + '/customer-statistic';
                const mileGrossRateUrl = _environment.default.APP.API_HOST + '/truck-gross-statistic';
                const statementsUrl = _environment.default.APP.API_HOST + '/statements-statistic';

                let self = this;

                basicAjax.request('GET', totalGrossUrl, formattedParams, true).then(function (response) {
                    self.set('model.totalGross', response);
                });
                basicAjax.request('GET', topCustomersUrl, formattedParams, true).then(function (response) {
                    self.set('model.topCustomers', response.data);
                });
                basicAjax.request('GET', mileGrossRateUrl, formattedParams, true).then(function (response) {
                    self.set('model.mileGrossRate', response);
                });
                basicAjax.request('GET', statementsUrl, formattedParams, true).then(function (response) {
                    self.set('model.statements', response);
                });
            }
        }

    });
});