define('smart-tracking-webapp/controllers/protected/accounting/accounts/drivers/driver/index', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    basicAjax: Ember.inject.service('basicAjax'),

    statementsTable: Ember.computed('model.statements.[]', 'model.statements', 'model.statements.@each.invoiceReference', function () {

      let statements = this.get('model').statements;
      return {
        header: [{ title: 'Statement number', propertyName: 'statementId' }, {
          title: 'Date',
          propertyName: 'formattedDate'
        }, {
          title: 'Check amount ($)',
          propertyName: 'finalPrice'
        }, {
          title: 'Payment status',
          propertyName: 'paymentStatus'
        }, {
          title: 'Payment',
          component: "table-switcher",
          disableSorting: true
        }, {
          title: 'Payment reference',
          propertyName: 'invoiceReference'
        },
        // {
        //     title: 'Edit',
        //     component: "table-edit-row",
        //     disableSorting: true
        // },
        {
          title: 'Delete',
          component: "table-delete-row",
          disableSorting: true
        }, {
          title: 'Download',
          component: "table-statement-generate",
          disableSorting: true
        }],
        data: statements
      };
    }),

    actions: {

      //payment-reference actions
      togglePayment(statement) {
        this.set('refNumber', null);
        let self = this;
        //get complete record and store in Ember data as in table is not complete one
        this.get('store').findRecord('statement', statement.get('id')).then(function (currentStatement) {
          self.set('currentStatement', currentStatement);
          self.set('paymentModal', true);
        });
      },

      confirmReferenceNumber(refNumber) {
        let currentStatement = this.get('currentStatement');
        let self = this;

        if (refNumber) {
          currentStatement.set('invoiceReference', refNumber);
          currentStatement.save().then(function () {
            self.set('refNumberError', false);
          });
          self.set('paymentModal', false);
        } else {
          if (currentStatement.get('invoiceReference')) {
            currentStatement.set('invoiceReference', null);
            self.set('paymentModal', false);
            currentStatement.save();
          } else {
            self.set('refNumberError', true);
          }
        }
      },

      closePaymentModal() {
        let currentStatement = this.get('currentStatement');
        if (currentStatement.get('invoiceReference')) {
          currentStatement.set('invoiceReference', currentStatement.get('invoiceReference'));
        }
        this.set('paymentModal', false);
      },

      //statement actions
      addStatement() {
        let statement = this.get('store').createRecord('statement');
        let randomId = Math.floor(Math.random() * 10000) + 1000;
        statement.set('id', randomId);
        this.set('currentStatement', statement);
        this.set('randomId', randomId);
        this.set('statementModal', true);
        this.set('isDelete', false);
      },

      confirmStatement(statement, randomId) {

        let currentDriver = this.get('model.driver');
        let self = this;

        if (randomId) {
          statement.set('id', null);
        }

        let loadsIds = [];
        statement.get('loads').map(item => {
          loadsIds.push(item.get('id'));
        });

        statement.set('driver', currentDriver);
        statement.set('loadsSaver', loadsIds);

        statement.save().then(function () {
          self.get('store').query('statement', {
            driver: currentDriver.get('id'),
            include: 'advances,loads'
          }).then(function (statements) {
            self.set('model.statements', statements);
            self.send('closeStatementModal');
          });
        });
      },

      closeStatementModal() {
        this.set('statementModal', false);
      },

      //generating statements
      generateStatement(id) {
        let basicAjax = this.get('basicAjax');
        let url = _environment.default.APP.API_HOST + "/generate-statement/" + id;

        basicAjax.request('GET', url).then(function (response) {
          let anchor = document.createElement("a");
          anchor.href = response.url;
          anchor.click();
        });
      },

      //delete
      deleteStatement(id) {
        let currentStatement = this.get('store').peekRecord('statement', id);
        this.set('currentStatement', currentStatement);
        this.set('isDelete', true);
        this.set('statementModal', true);
      },

      confirmDeleteStatement(currentStatement) {
        currentStatement.deleteRecord();
        let self = this;
        let currentDriver = this.get('model.driver');
        currentStatement.save().then(function () {
          self.get('store').query('statement', {
            driver: currentDriver.get('id'),
            include: 'advances,loads'
          }).then(function (statements) {
            self.set('model.statements', statements);
            self.send('closeStatementModal');
          });
        });
        this.set('statementModal', false);
      }

    }
  });
});