define('smart-tracking-webapp/routes/protected/users/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let user = routeCrud.getRecord(params, 'user');

            return Ember.RSVP.hash({
                user: user.record,
                states: this.get('store').findAll('state'),
                companies: this.get('store').findAll('company'),
                randomId: user.randomId,
                roles: ['ROLE_ADMIN', 'ROLE_SAFETY', 'ROLE_DISPATCH', 'ROLE_TRACKING', 'ROLE_ACCOUNTING']
            });
        }

    });
});