define('smart-tracking-webapp/controllers/protected/safety/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        expireDocuments: Ember.computed('model.documents', 'model.documents.[]', function () {

            let documents = this.get('model').documents || [];
            return {
                header: [{
                    title: 'Entity',
                    propertyName: 'case'
                }, {
                    title: 'Name/Number',
                    propertyName: 'truckid'
                }, {
                    title: 'Document type',
                    propertyName: 'name'
                }, {
                    title: 'Expire date',
                    propertyName: 'expiredate'
                }, {
                    title: 'Days left',
                    propertyName: 'days'
                }],
                data: documents
            };
        }),

        drivers: Ember.computed('model.entities', 'model.entities.[]', function () {
            let entities = this.get('model').entities;

            let formattedDrivers = entities.drivers.map(record => {
                return {
                    name: record.name,
                    y: record.count
                };
            });

            return {
                options: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Drivers'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    }
                },

                data: [{
                    name: 'Drivers',
                    colorByPoint: true,
                    data: formattedDrivers
                }]
            };
        }),

        entities: Ember.computed('model.entities', 'model.entities.[]', function () {
            let entities = this.get('model').entities;

            let formattedTrucks = entities.trucks.map(record => {
                return {
                    name: record.active ? 'Active' : 'Inactive',
                    y: record.count
                };
            });

            let formattedEquipment = entities.equipments.map(record => {
                return {
                    name: record.active ? 'Active' : 'Inactive',
                    y: record.count
                };
            });

            return {
                optionsTrucks: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Trucks'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    }
                },

                dataTrucks: [{
                    name: 'Trucks',
                    colorByPoint: true,
                    data: formattedTrucks
                }],

                optionsEquipment: {
                    chart: {
                        type: 'pie'
                    },
                    title: {
                        text: 'Equipment'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    }
                },

                dataEquipment: [{
                    name: 'Equipment',
                    colorByPoint: true,
                    data: formattedEquipment
                }]
            };
        })
    });
});