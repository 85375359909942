define('smart-tracking-webapp/routes/protected/accounting/accounts/owners/truck/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params, history) {
            let truckId = history.params['protected.accounting.accounts.owners.truck'].id;
            return Ember.RSVP.hash({
                statements: this.get('store').query('statement', { truck: truckId, include: 'statement' }),
                loads: this.get('store').query('load', { pagination: false, forTruck: truckId, newStatement: true, status: 'Completed', 'statement[exists]': false }),
                advances: this.get('store').query('advance', { pagination: false, truck: truckId, refunds: false, 'statement_exist': 0 }),
                refunds: this.get('store').query('advance', { pagination: false, truck: truckId, refunds: true, 'statement_exist': 0 }),
                truck: this.get('store').peekRecord('truck', truckId),
                fuel: this.get('store').query('fuel', { pagination: false, truck: truckId, 'statement[exists]': false })
            });
        }

    });
});