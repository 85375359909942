define('smart-tracking-webapp/controllers/protected/safety/settings/elds', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        companyEldProviders: Ember.computed('model.companyEldProviders', 'model.companyEldProviders.[]', function () {

            let companyEldProviders = this.get('model').companyEldProviders;
            return {
                header: [{
                    title: 'ELD provider',
                    propertyName: 'eldProvider.name'
                }, {
                    title: 'API key',
                    propertyName: 'apiKey'
                }, {
                    title: 'Edit',
                    component: "table-edit-row",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: companyEldProviders
            };
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            addProvider() {
                this.transitionToRoute('protected.safety.settings.elds.eld', 'new');
            },

            editProvider(id) {
                this.transitionToRoute('protected.safety.settings.elds.eld', id);
            },

            deleteProvider(id) {
                let provider = this.get('store').peekRecord('company-eld-provider', id);
                this.set('currentProvider', provider);
                this.set('openModal', true);
            },
            confirmDeleteProvider() {
                let provider = this.get('currentProvider');
                provider.deleteRecord();
                provider.save();
                this.send('closeModal');
            }
        }
    });
});