define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/driver', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params) {

            let driverTabs = [{
                icon: 'cog',
                label: 'Statements',
                link: 'protected.accounting.accounts.drivers.driver.index'
            }, {
                icon: 'cogs',
                label: 'Pay rates',
                link: 'protected.accounting.accounts.drivers.driver.rates'
            }, {
                icon: 'address-card-o',
                label: 'Advances and deductions',
                link: 'protected.accounting.accounts.drivers.driver.advances'
            }, {
                icon: 'address-card-o',
                label: 'Refunds and bonuses',
                link: 'protected.accounting.accounts.drivers.driver.refunds'
            }, {
                icon: 'address-card-o',
                label: 'Loads',
                link: 'protected.accounting.accounts.drivers.driver.loads'
            }];

            return Ember.RSVP.hash({
                driver: this.get('store').findRecord('driver', params.id),
                driverTabs: driverTabs
            });
        }

    });
});