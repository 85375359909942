define('smart-tracking-webapp/controllers/protected/accounting/accounts/drivers/driver/refunds', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        openModal: false,
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),
        advancesTable: Ember.computed('model.advances.[]', 'model.advances', function () {

            let advances = this.get('model').advances || [];
            return {
                header: [{
                    title: 'Description',
                    propertyName: 'description'
                }, {
                    title: 'Date',
                    propertyName: 'formattedDate'
                }, {
                    title: 'Amount ($)',
                    propertyName: 'amount'
                }, {
                    title: 'Payment status',
                    propertyName: 'status'
                }, {
                    title: 'Statement',
                    propertyName: 'statement.statementId'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }, {
                    title: 'View',
                    component: "table-view-row",
                    disableSorting: true
                }],
                data: advances
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            if (!this.get('isDelete')) {
                if (this.get('displayOnly')) {
                    return 'View';
                } else {
                    return 'Add';
                }
            } else {
                return 'Delete';
            }
        }),

        actions: {

            closeModal() {
                this.set('openModal', false);
            },

            //adding
            addAdvance() {
                this.set('openModal', true);
                this.set('isDelete', false);
                this.set('displayOnly', false);
                let advance = this.get('store').createRecord('advance');
                let randomId = Math.floor(Math.random() * 10000) + 1000;
                advance.set('id', randomId);
                this.set('currentAdvance', advance);
                this.set('randomId', randomId);
            },

            saveAdvance(advance) {
                const basicAjax = this.get('basicAjax');
                const notifications = this.get('notifications');
                let self = this;
                let currentDriver = this.get('model.driver');

                Ember.set(advance, 'driverId', currentDriver.get('id'));
                Ember.set(advance, 'refunds', true);
                Ember.set(advance, 'truckId', null);

                let url = _environment.default.APP.API_HOST + '/create-advance';

                basicAjax.request('POST', url, { advance: advance }).then(function () {

                    notifications.success('Refund(s)/bonus(es) successfully added', 'Success');

                    self.get('store').query('advance', {
                        driver: currentDriver.get('id'),
                        refunds: true
                    }).then(function (advances) {
                        self.set('model.advances', advances);
                        self.send('closeModal');
                    });
                }, function () {
                    notifications.error('Server error', 'Error');
                });
            },
            cancelAddAdvance() {
                this.send('closeModal');
            },

            //deleting
            deleteAdvance(id) {
                let advance = this.get('store').peekRecord('advance', id);
                this.set('currentAdvance', advance);
                this.set('openModal', true);
                this.set('isDelete', true);
                this.set('displayOnly', false);
            },

            confirmDeleteAdvance() {
                let advance = this.get('currentAdvance');
                advance.deleteRecord();
                advance.save();
                this.send('closeModal');
            },

            //view
            viewAdvance(id) {
                let advance = this.get('store').peekRecord('advance', id);
                this.set('currentAdvance', advance);
                this.set('openModal', true);
                this.set('isDelete', false);
                this.set('displayOnly', true);
            },

            confirmAdvance() {
                let self = this;
                let currentDriver = this.get('model.driver');
                this.get('store').query('advance', {
                    driver: currentDriver.get('id'),
                    refunds: true
                }).then(function (advances) {
                    self.set('model.advances', advances);
                    self.send('closeModal');
                });
            }
        }

    });
});