define('smart-tracking-webapp/controllers/protected/safety/drivers/driver/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                history.back();
            },

            saveDocument(document, randomId) {
                let driver = this.get('model').driver;
                document.set('driver', driver);

                let self = this;
                let driverId = driver.get('id');

                if (randomId) {
                    document.set('id', null);
                }

                document.save().then(function () {
                    self.get('store').findRecord('driver', driverId, { include: 'stops,events,driver' });
                });

                this.send('closeModal');
            }
        }
    });
});