define('smart-tracking-webapp/validations/expense', ['exports', 'ember-changeset-validations/validators', 'smart-tracking-webapp/validations/decimals'], function (exports, _validators, _decimals) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        description: (0, _validators.validatePresence)(true),
        category: (0, _validators.validatePresence)(true),
        amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true }), (0, _decimals.default)(true)]
    };
});