define('smart-tracking-webapp/routes/protected/safety/drivers/driver/drug-test', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {
            let driverId = transition.params['protected.safety.drivers.driver'].id;
            let routeCrud = this.get('routeCrud');
            let drugTest = routeCrud.getRecord(params, 'drug-test', 'test_id');
            return Ember.RSVP.hash({
                driver: this.get('store').findRecord('driver', driverId),
                drugTest: drugTest.record,
                randomId: drugTest.randomId,
                drugTestTypes: Ember.$.getJSON("/json/drug-test-types.json").then(function (types) {
                    return types;
                }),
                drugTestKinds: Ember.$.getJSON("/json/drug-test-kinds.json").then(function (kinds) {
                    return kinds;
                })
            });
        }

    });
});