define('smart-tracking-webapp/routes/protected/dispatch/index', ['exports', 'smart-tracking-webapp/config/environment', 'moment'], function (exports, _environment, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        session: Ember.inject.service(),

        model: function () {
            let userId = this.get('session.data.authenticated.user_id');
            let basicAjax = this.get('basicAjax');
            let url = _environment.default.APP.API_HOST + '/truck-board';

            let currentUser = this.get('store').peekRecord('user', userId);
            let currentUserRoles = currentUser.get('roles');

            let filter = {
                start: (0, _moment.default)().startOf('week').subtract(7, 'd').format('MM/DD/YYYY'),
                end: (0, _moment.default)().endOf('week').add(7, 'd').format('MM/DD/YYYY'),
                dispatch: currentUserRoles.includes('ROLE_DISPATCH') ? userId : null, //if null all values should be seen
                truckStatus: 'ALL'
            };

            return Ember.RSVP.hash({

                trucks: basicAjax.request('GET', url, filter, true).then(function (response) {
                    return response;
                }),
                allDispatchers: this.get('store').query('user', { roles: 'ROLE_DISPATCH' }),
                currentFilter: filter,
                currentUser: this.get('store').findRecord('user', userId),
                drivers: this.get('store').query('driver', { 'status.name': 'active driver', pagination: false }),
                equipment: this.get('store').query('equipment', { active: true, pagination: false }),
                allTrucks: this.get('store').query('truck', { active: true, pagination: false }),
                brokers: this.get('store').query('broker', { include: 'contacts', pagination: false }),
                loadStatuses: Ember.$.getJSON("/json/load-statuses.json").then(function (statuses) {
                    return statuses;
                })
            });
        }
    });
});