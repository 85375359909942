define('smart-tracking-webapp/controllers/protected', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        actions: {
            logout: function () {
                this.get('store').unloadAll();
                localStorage.clear();
                this.get('session').invalidate();
            }
        }
    });
});