define('smart-tracking-webapp/routes/protected/safety/drivers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {
            return Ember.RSVP.hash({
                drivers: this.get('store').query('driver', { include: 'status' }),
                owners: this.get('store').query('owner-group', { pagination: false }),
                driverStatuses: this.get('store').findAll('driver-status'),
                eldProviders: this.get('store').query('eld-provider', {})
            });
        }
    });
});