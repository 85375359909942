define('smart-tracking-webapp/routes/protected/safety/settings/elds/eld', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params) {

            let routeCrud = this.get('routeCrud');
            let provider = routeCrud.getRecord(params, 'company-eld-provider');

            return Ember.RSVP.hash({
                provider: provider.record,
                eldProviders: this.get('store').query('eld-provider', { pagination: false }),
                randomId: provider.randomId
            });
        }
    });
});