define('smart-tracking-webapp/components/owner-form', ['exports', 'smart-tracking-webapp/validations/group'], function (exports, _group) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        groupValidation: _group.default,

        broker: null,
        classNames: ['form'],

        actions: {

            saveGroup(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let group = this.get('group');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        group.set(change.key, change.value);
                    });

                    this.sendAction('saveGroup', group, randomId);
                }
            },
            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let group = this.get('group');
                    group.unloadRecord();
                }
                return changeset.rollback();
            }
        }
    });
});