define('smart-tracking-webapp/validations/accident', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        reportNumber: (0, _validators.validatePresence)(true),
        location: (0, _validators.validatePresence)(true),
        date: (0, _validators.validatePresence)(true)
    };
});