define('smart-tracking-webapp/controllers/protected/safety/settings/elds/eld', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                this.transitionToRoute('protected.safety.settings.elds');
            },

            saveProvider(provider, randomId) {

                let self = this;
                if (randomId) {
                    provider.set('id', null);
                }

                let companyId = JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.company_id;
                let currentCompany = this.get('store').peekRecord('company', companyId);

                provider.set('company', currentCompany);
                provider.save().then(function () {
                    self.send('refreshModel');
                    self.transitionToRoute('protected.safety.settings.elds');
                });
            }
        }
    });
});