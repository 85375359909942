define('smart-tracking-webapp/controllers/protected/accounting/loads', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),

        allLoads: Ember.computed('model.loads', 'model.loads.[]', 'invoiceGenerated', 'model.@each.invoiceReference', function () {
            let loads = this.get('model').loads || [];
            return {
                header: [{
                    title: 'Reference number',
                    propertyName: 'referenceNumber',
                    routeName: "protected.dispatch.loads.load"
                }, {
                    title: 'Trip number',
                    propertyName: 'tripNumber'
                }, {
                    title: 'Date (From)',
                    propertyName: 'firstStop.date',
                    disableSorting: true
                }, {
                    title: 'Date (To)',
                    propertyName: 'lastStop.date',
                    disableSorting: true
                }, {
                    title: 'Initial charge ($)',
                    propertyName: 'dollarInitialMoney'
                }, {
                    title: 'Status',
                    propertyName: 'status'
                }, {
                    title: 'Invoice',
                    propertyName: 'invoice',
                    component: "table-invoice"
                }, {
                    title: 'Download invoice',
                    component: "table-download-invoice",
                    disableSorting: true
                }, {
                    title: 'Payment',
                    component: "table-switcher",
                    disableSorting: true
                }, {
                    title: 'Payment reference',
                    propertyName: 'invoiceReference',
                    disableSorting: true
                }],
                data: loads
            };
        }),

        actions: {
            togglePayment(load) {
                this.set('refNumber', null);
                this.set('currentLoad', load);
                this.set('paymentModal', true);
            },

            confirmReferenceNumber(refNumber) {
                let currentLoad = this.get('currentLoad');
                let self = this;

                if (refNumber) {
                    currentLoad.set('invoiceReference', refNumber);
                    currentLoad.save().then(function () {
                        self.set('refNumberError', false);
                    });
                    self.set('paymentModal', false);
                } else {
                    if (currentLoad.get('invoiceReference')) {
                        currentLoad.set('invoiceReference', null);
                        self.set('paymentModal', false);
                        currentLoad.save();
                    } else {
                        self.set('refNumberError', true);
                    }
                }
            },

            closeModal() {
                let currentLoad = this.get('currentLoad');
                if (currentLoad.get('invoiceReference')) {
                    currentLoad.set('invoiceReference', currentLoad.get('invoiceReference'));
                }
                this.set('paymentModal', false);
            },

            generateInvoice(id) {
                const notifications = this.get('notifications');
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/generate-invoice/" + id;
                basicAjax.request('GET', url).then(function () {
                    notifications.success('Successfully generated invoice');
                    location.reload();
                });
            },

            uploadInvoice(load) {
                this.set('currentLoad', load);
                this.set('uploadModal', true);
            },

            closeUploadModal() {
                this.set('uploadModal', false);
            },

            confirmUpload(filePath) {
                let currentLoad = this.get('currentLoad');
                let self = this;
                currentLoad.set('invoice', filePath);
                currentLoad.save().then(() => {
                    self.set('uploadModal', false);
                });
            },

            downloadInvoice(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-invoice/" + id;
                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            },

            deleteInvoice(load) {
                this.set('currentLoad', load);
                this.set('deleteInvoiceModal', true);
            },

            closeDeleteInvoiceModal() {
                this.set('deleteInvoiceModal', false);
            },

            confirmDeleteInvoice() {
                let self = this;
                let currentLoad = this.get('currentLoad');
                currentLoad.set('invoice', null);
                currentLoad.save().then(function () {
                    self.set('deleteInvoiceModal', false);
                });
            }
        }

    });
});