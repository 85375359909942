define('smart-tracking-webapp/models/drug-test', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        date: _emberData.default.attr('string'),
        passed: _emberData.default.attr('boolean'),
        path: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        kind: _emberData.default.attr('string'),
        driver: _emberData.default.belongsTo('driver', { inverse: null }),
        status: Ember.computed('passed', function () {
            let passed = this.get('passed');
            let status;
            if (passed === true) {
                status = 'Negative';
            } else if (passed === false) {
                status = 'Positive';
            } else {
                status = null;
            }
            return status;
        })
    });
});