define('smart-tracking-webapp/routes/protected/accounting/accounts/owners/truck/refunds', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params, history) {
            let truckId = history.params['protected.accounting.accounts.owners.truck'].id;
            return Ember.RSVP.hash({
                advances: this.get('store').query('advance', { truck: truckId, refunds: true, include: 'statement' }),
                truck: this.get('store').peekRecord('truck', truckId)
            });
        }

    });
});