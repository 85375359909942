define('smart-tracking-webapp/controllers/protected/safety/settings/groups/group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                this.transitionToRoute('protected.safety.settings.groups');
            },

            saveGroup(group, randomId) {

                let self = this;
                if (randomId) {
                    group.set('id', null);
                }

                group.save().then(function () {
                    self.send('refreshModel');
                    self.transitionToRoute('protected.safety.settings.groups');
                });
            }
        }
    });
});