define('smart-tracking-webapp/controllers/protected/accounting/company', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        openModal: false,
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),

        expensesTable: Ember.computed('model', 'model.[]', 'model.@each.formattedDate', function () {

            let expenses = this.get('model') || [];
            return {
                header: [{
                    title: 'Description',
                    propertyName: 'description'
                }, {
                    title: 'Date',
                    propertyName: 'formattedDate'
                }, {
                    title: 'Amount ($)',
                    propertyName: 'amount'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }, {
                    title: 'View',
                    component: "table-view-row",
                    disableSorting: true
                }],
                data: expenses
            };
        }),

        modalTitle: Ember.computed('isDelete', 'displayOnly', function () {
            if (!this.get('isDelete')) {
                if (this.get('displayOnly')) {
                    return 'View';
                } else {
                    return 'Add';
                }
            } else {
                return 'Delete';
            }
        }),

        actions: {

            closeModal() {
                this.set('openModal', false);
            },

            addExpense() {
                this.set('openModal', true);
                this.set('isDelete', false);
                this.set('displayOnly', false);
                let expense = this.get('store').createRecord('company-expense');
                let randomId = Math.floor(Math.random() * 10000) + 1000;
                expense.set('id', randomId);
                this.set('currentExpense', expense);
                this.set('randomId', randomId);
            },

            saveExpense(expense) {
                const basicAjax = this.get('basicAjax');
                const notifications = this.get('notifications');
                let self = this;

                let url = _environment.default.APP.API_HOST + '/create-expense';

                basicAjax.request('POST', url, { expense: expense }).then(function () {

                    notifications.success('Expense(s) successfully added', 'Success');

                    self.get('store').query('company-expense', {}).then(function (expenses) {
                        self.set('model', expenses);
                        self.send('closeModal');
                    });
                }, function () {
                    notifications.error('Server error', 'Error');
                });

                self.send('closeModal');
            },

            cancelAddExpense() {
                this.send('closeModal');
            },

            //deleting
            deleteExpense(id) {
                let expense = this.get('store').peekRecord('company-expense', id);
                this.set('currentExpense', expense);
                this.set('openModal', true);
                this.set('isDelete', true);
                this.set('displayOnly', false);
            },

            confirmDeleteExpense() {
                let expense = this.get('currentExpense');
                expense.deleteRecord();
                expense.save();
                this.send('closeModal');
            },

            //view
            viewExpense(id) {
                let expense = this.get('store').peekRecord('company-expense', id);
                this.set('currentExpense', expense);
                this.set('openModal', true);
                this.set('isDelete', false);
                this.set('displayOnly', true);
            },

            confirmExpense() {
                let self = this;
                this.get('store').query('company-expense', {}).then(function (expenses) {
                    self.set('model', expenses);
                    self.send('closeModal');
                });
            }
        }
    });
});