define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/driver/rates', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params, history) {
            return Ember.RSVP.hash({
                driver: this.get('store').peekRecord('driver', history.params['protected.accounting.accounts.drivers.driver'].id)
            });
        }

    });
});