define('smart-tracking-webapp/routes/protected/safety/trucks/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            return Ember.RSVP.hash({
                trucks: this.get('store').query('truck', {}),
                ownerGroups: this.get('store').query('owner-group', { pagination: false }),
                allMakes: Ember.$.getJSON("/json/truck-makes.json").then(function (makes) {
                    return makes;
                }),
                allFuelTypes: Ember.$.getJSON("/json/fuel-types.json").then(function (types) {
                    return types;
                }),
                eldProviders: this.get('store').query('eld-provider', {})
            });
        }
    });
});