define('smart-tracking-webapp/routes/protected/dispatch/loads/load/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {

            let loadId = transition.params['protected.dispatch.loads.load'].id;
            let routeCrud = this.get('routeCrud');
            let document = routeCrud.getRecord(params, 'load-document', 'document_id');

            return Ember.RSVP.hash({
                load: this.get('store').findRecord('load', loadId, { include: 'documents' }),
                document: document.record,
                randomId: document.randomId,
                loadDocumentTypes: Ember.$.getJSON("/json/load-document-types.json").then(function (types) {
                    return types;
                })
            });
        }

    });
});