define('smart-tracking-webapp/routes/protected/safety/equipment/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            return Ember.RSVP.hash({
                equipments: this.get('store').query('equipment', {}),
                ownerGroups: this.get('store').query('owner-group', { pagination: false }),
                equipmentTypes: Ember.$.getJSON("/json/equipment-types.json").then(function (types) {
                    return types;
                })
            });
        }
    });
});