define('smart-tracking-webapp/components/btn--regular', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            onClick() {
                this.sendAction('onClick');
            }
        }
    });
});