define('smart-tracking-webapp/controllers/protected/safety/equipment/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        equipmentsTable: Ember.computed('model.equipments.[]', 'model.equipments', function () {

            let equipments = this.get('model').equipments;
            return {
                header: [{
                    title: 'Equipment number',
                    propertyName: 'equipmentId',
                    routeName: "protected.safety.equipment.equipment"
                }, {
                    title: 'Type',
                    propertyName: 'type'
                }, {
                    title: 'Licence',
                    propertyName: 'licence'
                }, {
                    title: 'VIN',
                    propertyName: 'vin'
                }, {
                    title: 'Owner group',
                    propertyName: 'owner.name'
                }, {
                    title: 'Status',
                    propertyName: 'status'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: equipments
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete equipment' : 'Add equipment';
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },

            cancelAddingEquipment() {
                let currentEquipment = this.get('currentEquipment');
                currentEquipment.unloadRecord();
                this.send('closeModal');
            },

            deleteEquipment(id) {
                let equipment = this.get('store').peekRecord('equipment', id);
                this.set('currentEquipment', equipment);
                this.set('openModal', true);
                this.set('isDelete', true);
            },
            confirmDeleteEquipment() {
                let equipment = this.get('currentEquipment');
                equipment.deleteRecord();
                equipment.save();
                this.send('closeModal');
            },
            addEquipment() {
                this.set('openModal', true);
                let equipment = this.get('store').createRecord('equipment', {});
                this.set('currentEquipment', equipment);
                this.set('isDelete', false);
            },
            confirmAddEquipment(equipment) {
                let self = this;
                equipment.save().then(function () {
                    self.get('store').query('equipment', {}).then(function (equipment) {
                        self.set('model.equipments', equipment);
                    });
                }).catch(function () {
                    equipment.unloadRecord();
                });
                this.send('closeModal');
            }
        }
    });
});