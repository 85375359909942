define('smart-tracking-webapp/routes/protected/accounting/statistics/dispatch-performance', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {

            let params = {
                startTime: (0, _moment.default)().startOf('month').format('YYYY-MM-DD'),
                endTime: (0, _moment.default)().endOf('month').format('YYYY-MM-DD')
            };

            return Ember.RSVP.hash({
                selectedDate: {
                    start: params.startTime,
                    end: params.endTime
                },
                dispatchPerformance: this.get('store').query('dispatch-performance', { startTime: params.startTime, endTime: params.endTime })
            });
        }
    });
});