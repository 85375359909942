define('smart-tracking-webapp/models/truck', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        truckId: _emberData.default.attr('string'),
        active: _emberData.default.attr('boolean'),
        licence: _emberData.default.attr('string'),
        vin: _emberData.default.attr('string'),
        owner: _emberData.default.belongsTo('owner-group', { inverse: null }),
        documents: _emberData.default.hasMany('vehicle-document', { inverse: null }),
        company: _emberData.default.belongsTo('company', { inverse: null }),
        maintenances: _emberData.default.hasMany('vehicle-maintenance', { inverse: null }),
        accidents: _emberData.default.hasMany('accident', { inverse: null }),
        year: _emberData.default.attr('string'),
        make: _emberData.default.attr('string'),
        model: _emberData.default.attr('string'),
        fuelType: _emberData.default.attr('string'),
        issuingState: _emberData.default.attr('string'),
        dispatcher: _emberData.default.belongsTo('user'),
        note: _emberData.default.attr('string'),
        perEmptyMiles: _emberData.default.attr('number'),
        perLoadedMiles: _emberData.default.attr('number'),
        perTotalMiles: _emberData.default.attr('number'),
        perStop: _emberData.default.attr('number'),
        perPickup: _emberData.default.attr('number'),
        perDelivery: _emberData.default.attr('number'),
        perExtraStop: _emberData.default.attr('number'),
        percentageDetention: _emberData.default.attr('number'),
        percentageLayover: _emberData.default.attr('number'),
        initialAmount: _emberData.default.attr('number'),
        status: Ember.computed('active', function () {
            return this.get('active') ? 'Active' : 'Inactive';
        }),
        eldProviderId: _emberData.default.attr('string'),
        eldProvider: _emberData.default.belongsTo('eld-provider')
    });
});