define('smart-tracking-webapp/controllers/protected/admin', ['exports', 'smart-tracking-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        session: Ember.inject.service('session'),
        notifications: Ember.inject.service('toast'),

        usersTable: Ember.computed('model.users', 'model.users.[]', function () {
            let users = this.get('model').users || [];
            return {
                header: [{
                    title: 'Username',
                    propertyName: 'username',
                    routeName: "protected.admin.user"
                }, { title: 'First name', propertyName: 'firstName' }, { title: 'Last name', propertyName: 'lastName' }, { title: 'Status', propertyName: 'statusName', disableSorting: true }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }, {
                    title: 'Change password',
                    component: "table-change-pass",
                    disableSorting: true
                }],
                data: users
            };
        }),

        documentsTable: Ember.computed('model.company.documents', 'model.company.documents.[]', function () {
            let documents = this.get('model').company.get('documents');
            return {
                header: [{ title: 'Description', propertyName: 'description' }, { title: 'Issue date', propertyName: 'issueDate' }, { title: 'Expire date', propertyName: 'expireDate' }, {
                    title: 'Download',
                    component: "table-download-document",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: documents
            };
        }),

        isSuperAdmin: Ember.computed('model.currentUser', function () {

            let currentUser = this.get('model').currentUser;
            let userRoles = currentUser.get('roles');

            return userRoles.includes('ROLE_SUPERADMIN');
        }),

        actions: {
            closeModal() {
                this.set('deleteUserModal', false);
                this.set('deleteDocumentModal', false);
                this.set('changePassUserModal', false);
            },

            // users
            deleteUser(id) {
                let user = this.get('store').peekRecord('user', id);
                this.set('currentUser', user);
                this.set('deleteUserModal', true);
            },

            confirmDeleteUser() {
                let user = this.get('currentUser');
                user.deleteRecord();
                user.save();
                this.send('closeModal');
            },

            addUser() {
                this.transitionToRoute('protected.admin.user', 'new');
            },

            //documents
            addDocument() {
                this.transitionToRoute('protected.admin.document', 'new');
            },

            deleteDocument(id) {
                let document = this.get('store').peekRecord('company-document', id);
                this.set('currentDocument', document);
                this.set('deleteDocumentModal', true);
            },

            confirmDeleteDocument() {
                let document = this.get('currentDocument');
                document.deleteRecord();
                document.save();
                this.send('closeModal');
            },

            downloadDocument(id) {
                let basicAjax = this.get('basicAjax');
                let url = _environment.default.APP.API_HOST + "/get-company-documents/" + id;

                basicAjax.request('GET', url).then(function (response) {
                    let anchor = document.createElement("a");
                    anchor.href = response.url;
                    anchor.click();
                });
            },

            saveLogo(filePath) {
                let companyId = this.get('session.data.authenticated.company_id');
                let company = this.get('store').peekRecord('company', companyId);
                company.set('logo', filePath);
                company.save();
            },

            saveCompany(company) {
                const notifications = this.get('notifications');
                company.save().then(function () {
                    notifications.success('Company successfully saved');
                });
            },

            changePass(user) {
                this.set('changePassUserModal', true);
                this.set('currentUser', user);
            },
            updatePassword() {
                this.send('closeModal');
            }
        }

    });
});