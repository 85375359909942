define('smart-tracking-webapp/routes/protected/dispatch/loads-archive', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        model: function () {
            let userId = this.get('session.data.authenticated.user_id');
            return Ember.RSVP.hash({
                loads: this.get('store').query('load', { include: "events,stops,truck" }),
                drivers: this.get('store').query('driver', { pagination: false }),
                filteredDrivers: this.get('store').query('driver', { 'status.name': 'active driver', pagination: false }),
                equipment: this.get('store').query('equipment', { pagination: false }),
                filteredEquipment: this.get('store').query('equipment', { active: true, pagination: false }),
                trucks: this.get('store').query('truck', { pagination: false }),
                filteredTrucks: this.get('store').query('truck', { active: true, pagination: false }),
                brokers: this.get('store').query('broker', { include: 'contacts', pagination: false }),
                loadStatuses: Ember.$.getJSON("/json/load-statuses.json").then(function (statuses) {
                    return statuses;
                }),
                currentUser: this.get('store').findRecord('user', userId)
            });
        }
    });
});