define('smart-tracking-webapp/controllers/protected/accounting/accounts/owners/truck/fuel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        fuel: Ember.computed('model', 'model.[]', function () {
            let loads = this.get('model') || [];
            return {
                header: [{
                    title: 'Transaction date and time',
                    propertyName: 'formattedDate'
                }, {
                    title: 'City',
                    propertyName: 'city'
                }, {
                    title: 'State',
                    propertyName: 'state'
                }, {
                    title: 'Product Code',
                    propertyName: 'code'
                }, {
                    title: 'Quantity',
                    propertyName: 'quantity'
                }, {
                    title: 'Amount ($)',
                    propertyName: 'amount'
                }],
                data: loads
            };
        })
    });
});