define('smart-tracking-webapp/instance-initializers/application', ['exports', 'smart-tracking-webapp/utils/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(appInstance) {
        _application.default.instance = appInstance;

        const applicationRoute = appInstance.lookup('route:application');
        const session = appInstance.lookup('service:session');
        session.on('authenticationSucceeded', function () {
            applicationRoute.transitionTo('protected.index');
        });
        session.on('invalidationSucceeded', function () {
            applicationRoute.transitionTo('login');
        });
    }

    exports.default = {
        name: 'application',
        initialize
    };
});