define('smart-tracking-webapp/components/company-expense-display-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            confirm(expense) {
                let self = this;
                expense.save().then(function () {
                    self.sendAction('confirmExpense');
                });
            },

            closeModal() {
                this.sendAction('closeModal');
            }
        }

    });
});