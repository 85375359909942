define('smart-tracking-webapp/components/stop-form', ['exports', 'smart-tracking-webapp/validations/stop', 'moment'], function (exports, _stop, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        notifications: Ember.inject.service('toast'),
        stopValidation: _stop.default,
        stop: null,
        classNames: ['form'],

        stopTypes: Ember.computed('loadStops', 'model.loadStops', function () {

            let loadStops = this.get('loadStops');
            let counter = 0;
            if (loadStops) {
                loadStops.forEach(() => {
                    counter++;
                });
            }

            return counter === 0 ? ['Pick-up'] : ['Pick-up', 'Delivery'];
        }),

        actions: {

            saveStop(changeset) {

                const notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let stop = this.get('stop');
                let randomId = this.get('randomId');
                let createdFacility = this.get('createdFacility');

                let startTime = changeset.get('startTime');
                let endTime = changeset.get('endTime');

                if (isValid) {
                    changes.forEach(function (change) {
                        stop.set(change.key, change.value);
                    });

                    if (createdFacility) {
                        stop.set('facility', createdFacility);
                    }

                    if (!(0, _moment.default)(startTime).isBefore(endTime)) {
                        notifications.error('Stop end time must be after stop start time');
                        return false;
                    }

                    this.sendAction('saveStop', stop, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let stop = this.get('stop');
                    stop.unloadRecord();
                }
                return changeset.rollback();
            },

            didSelectFacility(facility, isCreate) {
                if (isCreate) {
                    //set new facility as stop facility with rand id
                    let randomId = Math.floor(Math.random() * 10000) + 1000;
                    Ember.set(facility, 'id', randomId);
                    this.set('newFacility', facility);
                    this.set('facilityModal', true);
                }
            },

            addFacility() {
                let randomId = Math.floor(Math.random() * 10000) + 1000;
                let facility = this.get('store').createRecord('facility');
                Ember.set(facility, 'id', randomId);
                this.set('newFacility', facility);
                this.set('facilityModal', true);
                this.set('facilityModal', true);
            },

            saveFacility(facility) {
                facility.set('id', null);
                facility.save();
                this.set('createdFacility', facility);
                this.set('facilityModal', false);
            },

            closeFacilityModal() {
                this.set('stop.facility', null);
                this.set('facilityModal', false);
                this.set('allFacilities', []);
            },

            searchFacilities(value) {
                let self = this;
                self.get('store').query('facility', { search: value, pagination: false }).then(values => {
                    self.set('allFacilities', values);
                });
            }
        }
    });
});