define('smart-tracking-webapp/components/rate-toggler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['rate-toggler'],
        isDisabled: Ember.computed('value', function () {
            return !this.value;
        }),
        actions: {
            toggleValue(value) {
                let self = this;
                if (value) {
                    self.set('value', null);
                    self.set('isDisabled', true);
                } else {
                    self.set('isDisabled', false);
                }
            }
        }
    });
});