define('smart-tracking-webapp/components/recovery-password-form', ['exports', 'smart-tracking-webapp/validations/recovery-password', 'smart-tracking-webapp/config/environment'], function (exports, _recoveryPassword, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        recoveryValidation: _recoveryPassword.default,
        notifications: Ember.inject.service('toast'),

        init() {
            this._super(...arguments);
            this.set('recovery', {
                password: '',
                passwordConfirmation: ''
            });
        },

        actions: {
            send(changeset) {
                const notifications = this.get('notifications');
                changeset.validate();
                let isValid = changeset.get('isValid');

                const basicAjax = this.get('basicAjax');

                if (isValid) {
                    let queryString = window.location.search;
                    let hash = queryString.substring(6);
                    let url = _environment.default.APP.API_HOST + "/reset-password/" + hash;
                    let jsonData = { "recovery": { "password": changeset.get('password'), "confirmPassword": changeset.get('passwordConfirmation') } };

                    basicAjax.request('POST', url, jsonData).then(function (response) {
                        if (response) {
                            notifications.success('Password is successfully changed!');
                        } else {
                            notifications.error('Server error', 'Error');
                        }
                    });
                }
            }
        }

    });
});