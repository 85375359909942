define('smart-tracking-webapp/validations/charge', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        category: (0, _validators.validatePresence)(true),
        cost: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })]
    };
});