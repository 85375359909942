define('smart-tracking-webapp/routes/protected/safety/settings/driver-document-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function () {
            return Ember.RSVP.hash({
                driverDocumentTypes: this.get('store').findAll('driver-document-type')
            });
        }
    });
});