define('smart-tracking-webapp/controllers/protected/safety/trucks/truck/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            closeModal() {
                history.back();
            },

            saveDocument(document, randomId) {
                let truck = this.get('model').truck;
                document.set('truck', truck);

                let self = this;
                let truckId = truck.get('id');

                if (randomId) {
                    document.set('id', null);
                }

                document.save().then(function () {
                    self.get('store').findRecord('truck', truckId, { include: 'documents,maintenances,accidents' });
                });

                this.send('closeModal');
            }
        }
    });
});