define('smart-tracking-webapp/routes/protected/dispatch/loads/load/stop', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {
            let loadId = transition.params['protected.dispatch.loads.load'].id;
            let routeCrud = this.get('routeCrud');
            let stop = routeCrud.getRecord(params, 'stop', 'stop_id');
            return Ember.RSVP.hash({
                load: this.get('store').findRecord('load', loadId),
                stop: stop.record,
                randomId: stop.randomId,
                facilities: this.get('store').query('facility', {}),
                loadStops: this.get('store').query('stop', {
                    load: loadId,
                    include: 'facility'
                })
            });
        }
    });
});