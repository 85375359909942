define('smart-tracking-webapp/components/change-password-form', ['exports', 'smart-tracking-webapp/validations/password', 'smart-tracking-webapp/config/environment'], function (exports, _password, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        notifications: Ember.inject.service('toast'),
        password: {
            currentPassword: null,
            newPassword: null,
            passwordConfirmation: null
        },
        classNames: ['form'],
        passwordValidation: _password.default,

        actions: {
            updatePassword(changeset) {
                let notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let self = this;
                if (isValid) {

                    let basicAjax = this.get('basicAjax');
                    let url = _environment.default.APP.API_HOST + "/update/password";
                    let data = {};

                    //preparing data for server
                    changes.forEach(function (change) {
                        if (change.key === 'currentPassword') {
                            data['oldPassword'] = change.value;
                        }

                        if (change.key === 'passwordConfirmation') {
                            data['newPassword'] = change.value;
                        }
                    });

                    let jsonData = { "update": data };

                    basicAjax.request('POST', url, jsonData).then(function (response) {
                        self.set('wrongPasswordError', false);
                        self.sendAction('closeModal');
                    }, function () {
                        self.set('wrongPasswordError', true);
                    });
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');
                return changeset.rollback();
            }
        }
    });
});