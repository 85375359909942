define('smart-tracking-webapp/routes/protected/accounting/accounts/drivers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            let self = this;
            return Ember.RSVP.hash({
                drivers: this.get('store').query('owner-group', { companyGroup: false }).then(groups => {
                    let groupIds = [];
                    groups.forEach(group => {
                        groupIds.push(group.get('id'));
                    });
                    return self.get('store').query('driver', { 'owner.companyGroup': true, include: 'status' });
                })
            });
        }

    });
});