define('smart-tracking-webapp/components/event-form', ['exports', 'smart-tracking-webapp/validations/event'], function (exports, _event) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        eventValidation: _event.default,

        event: null,
        classNames: ['form'],

        actions: {

            saveEvent(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let event = this.get('event');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        event.set(change.key, change.value);
                    });
                    event.set('authorFullName', null);
                    this.sendAction('saveEvent', event, randomId);
                }
            },
            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let event = this.get('event');
                    event.unloadRecord();
                }
                return changeset.rollback();
            }
        }
    });
});