define('smart-tracking-webapp/components/accident-form', ['exports', 'smart-tracking-webapp/validations/accident'], function (exports, _accident) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        classNames: ['form'],
        accidentValidation: _accident.default,
        uploadedFiles: [],
        document: null,
        notifications: Ember.inject.service('toast'),

        actions: {

            addDocument(files) {
                this.set('uploadedFiles', files);
            },
            saveAccident(changeset) {

                const notifications = this.get('notifications');
                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let accident = this.get('accident');
                let randomId = this.get('randomId');
                let uploadedFiles = this.get('uploadedFiles');
                let formattedFiles = uploadedFiles.join();

                let description = changeset.get('description');

                if (description && description.length > 5000) {
                    notifications.error('Too long description', 'Error');
                    return false;
                }

                if (isValid) {
                    changes.forEach(function (change) {
                        accident.set(change.key, change.value);
                    });

                    if (uploadedFiles) {
                        accident.set('path', formattedFiles);
                    }
                    this.sendAction('saveAccident', accident, randomId);
                }
            },
            rollback(changeset) {
                this.sendAction('closeModal');
                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let accident = this.get('accident');
                    accident.unloadRecord();
                }
                return changeset.rollback();
            }
        }
    });
});