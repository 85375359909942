define('smart-tracking-webapp/routes/protected/copy-brokers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),

        activate() {
            let self = this;
            this.get('navigationService').set('visibleItems', self.get('navigationService').get('defaultVisibleItems'));
        },

        model: function () {
            return Ember.RSVP.hash({
                companies: this.get('store').query('company', { pagination: false })
            });
        },
        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});