define('smart-tracking-webapp/controllers/protected/admin/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init() {
            this._super(...arguments);
            let allRoles = ['ROLE_ADMIN', 'ROLE_SAFETY', 'ROLE_DISPATCH', 'ROLE_TRACKING', 'ROLE_ACCOUNTING', 'ROLE_DRIVER'];
            this.set('allRoles', allRoles);
        },

        actions: {
            closeModal() {
                this.transitionToRoute('protected.admin');
            },

            saveUser(user, randomId) {

                if (randomId) {
                    user.set('id', null);
                }

                user.save().catch(function () {
                    user.unloadRecord();
                });

                this.send('closeModal');
            }
        }
    });
});