define('smart-tracking-webapp/models/equipment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        equipmentId: _emberData.default.attr('string'),
        active: _emberData.default.attr('boolean'),
        licence: _emberData.default.attr('string'),
        vin: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        owner: _emberData.default.belongsTo('owner-group', { inverse: null }),
        company: _emberData.default.belongsTo('company', { inverse: null }),
        documents: _emberData.default.hasMany('vehicle-document', { inverse: null }),
        maintenances: _emberData.default.hasMany('vehicle-maintenance', { inverse: null }),
        accidents: _emberData.default.hasMany('accident', { inverse: null }),
        status: Ember.computed('active', function () {
            return this.get('active') ? 'Active' : 'Inactive';
        })
    });
});