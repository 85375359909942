define('smart-tracking-webapp/routes/protected/safety/equipment/equipment/document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {

            let equipmentId = transition.params['protected.safety.equipment.equipment'].id;
            let routeCrud = this.get('routeCrud');
            let document = routeCrud.getRecord(params, 'vehicle-document', 'document_id');

            return Ember.RSVP.hash({
                equipment: this.get('store').findRecord('equipment', equipmentId),
                document: document.record,
                randomId: document.randomId,
                equipmentDocumentTypes: this.get('store').findAll('vehicle-document-type')
            });
        }

    });
});