define('smart-tracking-webapp/controllers/protected/companies', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        companyTable: Ember.computed('model.companies.[]', 'model.companies', function () {

            let companies = this.get('model').companies;
            return {
                header: [{
                    title: 'Name',
                    propertyName: 'name',
                    routeName: "protected.companies.company"
                }, { title: 'Zip', propertyName: 'zip' }, { title: 'State', propertyName: 'state.name' }, {
                    title: 'Delete',
                    propertyName: 'id',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: companies
            };
        }),

        actions: {
            closeModal() {
                this.set('deleteModal', false);
            },
            deleteCompany(id) {
                let company = this.get('store').peekRecord('company', id);
                this.set('currentCompany', company);
                this.set('deleteModal', true);
            },
            confirmDeleteCompany() {
                let company = this.get('currentCompany');

                company.deleteRecord();
                company.save();
                this.send('closeModal');
            },
            addCompany() {
                this.transitionToRoute('protected.companies.company', 'new');
            }
        }
    });
});