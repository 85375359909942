define('smart-tracking-webapp/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        date: _emberData.default.attr('string'),
        note: _emberData.default.attr('string'),
        authorFullName: _emberData.default.attr('string'),
        load: _emberData.default.belongsTo('load', { inverse: null })
    });
});