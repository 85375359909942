define('smart-tracking-webapp/components/time-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['timepicker-component'],

        /**
         * didInsertElement sets available and init values for hours and minutes
         * @method didInsertElement
         */
        settingTime() {

            function setupTime(limit) {
                return Array.from({ length: limit }, (currentValue, index) => index < 10 ? '0' + `${index++}` : `${index++}`);
            }

            let hours = setupTime(24);
            let minutes = setupTime(60);

            this.set('hours', hours);
            this.set('minutes', minutes);

            function splitTime(time) {
                let split = time.split(':');
                return {
                    hours: split[0],
                    minutes: split[1]
                };
            }

            let split = {
                hours: null,
                minutes: null
            };

            //if current time is set (editing cases)
            if (this.currentTime) {
                split = splitTime(this.currentTime);
            }

            this.set('selectedHours', split.hours);
            this.set('selectedMinutes', split.minutes);
        },

        didUpdateAttrs() {
            this.settingTime();
        },

        /**
         * didInsertElement sets available and init values for hours and minutes
         * @method didInsertElement
         */
        didInsertElement() {
            this.settingTime();
        },

        /**
         * presetTime sets selected value to select field and presets other select value to '00'
         * @method presetTime
         * @params selected {string} - selected value, currentSelect - value to be set, presetValue - value to be preset
         */
        presetTime(selected, currentSelect, presetValue) {
            this.set(currentSelect, selected);
            if (!this.get(presetValue)) {
                this.set(presetValue, '00');
            }
        },

        selectedTime: Ember.computed('selectedHours', 'selectedMinutes', function () {
            return this.get('selectedHours') + ':' + this.get('selectedMinutes');
        }),

        actions: {
            setTime(type, selected) {
                type === 'hours' ? this.presetTime(selected, 'selectedHours', 'selectedMinutes') : this.presetTime(selected, 'selectedMinutes', 'selectedHours');
                this.sendAction('setTime', this.get('selectedTime'));
            }
        }
    });
});