define('smart-tracking-webapp/routes/protected/safety/trucks/truck/maintenance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {

            let truckId = transition.params['protected.safety.trucks.truck'].id;
            let routeCrud = this.get('routeCrud');
            let maintenance = routeCrud.getRecord(params, 'vehicle-maintenance', 'maintenance_id');

            return Ember.RSVP.hash({
                truck: this.get('store').findRecord('truck', truckId),
                maintenance: maintenance.record,
                randomId: maintenance.randomId,
                currentTruck: this.get('store').findRecord('truck', truckId),
                maintenanceTypes: Ember.$.getJSON("/json/maintenance-types.json").then(function (types) {
                    return types;
                })
            });
        }

    });
});