define('smart-tracking-webapp/routes/protected/dispatch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        navigationService: Ember.inject.service('navigationService'),
        activate() {
            let defaultItems = this.get('navigationService').get('defaultVisibleItems');
            let navigationItems = [{
                icon: 'truck',
                position: 2,
                label: 'Truck board',
                link: 'protected.dispatch.index'
            }, {
                icon: 'truck',
                position: 3,
                label: 'Loads board',
                link: 'protected.dispatch.loads'
            }, {
                icon: 'truck',
                position: 4,
                label: 'All loads',
                link: 'protected.dispatch.loads-archive'
            }, {
                icon: 'user',
                position: 5,
                label: 'Brokers',
                link: 'protected.dispatch.brokers'
            }, {
                icon: 'building',
                position: 6,
                label: 'Facilities',
                link: 'protected.dispatch.facilities'
            }];
            let completeNavigationItems = navigationItems.concat(defaultItems);

            //sorting by position
            completeNavigationItems.sort(function (a, b) {
                return a.position - b.position;
            });

            this.get('navigationService').set('visibleItems', completeNavigationItems);
        },
        actions: {
            loading: function () {
                this.controllerFor('protected').set('isLoading', true);

                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});