define('smart-tracking-webapp/models/vehicle-document', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        type: _emberData.default.belongsTo('vehicle-document-type', { inverse: null }),
        equipment: _emberData.default.belongsTo('equipment', { inverse: null }),
        truck: _emberData.default.belongsTo('truck', { inverse: null }),
        path: _emberData.default.attr('string'),
        issueDate: _emberData.default.attr('string'),
        expireDate: _emberData.default.attr('string'),
        formattedIssueDate: Ember.computed('issueDate', function () {
            return this.get('issueDate') ? (0, _moment.default)(this.get('issueDate')).format('MM/DD/YYYY') : '/';
        }),
        formattedExpireDate: Ember.computed('expireDate', function () {
            return this.get('expireDate') ? (0, _moment.default)(this.get('expireDate')).format('MM/DD/YYYY') : '/';
        })
    });
});