define('smart-tracking-webapp/routes/protected/safety/trucks/truck', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function (params) {
            return Ember.RSVP.hash({
                truck: this.get('store').findRecord('truck', params.id, { include: 'documents,accidents' }),
                maintenances: this.get('store').query('vehicle-maintenance', { 'truck': params.id, pagination: false }),
                ownerGroups: this.get('store').query('owner-group', { pagination: false }),
                allMakes: Ember.$.getJSON("/json/truck-makes.json").then(function (makes) {
                    return makes;
                }),
                allFuelTypes: Ember.$.getJSON("/json/fuel-types.json").then(function (types) {
                    return types;
                }),
                eldProviders: this.get('store').query('eld-provider', {})
            });
        }
    });
});