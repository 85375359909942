define('smart-tracking-webapp/routes/protected/dispatch/brokers/broker/contact', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {
            let brokerId = transition.params['protected.dispatch.brokers.broker'].id;
            let routeCrud = this.get('routeCrud');
            let brokerContact = routeCrud.getRecord(params, 'broker-contact', 'contact_id');
            return Ember.RSVP.hash({
                broker: this.get('store').findRecord('broker', brokerId),
                brokerContact: brokerContact.record,
                randomId: brokerContact.randomId
            });
        }

    });
});