define('smart-tracking-webapp/components/charge-form', ['exports', 'smart-tracking-webapp/validations/charge'], function (exports, _charge) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['form'],
        charge: null,
        chargeValidation: _charge.default,

        actions: {
            saveCharge(changeset) {

                let changes = changeset.get('changes');
                changeset.validate();
                let isValid = changeset.get('isValid');
                let charge = this.get('charge');
                let randomId = this.get('randomId');

                if (isValid) {
                    changes.forEach(function (change) {
                        charge.set(change.key, change.value);
                    });
                    this.sendAction('saveCharge', charge, randomId);
                }
            },

            rollback(changeset) {
                this.sendAction('closeModal');

                //unload record if adding is canceled
                if (this.get('randomId')) {
                    let charge = this.get('charge');
                    charge.unloadRecord();
                }

                return changeset.rollback();
            }
        }
    });
});