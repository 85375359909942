define('smart-tracking-webapp/controllers/protected/dispatch/loads-archive', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        openModal: false,
        addingEnabled: Ember.computed('model.currentUser', function () {
            let currentUserRoles = this.get('model').currentUser.get('roles');
            let enabled = false;

            if (currentUserRoles.includes('ROLE_ADMIN')) {
                enabled = true;
            }

            if (currentUserRoles.includes('ROLE_DISPATCH')) {
                enabled = true;
            }

            return enabled;
        }),
        allLoads: Ember.computed('model.loads', 'model.loads.[]', function () {
            let loads = this.get('model').loads || [];

            return {
                header: [{
                    title: 'Reference number',
                    propertyName: 'referenceNumber',
                    routeName: "protected.dispatch.loads.load"
                }, {
                    title: 'Trip number',
                    propertyName: 'tripNumber',
                    routeName: "protected.dispatch.loads.load"
                }, {
                    title: 'Truck id',
                    propertyName: 'truck.truckId'
                }, {
                    title: 'Driver 1',
                    propertyName: 'driver.fullName'
                }, {
                    title: 'Driver 2',
                    propertyName: 'secondDriver.fullName'
                }, {
                    title: 'First stop',
                    propertyName: 'formattedFirstStop',
                    disableSorting: true
                }, {
                    title: 'Last stop',
                    propertyName: 'formattedLastStop',
                    disableSorting: true
                }, {
                    title: 'Total miles',
                    propertyName: 'totalMiles',
                    disableSorting: true
                }, {
                    title: 'Initial charge ($)',
                    propertyName: 'initialMoney'
                }, {
                    title: 'Rate per mile',
                    propertyName: 'totalRate'
                }, {
                    title: 'Status',
                    propertyName: 'status'
                }, {
                    title: 'Delete',
                    component: "table-delete-row",
                    disableSorting: true
                }],
                data: loads
            };
        }),

        modalTitle: Ember.computed('isDelete', function () {
            return this.get('isDelete') ? 'Delete load' : 'Add load';
        }),

        actions: {
            closeModal() {
                this.set('openModal', false);
            },
            addLoad() {
                this.set('openModal', true);
                let load = this.get('store').createRecord('load');
                this.set('currentLoad', load);
                this.set('isAdd', true);
                this.set('isDelete', false);
            },
            confirmAddLoad(load) {
                let self = this;
                load.set('status', 'Planned');
                load.save().then(function (load) {
                    self.transitionToRoute('protected.dispatch.loads.load', load.get('id'));
                    self.get('store').query('load', { include: "events,stops,truck" }).then(function (loads) {
                        self.set('content.loads', loads);
                    });
                });
                this.send('closeModal');
            },
            cancelAddingLoad() {
                let currentLoad = this.get('currentLoad');
                currentLoad.unloadRecord();
                this.send('closeModal');
            },
            //delete actions
            deleteLoad(id) {
                let load = this.get('store').peekRecord('load', id);
                this.set('currentLoad', load);
                this.set('openModal', true);
                this.set('isDelete', true);
            },
            confirmDeleteLoad() {
                let load = this.get('currentLoad');
                load.deleteRecord();
                load.save();
                this.send('closeModal');
            }
        }

    });
});