define('smart-tracking-webapp/validations/password', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        currentPassword: [(0, _validators.validatePresence)(true)],
        newPassword: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 }), (0, _validators.validateFormat)({ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/, message: 'Password must have at least one uppercase letter, one number and one special character:' })],
        passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({ on: 'newPassword', message: 'Passwords do not match' })]
    };
});