define('smart-tracking-webapp/components/zip-distance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notifications: Ember.inject.service('toast'),
        store: Ember.inject.service(),
        zipStart: null,
        zipEnd: null,
        calculatedDistance: null,

        didInsertElement: function () {
            this._super();
            Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
        },

        afterRenderEvent: function () {

            let self = this;
            let notifications = this.get('notifications');
            let companyId = JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.company_id;
            let currentCompany = this.get('store').peekRecord('company', companyId);

            let companyZipAPIkey;

            if (currentCompany) {
                companyZipAPIkey = currentCompany.get('zipAPIkey');
            }

            //default one deleted from front end app
            let clientKey = companyZipAPIkey;

            Ember.$(function () {
                let container = Ember.$("#zip-distance");
                let cache = {};

                /** Handle successful response */
                function handleResp(data) {
                    // Check for error
                    if (data.error_msg) {
                        notifications.error(data.error_msg, 'Error');
                    } else if ("distance" in data) {
                        // Set distance
                        self.set('calculatedDistance', data.distance);
                    }
                }

                container.find("#zip-start-field,#zip-end-field").on("keyup change", function () {

                    let zipStart = self.get('zipStart');
                    let zipEnd = self.get('zipEnd');

                    // Get zip code
                    if (zipStart && zipStart.length == 5 && /^[0-9]+$/.test(zipStart) && zipEnd && zipEnd.length == 5 && /^[0-9]+$/.test(zipEnd)) {
                        // Check cache
                        var cacheKey = zipStart + '-' + zipEnd;
                        if (cacheKey in cache) {
                            handleResp(cache[cacheKey]);
                        } else {
                            // Build url
                            let url = "https://www.zipcodeapi.com/rest/" + clientKey + "/distance.json/" + zipStart + "/" + zipEnd + "/mile";

                            //Make AJAX request
                            Ember.$.ajax({
                                "url": url,
                                "dataType": "json"
                            }).done(function (data) {
                                handleResp(data);
                                // Store in cache
                                cache[cacheKey] = data;
                            }).fail(function (data) {
                                if (data.responseText) {
                                    let json = Ember.$.parseJSON(data.responseText);
                                    if (json.error_code) {
                                        notifications.error(json.error_msg, 'Error');
                                    } else {
                                        // Store in cache
                                        cache[cacheKey] = json;
                                    }
                                } else {
                                    notifications.error('Request failed', 'Error');
                                }
                            });
                        }
                    }
                }).trigger("change");
            });
        },

        actions: {
            closeModal() {
                this.sendAction('closeModal');
            },

            rollback() {
                this.set('zipStart', null);
                this.set('zipEnd', null);
                this.set('calculatedDistance', null);
            }
        }
    });
});