define('smart-tracking-webapp/components/crud-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['crud-table-wrapper'],
        store: Ember.inject.service(),
        notifications: Ember.inject.service('toast'),

        formattedProperties: Ember.computed('currentRecord', 'properties', function () {

            let properties = this.properties;
            let self = this;
            let formattedProperties = [];

            properties.forEach(function (property) {

                let fieldType = property.fieldType || 'input';
                let type = property.type || 'text';
                let value = self.get('currentRecord').get(property.name);

                formattedProperties.pushObject({
                    name: property.name,
                    value: value,
                    label: property.label,
                    type: type,
                    fieldType: fieldType,
                    availableOptions: property.availableOptions || [],
                    selectProperty: property.selectProperty || 'name',
                    modelName: property.modelName,
                    isRequired: property.isRequired
                });
            });

            return formattedProperties;
        }),

        //modal type depending on wanted state
        setActiveState(state) {

            switch (state) {
                case 'add':
                    this.set('isAdd', true);
                    this.set('isEdit', false);
                    this.set('isDelete', false);
                    break;
                case 'edit':
                    this.set('isAdd', false);
                    this.set('isEdit', true);
                    this.set('isDelete', false);
                    break;
                case 'delete':
                    this.set('isAdd', false);
                    this.set('isEdit', false);
                    this.set('isDelete', true);
                    break;
            }
        },

        //applying values from validation fields to current record
        settingUpRecord(currentRecord, formattedProperties) {
            let self = this;
            formattedProperties.forEach(function (change) {
                if (change.fieldType === 'select') {
                    let record = self.get('store').peekRecord(change.modelName, change.value.id);
                    currentRecord.set(change.name, record);
                } else {
                    currentRecord.set(change.name, change.value);
                }
            });
            return currentRecord;
        },

        actions: {
            addRecord() {
                this.setActiveState('add');

                let tmpId = Math.floor(Math.random() * 9000) + 1000;

                let currentRecord = this.set('currentRecord', this.get('store').createRecord(this.get('modelName'), {
                    id: tmpId
                }));

                this.set('tmpId', tmpId);
                this.set('openModal', true);
                this.set('isNewRecord', true);
            },

            editRecord(id) {
                let self = this;
                this.get('store').findRecord(this.get('modelName'), id).then(function (record) {
                    self.set('currentRecord', record);
                    self.setActiveState('edit');
                    self.set('openModal', true);
                });
            },

            deleteRecord(id) {
                this.set('currentRecord', this.get('store').peekRecord(this.get('modelName'), id));
                this.setActiveState('delete');
                this.set('openModal', true);
            },

            closeModal() {
                this.set('openModal', false);
                let self = this;
                //removing record from ember data if action is canceled
                if (this.get('isNewRecord')) {
                    let newRecord = this.get('store').peekRecord(self.get('modelName'), this.get('tmpId'));
                    this.get('store').unloadRecord(newRecord);
                }
                this.set('isNewRecord', false);
                this.set('allSelected', false);
                this.set('requiredFieldsMsg', false);
            },

            confirm(action) {
                let notifications = this.get('notifications');
                let currentRecord = this.get('currentRecord');
                let isValid = true;
                let self = this;
                let formattedProperties = this.get('formattedProperties');
                let selectedUsers = this.get('selectedUsers');

                formattedProperties.forEach(function (property) {
                    if (property.isRequired) {
                        //TODO Maybe refactor
                        if (property.fieldType === 'select') {
                            if (!property.value.id) {
                                isValid = false;
                            }
                        } else {
                            if (!property.value) {
                                isValid = false;
                            }
                        }
                    }
                });

                if (isValid) {

                    switch (action) {

                        case 'add':
                            currentRecord = self.settingUpRecord(currentRecord, formattedProperties);
                            //reset id from new to null
                            currentRecord.set('id', null);
                            currentRecord.save().then(function () {
                                notifications.success('Successfully added record');
                            },
                            //error unload
                            function () {
                                currentRecord.unloadRecord();
                            });
                            break;
                        case 'edit':
                            currentRecord = self.settingUpRecord(currentRecord, formattedProperties);
                            currentRecord.save().then(function () {
                                notifications.success('Successfully edited record');
                            });
                            break;
                        case 'delete':
                            currentRecord.deleteRecord();
                            currentRecord.save().then(function () {
                                notifications.success('Successfully deleted record');
                            });
                            break;

                    }

                    this.set('isNewRecord', false);
                    this.send('closeModal');
                    this.set('requiredFieldsMsg', false);
                } else {
                    this.set('requiredFieldsMsg', true);
                }
            }
        }
    });
});