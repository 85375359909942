define('smart-tracking-webapp/validations/driver', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        firstName: (0, _validators.validatePresence)(true),
        lastName: (0, _validators.validatePresence)(true),
        cdl: (0, _validators.validatePresence)(true),
        cdlState: (0, _validators.validatePresence)(true),
        driverId: (0, _validators.validatePresence)(true),
        email: (0, _validators.validatePresence)(true)
    };
});