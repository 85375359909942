define('smart-tracking-webapp/components/forgot-password-form', ['exports', 'smart-tracking-webapp/validations/forgot-password', 'smart-tracking-webapp/config/environment'], function (exports, _forgotPassword, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basicAjax: Ember.inject.service('basicAjax'),
        ForgotPassword: _forgotPassword.default,
        forgotPassword: {},
        notifications: Ember.inject.service('toast'),

        actions: {
            send(changeset) {
                const notifications = this.get('notifications');
                changeset.validate();
                let isValid = changeset.get('isValid');

                if (isValid) {

                    let jsonData = {
                        email: changeset.get('email')
                    };
                    let basicAjax = this.get('basicAjax');
                    let url = _environment.default.APP.API_HOST + "/forgot-password";

                    basicAjax.request('POST', url, jsonData).then(function (response) {
                        if (response) {
                            notifications.success('Email with instructions is sent!');
                        }
                    });
                }
            }
        }

    });
});