define('smart-tracking-webapp/routes/protected/accounting/accounts/owners/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function () {
            return Ember.RSVP.hash({
                owners: this.get('store').query('owner-group', { exclude: true, include: 'trucks', pagination: false }),
                trucks: []
            });
        }

    });
});