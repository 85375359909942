define('smart-tracking-webapp/routes/protected/dispatch/loads/load/event', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        routeCrud: Ember.inject.service('routeCrud'),

        model: function (params, transition) {
            let loadId = transition.params['protected.dispatch.loads.load'].id;
            let routeCrud = this.get('routeCrud');
            let event = routeCrud.getRecord(params, 'event', 'event_id');
            return Ember.RSVP.hash({
                load: this.get('store').findRecord('load', loadId),
                event: event.record,
                randomId: event.randomId
            });
        }
    });
});