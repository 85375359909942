define('smart-tracking-webapp/services/basic-ajax', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service('session'),
        notifications: Ember.inject.service('toast'),

        request(requestType, url, jsonData = false, disableStringify = false) {
            let session = this.get('session').get('session');
            let notifications = this.get('notifications');

            let data;

            if (jsonData) {
                if (disableStringify) {
                    data = jsonData;
                } else {
                    data = JSON.stringify(jsonData);
                }
            }

            let accessToken;

            if (session) {
                accessToken = "Bearer " + session.content.authenticated.access_token;
            }

            let contentType = 'application/vnd.api+json';

            return new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: requestType,
                    url: url,
                    data: data,
                    contentType: contentType,
                    // dataType: "json",

                    beforeSend: function (xhr) {
                        if (accessToken) {
                            xhr.setRequestHeader("authorization", accessToken);
                        }
                    },

                    /**
                     * Put "status" in success functions is something wrong
                     */
                    success: function (response) {
                        return resolve(response);
                    },
                    /**
                     * Put "textStatus, errorThrown" in success functions is something wrong
                     */
                    error: function (error) {
                        notifications.error(error.responseJSON && error.responseJSON.error || error.responseJSON.message || error.statusText, 'Error');
                        return reject(error);
                    }
                });
            });
        }
    });
});