define('smart-tracking-webapp/components/table-change-pass', ['exports', 'smart-tracking-webapp/templates/components/table-change-pass'], function (exports, _tableChangePass) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableChangePass.default,
        actions: {
            sendAction(actionName, record, event) {
                this.sendAction(actionName, record);
                event.stopPropagation();
            }
        }
    });
});