define('smart-tracking-webapp/components/star-rating', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['star-rating'],
        renderValues: Ember.computed('value', function () {
            let value = this.value;
            let segmentsWidth = Number(((value - Math.floor(value)) * 100).toFixed(0));
            if (value > 0) {
                return {
                    fullStars: new Array(Math.floor(value)),
                    segmentsWidth: segmentsWidth,
                    segmentsWidthString: Ember.String.htmlSafe('width:' + segmentsWidth + '%'),
                    emptyStars: value < 5 ? new Array(4 - Math.floor(value)) : [],
                    value: value
                };
            } else {
                return {
                    fullStars: [],
                    segments: false,
                    emptyStars: new Array(5),
                    value: 0
                };
            }
        })
    });
});