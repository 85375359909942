define('smart-tracking-webapp/models/driver', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        driverId: _emberData.default.attr('string'),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        cdl: _emberData.default.attr('string'),
        status: _emberData.default.belongsTo('driver-status'),
        owner: _emberData.default.belongsTo('owner-group', { inverse: null }),
        documents: _emberData.default.hasMany('driver-document'),
        fuelCard: _emberData.default.attr('string'),
        iPass: _emberData.default.attr('string'),
        isEligible: _emberData.default.attr('boolean'),
        drugTests: _emberData.default.hasMany('drug-test'),
        company: _emberData.default.belongsTo('company'),
        accidents: _emberData.default.hasMany('accident', { inverse: null }),
        fullName: Ember.computed('firstName', 'lastName', function () {
            return this.get('firstName') || this.get('lastName') ? (this.get('firstName') || "") + " " + (this.get('lastName') || "") : 'unnamed';
        }),
        birthDate: _emberData.default.attr('string'),
        ssn: _emberData.default.attr('string'),
        cdlState: _emberData.default.attr('string'),
        address: _emberData.default.attr('string'),
        telephone: _emberData.default.attr('string'),
        bankRoutingNumber: _emberData.default.attr('string'),
        bankAccountNumber: _emberData.default.attr('string'),
        emergencyName: _emberData.default.attr('string'),
        emergencyPhone: _emberData.default.attr('string'),
        emergencyRelationship: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        lastLoadZip: _emberData.default.attr('string'),
        perEmptyMiles: _emberData.default.attr('number'),
        perLoadedMiles: _emberData.default.attr('number'),
        perTotalMiles: _emberData.default.attr('number'),
        perStop: _emberData.default.attr('number'),
        perPickup: _emberData.default.attr('number'),
        perDelivery: _emberData.default.attr('number'),
        perExtraStop: _emberData.default.attr('number'),
        percentageDetention: _emberData.default.attr('number'),
        percentageLayover: _emberData.default.attr('number'),
        initialAmount: _emberData.default.attr('number'),
        eldProviderId: _emberData.default.attr('string'),
        eldProvider: _emberData.default.belongsTo('eld-provider')
    });
});